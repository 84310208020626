import {CURRENT_STATE} from "../reducers/historicalData";
import _ from "lodash";

const TARGET_SECONDS_DATA_COUNT = 240; // 4 minutes
const TIME_JUMP_BACK = 180; // 3 minutes


/**
 * Returns the value between two numbers at a specified, decimal midpoint.
 * */
function linIntPol(firstValue, secondValue, midPoint) {
    firstValue = parseFloat(firstValue);
    secondValue = parseFloat(secondValue);

    if (firstValue === secondValue) return (secondValue)
    else return (firstValue * (1 - midPoint) + secondValue * midPoint);
}

function generateMetrics(fromMetric, toMetric, disallowTimestamps = []) {
    const generatedMetrics = [];
    const fromMetricDate = new Date(fromMetric["Dátum"]);
    const toMetricDate = new Date(toMetric["Dátum"]);

    // Calculate the time difference in milliseconds and convert it to seconds
    const timeGapInSeconds = (toMetricDate.getTime() - fromMetricDate.getTime()) / 1000;

    // Create the missing metrics between the previous and the next metric
    for (let j = 1; j < timeGapInSeconds; j++) {

        // Get the timestamp
        const currentDate = new Date(fromMetricDate.getTime() + (j * 1000));

        // Skip this timestamp if it's in the disallow list
        if (disallowTimestamps.includes(getDateString(currentDate))) {
            continue;
        }

        // Calculate values with linear interpolation
        let pBe = linIntPol(fromMetric["Pbe (mbar)"], toMetric["Pbe (mbar)"], (1 / timeGapInSeconds) * j);
        let pKi = linIntPol(fromMetric["Pki (mbar)"], toMetric["Pki (mbar)"], (1 / timeGapInSeconds) * j);

        // Round values to 2 decimals
        pBe = Math.round((pBe + Number.EPSILON) * 100) / 100
        pKi = Math.round((pKi + Number.EPSILON) * 100) / 100

        generatedMetrics.push({
            "Dátum": getDateString(currentDate),
            "Pbe (mbar)": pBe,
            "Pki (mbar)": pKi,
            "Gyz1": "",
            "Gyz2": "",
            type: "gen"
        });
    }

    return generatedMetrics;
}

function getDateString(date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    const hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    const minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    const seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

    // Set the newly calculated timestamp to the created row
    return (`${year}.${month}.${day}. ${hours}:${minutes}:${seconds}`);
}

function getClosestMetric(metrics, date) {
    if (metrics.length > 0) {
        const timeDiffs = metrics.map((metric) => Math.abs(date.getTime() - new Date(metric["Dátum"]).getTime()));
        const indexOfClosest = timeDiffs.indexOf(Math.min(...timeDiffs));
        return (metrics[indexOfClosest]);
    } else {
        return null;
    }
}

/**
 * @param {{"Dátum": string, "Pbe (mbar)": number, "Pki (mbar)": number, "Gyz1": string, "Gyz2": string}[]} metrics
 * @param {Date} fromDate
 * @param {Date} toDate
 * @param {boolean} sort
 * */
function collectMetricsInTimeRange(metrics, fromDate, toDate, sort = true) {
    const collectedMetrics = [];
    const sortedMetrics = _.sortBy(metrics, ["Dátum"]);

    for (const metric of sortedMetrics) {
        const metricDate = new Date(metric["Dátum"]);
        if (fromDate.getTime() <= metricDate.getTime() && metricDate.getTime() <= toDate.getTime()) {
            collectedMetrics.push(metric);
            /*console.info(`FromDate: ${fromDate.toLocaleTimeString()} | Date: ${metric["Dátum"]} | ToDate: ${toDate.toLocaleTimeString()}`)
            console.info("Added!")*/
        }
    }

    if (sort) {
        return (_.sortBy(collectedMetrics, ["Dátum"]));
    } else {
        return (collectedMetrics);
    }
}

export default (metrics) => {

    console.info("\nStart of creating seconds data...");

    let finalMetrics = [..._.sortBy([...metrics], ["Dátum"])];

    console.info("Metrics: ", metrics);

    for (const metric of metrics) {

        // Skip this metric if it's not havaria
        if (metric.type !== "havaria") continue;

        /* This metric is a havaria */

        /* PHASE 1: Define start date and start metric */

        // Calculate the start date
        const startDate = new Date(new Date(metric["Dátum"]).getTime() - (TIME_JUMP_BACK * 1000));

        console.info("Start Date: ", startDate.toLocaleTimeString());

        // Calculate end date based on the HAVARIA_METRIC's date and the given TIME_JUMP_BACK and TARGET_SECONDS_DATA_COUNT
        const endDate = new Date(new Date(metric["Dátum"]).getTime() - (TIME_JUMP_BACK * 1000) + (TARGET_SECONDS_DATA_COUNT * 1000));

        // Get the closest metric to the start date
        let closestMetricToStart = getClosestMetric([...finalMetrics], startDate);
        if (!closestMetricToStart) {
            closestMetricToStart = {...metric};
        }
        console.info("Closest Metric to start date: ", closestMetricToStart);

        // Create the start metric
        const startMetric = {
            ...closestMetricToStart,
            "Dátum": getDateString(startDate),
            type: "gen-start"
        }

        /* PHASE 2: Prepare the data for generation */

        // Collect all existing metrics that overlap in time
        const existingMetrics = collectMetricsInTimeRange([...finalMetrics], startDate, endDate);

        console.log("Existing metrics: ", existingMetrics);

        // Extract the timestamps
        const existingTimestamps = existingMetrics.map(m => m["Dátum"]);

        let extendedMetrics = [...existingMetrics];

        // Add startMetric to the extendedMetrics array if its date is equal to the calculated start date
        // Otherwise the startMetric is an already processed and stored metric
        if (!existingTimestamps.includes(startMetric["Dátum"])) {
            extendedMetrics.push(startMetric);
            console.info("Merged seconds data did not contain start metric, so it was added.");
        }

        // Check if the oldest metric in the buffer is still lower than the desired end date
        const oldestMetric = existingMetrics[existingMetrics.length - 1];
        if (!existingTimestamps.includes(getDateString(endDate))) {
            extendedMetrics.push({
                "Dátum": getDateString(endDate),
                "Pbe (mbar)": oldestMetric["Pbe (mbar)"],
                "Pki (mbar)": oldestMetric["Pki (mbar)"],
                "Gyz1": "",
                "Gyz2": "",
                type: "gen-end"
            });
        }

        extendedMetrics = _.sortBy(extendedMetrics, ["Dátum"]);

        /* PHASE 3: Generate seconds data between the collected ones */

        console.info("Metrics that will be used for the generation: ", extendedMetrics);

        let createdSecondsData = [];

        // Loop through all metrics in the buffer and fill the time gaps between them with some generated metrics
        for (let i = 0; i < extendedMetrics.length; i++) {

            const currentMetric = extendedMetrics[i];
            const nextMetric = extendedMetrics[i + 1];

            // Stop generation if there is no next metric to interpolate to
            if (!nextMetric) break;

            // Generate secondsMetrics between the current and the next metric
            const generatedMetrics = generateMetrics(currentMetric, nextMetric, existingTimestamps);
            createdSecondsData = [...createdSecondsData, ...generatedMetrics];
        }

        console.info("Final createdSecondsData: ", createdSecondsData);

        /* PHASE 4: Merging */

        finalMetrics = [...finalMetrics, ...createdSecondsData];

    }

    console.info("End of creating seconds data...\n");

    return(_.sortBy(finalMetrics, ["Dátum"]));

}