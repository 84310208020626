import {makeStyles} from "@material-ui/core/styles";

/** Styles for Diagnostic component */

export default makeStyles(theme => ({
    notificationTitle: {
        marginTop: 35,
        fontSize: 28
    },
    connectButton: {
        marginTop: 35,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative"
    },
    buttonProgress: {
        color: "primary",
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
}));
