// General
import React from 'react';
import PropTypes from "prop-types";
// Other
import {
    XAxis,
    YAxis,
    ResponsiveContainer,
    CartesianGrid,
    Tooltip,
    AreaChart, Area
} from 'recharts';
// Material-UI
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import {TrendingDown, TrendingFlat} from "@material-ui/icons";
// Own Component
import Title from '../../../templates/Title';
// Styles
import useDiagDataChartStyles from "./DiagChart.styles";
import {useTheme} from '@material-ui/core/styles';
// Utils
import {calculateTrending} from "./DiagChart.utils";


export default function DiagChart({liveInput}) {

    // Styles
    const classes = useDiagDataChartStyles();
    const theme = useTheme();

    // Calculate y axis maximum value
    let yMaxVal = (1.2 * Math.max.apply(Math, liveInput.metrics.map(metric => metric.value))).toFixed(1);
    yMaxVal = liveInput.metrics[9].value === 0 ? 0 : parseInt(yMaxVal);

    // Get trending
    let trendingPercent = calculateTrending(liveInput)

    // Select trending icon based on the trending number
    let trendingColor = "primary";
    let trendingIcon = <TrendingUpIcon color={trendingColor} className={classes.trendingIcon}/>;
    if (trendingPercent < 0) {
        trendingColor = "error";
        trendingIcon = <TrendingDown color={trendingColor} className={classes.trendingIcon}/>;
    } else if (parseInt(trendingPercent) === 0) {
        trendingColor = "secondary";
        trendingIcon = <TrendingFlat color={trendingColor} className={classes.trendingIcon}/>;
    }

    return (
        <React.Fragment>
            <Grid container spacing={5}>
                <Grid item xs={6} md={8} lg={10}>
                    <Title noWrap className={classes.title}>
                        {liveInput.name}: {liveInput.metrics[9].value} {liveInput.dim}
                    </Title>
                </Grid>
                <Grid item xs={6} md={4} lg={2}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={3} md={3} lg={3}>
                            {trendingIcon}
                        </Grid>
                        <Grid item xs={9} md={9} lg={9}>
                            <Typography noWrap color={trendingColor} component="p" variant="h6">
                                {trendingPercent}%
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ResponsiveContainer>
                <AreaChart data={liveInput.metrics} margin={{top: 16, right: 10, bottom: 0, left: 10,}}>
                    <defs>
                        <linearGradient id="colorIndex" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={theme.palette.primary.main} stopOpacity={0.8}/>
                            <stop offset="95%" stopColor={theme.palette.primary.main} stopOpacity={0.05}/>
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="time"/>
                    <YAxis domain={[0, yMaxVal]}/>
                    <CartesianGrid strokeDasharray="7"/>
                    {/*<ReferenceLine y={liveInput.limit} stroke="red"/>*/}
                    <Tooltip
                        wrapperStyle={{backgroundColor: "white"}}
                        labelStyle={{color: theme.palette.primary.main}}
                        itemStyle={{color: theme.palette.primary.main}}
                        formatter={function (value, name) {
                            return `${value} ${liveInput.dim}`;
                        }}
                        labelFormatter={function (value) {
                            return `időpont: ${value}`;
                        }}/>
                    <Area type="monotone" dataKey="value"
                          stroke={theme.palette.primary.main}
                          fillOpacity={1} fill="url(#colorIndex)"
                          dot={true}/>
                </AreaChart>
            </ResponsiveContainer>
        </React.Fragment>
    )
}

// Property definitions
DiagChart.propTypes = {
    liveInput: PropTypes.object.isRequired
};
