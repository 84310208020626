// General
import React from "react";
import PropTypes from 'prop-types';
// Material-UI
import {TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import SaveIcon from '@material-ui/icons/Save';
import Fab from "@material-ui/core/Fab";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Paper from "@material-ui/core/Paper";
import InputAdornment from "@material-ui/core/InputAdornment";
// Redux
import {store} from "../../../index";
import {setCommandBuffer, setConnectionStatus, setNotification} from "../../../redux/actions";
import {
    ConnectionMessage,
    ConnectionMessageSeverity,
    ConnectionStatus,
    CommandBufferStatus
} from "../../../redux/actions";
// Own Components
import OnSaveBackdrop from "../../templates/OnSaveBackdrop";
import LayoutItem from "../../templates/LayoutItem";
import LinearLayout from "../../templates/LinearLayout";
// Styles
import useSettingsStyles from "./Settings.styles";
// Assets
import settings from "../../../assets/settings.png";
import {grey} from "@material-ui/core/colors";


function createDeviceConfigHeader(config, classes, VeriboxDataListItems) {

    const deviceNameData = [
        '/' + config.VNAM,      // MQTT topic
        config.VNAM.slice(      // Text behind the last "/" char
            config.VNAM.lastIndexOf('/') + 1
        )
    ];

    VeriboxDataListItems.push(
        <ListItem>
            <Paper className={classes.listPaper} variant={"outlined"}>
                <Typography className={classes.listSubheader} variant={"h6"}>Eszközinformáció</Typography>
                <LinearLayout orientation={"horizontal"} itemSpacing={3} sm={12}>
                    <LayoutItem md={4}>
                        <Tooltip title={"VNAM"}>
                            <TextField fullWidth disabled
                                       value={deviceNameData[1]}
                                       variant={"outlined"}
                                       label={"Eszköznév"}/>
                        </Tooltip>
                    </LayoutItem>
                    <LayoutItem md={4}>
                        <Tooltip title={"VNAM"}>
                            <TextField fullWidth disabled
                                       value={deviceNameData[0]}
                                       variant={"outlined"}
                                       label={"MQTT topic"}/>
                        </Tooltip>
                    </LayoutItem>
                    <LayoutItem md={4}>
                        <Tooltip title={"BATT"}>
                            <TextField fullWidth disabled
                                       value={config.BATT}
                                       variant={"outlined"}
                                       label={"Töltöttségiszint"}/>
                        </Tooltip>
                    </LayoutItem>
                </LinearLayout>
            </Paper>
        </ListItem>
    );
}

const paramNameList = ["inputName", "dimension", "physicalInput", "warnLow", "warnHigh", "limitHigh", "alarmAction", "alarmName", "alarmOKStr", "alarmWarnStr"];
let commandBuffer = [];

function Settings({config, liveData, connectionStatus, commandBufferStatus}) {
    // Styles
    const classes = useSettingsStyles();

    let VeriboxDataListItems = [];
    let backDropStatus = (commandBufferStatus === CommandBufferStatus.COMMAND_REQUESTED);

    const [inputNames, setInputNames] = React.useState(() => {
        return (liveData.map(data => {
            return {
                paramName: paramNameList[0],
                paramIndex: data.id.substring(4),
                paramValue: data.name,
                paramInitValue: data.name,
                paramHasChanged: false
            }
        }));
    });

    const [dimensions, setDimensions] = React.useState(() => {
        return (liveData.map(data => {
            return {
                paramName: paramNameList[1],
                paramIndex: data.id.substring(4),
                paramValue: data.dim,
                paramInitValue: data.dim,
                paramHasChanged: false
            }
        }));
    });

    const [physicalInputs, setPhysicalInputs] = React.useState(() => {
        return (liveData.map(data => {
            return {
                paramName: paramNameList[2],
                paramIndex: data.id.substring(4),
                paramValue: data.physical_input,
                paramInitValue: data.physical_input,
                paramHasChanged: false
            }
        }));
    });

    const [warnLows, setWarnLows] = React.useState(() => {
        return (liveData.map(data => {
            return {
                paramName: paramNameList[3],
                paramIndex: data.id.substring(4),
                paramValue: data.warn_low,
                paramInitValue: data.warn_low,
                paramHasChanged: false
            }
        }));
    });

    const [warnHighs, setWarnHighs] = React.useState(() => {
        return (liveData.map(data => {
            return {
                paramName: paramNameList[4],
                paramIndex: data.id.substring(4),
                paramValue: data.warn_high,
                paramInitValue: data.warn_high,
                paramHasChanged: false
            }
        }));
    });

    const [limitHighs, setLimitHighs] = React.useState(() => {
        return (liveData.map(data => {
            return {
                paramName: paramNameList[5],
                paramIndex: data.id.substring(4),
                paramValue: data.limit_high,
                paramInitValue: data.limit_high,
                paramHasChanged: false
            }
        }));
    });

    const [alarmActions, setAlarmActions] = React.useState(() => {
        return (liveData.map(data => {
            return {
                paramName: paramNameList[6],
                paramIndex: data.id.substring(4),
                paramValue: data.alarm_action,
                paramInitValue: data.alarm_action,
                paramHasChanged: false
            }
        }));
    });

    const [alarmNames, setAlarmNames] = React.useState(() => {
        return (liveData.map(data => {
            return {
                paramName: paramNameList[7],
                paramIndex: data.id.substring(4),
                paramValue: data.alarm_name,
                paramInitValue: data.alarm_name,
                paramHasChanged: false
            }
        }));
    });

    const [alarmOKStrs, setAlarmOKStrs] = React.useState(() => {
        return (liveData.map(data => {
            return {
                paramName: paramNameList[8],
                paramIndex: data.id.substring(4),
                paramValue: data.alarm_ok_str,
                paramInitValue: data.alarm_ok_str,
                paramHasChanged: false
            }
        }));
    });

    const [alarmWarnStrs, setAlarmWarnStrs] = React.useState(() => {
        return (liveData.map(data => {
            return {
                paramName: paramNameList[9],
                paramIndex: data.id.substring(4),
                paramValue: data.alarm_warn_str,
                paramInitValue: data.alarm_warn_str,
                paramHasChanged: false
            }
        }));
    });

    if (liveData.length === 0) {
        return (
            <div>
                <div style={{marginTop: 32, width: '100%', height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                    <img alt={"settings"} src={settings} width={400}/>
                    <div style={{height: 16}}/>
                    <Typography style={{color: grey[500]}} component="h2" variant="h5" align={"center"}>
                        Az adatok megjelenítéséhez csatlakozzon egy Veribox eszközhöz!
                    </Typography>
                </div>
            </div>
        )
    }

    createDeviceConfigHeader(config, classes, VeriboxDataListItems);

    const handleSave = event => {

        let emptyAlarmFieldsError = false;
        let allParameters = [...inputNames, ...dimensions, ...physicalInputs, ...warnLows, ...warnHighs, ...limitHighs];
        const paramArraysAndSetters = [
            [inputNames, setInputNames], [dimensions, setDimensions], [physicalInputs, setPhysicalInputs],
            [warnLows, setWarnLows], [warnHighs, setWarnHighs], [limitHighs, setLimitHighs],
            [alarmActions, setAlarmActions], [alarmNames, setAlarmNames], [alarmOKStrs, setAlarmOKStrs],
            [alarmWarnStrs, setAlarmWarnStrs]];

        commandBuffer = allParameters.filter(parameter => parameter.paramHasChanged);

        for (let i = 0; i < alarmActions.length; i++) {
            let action = alarmActions[i];
            let name = alarmNames[i];
            let okStr = alarmOKStrs[i];
            let warnStr = alarmWarnStrs[i];

            if (!action.paramHasChanged && action.paramValue === "0") continue;

            if (name.paramValue === "" && okStr.paramValue === "" && warnStr.paramValue === "") {
                if (action.paramValue === "3") {
                    emptyAlarmFieldsError = true;
                    break;
                }
            }

            action.paramHasChanged && commandBuffer.push(action);
            name.paramHasChanged && commandBuffer.push(name);
            okStr.paramHasChanged && commandBuffer.push(okStr);
            warnStr.paramHasChanged && commandBuffer.push(warnStr);

        }

        if (emptyAlarmFieldsError) {
            commandBuffer = [];
            return;
        }

        paramArraysAndSetters.forEach(paramArrayAndSetter => {
            paramArrayAndSetter[1](paramArrayAndSetter[0].map(parameter => ({
                ...parameter,
                paramInitValue: parameter.paramValue,
                paramHasChanged: false
            })));
        });

        if (commandBuffer.length > 0) {

            store.dispatch(setConnectionStatus({
                status: ConnectionStatus.PAUSED,
                message: ConnectionMessage.PAUSED,
                ConnectionMessageSeverity: ConnectionMessageSeverity.LOW
            }));

            store.dispatch(setCommandBuffer({
                commandBuffer,
                commandBufferStatus: CommandBufferStatus.COMMAND_REQUESTED
            }));

        } else store.dispatch(setNotification({status: true, severity: "info", message: "Nem történt változtatás"}));

        commandBuffer = [];
    };

    const handleInputNamesChange = event => {
        setInputNames(inputNames.map(name => {
                if (name.paramIndex === event.target.id) {
                    return name.paramInitValue === event.target.value ? {
                        ...name,
                        paramValue: event.target.value,
                        paramHasChanged: false
                    } : {...name, paramValue: event.target.value, paramHasChanged: true}
                }
                return name;
            }
        ));
    };

    const handleDimensionsChange = event => {
        setDimensions(dimensions.map(dim => {
                if (dim.paramIndex === event.target.id) {
                    return dim.paramInitValue === event.target.value ? {
                        ...dim,
                        paramValue: event.target.value,
                        paramHasChanged: false
                    } : {...dim, paramValue: event.target.value, paramHasChanged: true}
                }
                return dim;
            }
        ));

    };

    const handlePhysicalInputsChange = event => {
        setPhysicalInputs(physicalInputs.map(physicalInput => {
                if (physicalInput.paramIndex === event.currentTarget.id) {
                    return physicalInput.paramInitValue === event.target.value ? {
                        ...physicalInput,
                        paramValue: event.target.value,
                        paramHasChanged: false
                    } : {...physicalInput, paramValue: event.target.value, paramHasChanged: true}
                }
                return physicalInput;
            }
        ));
    };

    const handleWarnLowsChange = event => {
        setWarnLows(warnLows.map(warnLow => {
                if (warnLow.paramIndex === event.target.id) {
                    return warnLow.paramInitValue === event.target.value ? {
                        ...warnLow,
                        paramValue: event.target.value,
                        paramHasChanged: false
                    } : {...warnLow, paramValue: event.target.value, paramHasChanged: true}
                }
                return warnLow;
            }
        ));
    };

    const handleWarnHighsChange = event => {
        setWarnHighs(warnHighs.map(warnHigh => {
                if (warnHigh.paramIndex === event.target.id) {
                    return warnHigh.paramInitValue === event.target.value ? {
                        ...warnHigh,
                        paramValue: event.target.value,
                        paramHasChanged: false
                    } : {...warnHigh, paramValue: event.target.value, paramHasChanged: true}
                }
                return warnHigh;
            }
        ));
    };

    const handleLimitHighsChange = event => {
        setLimitHighs(limitHighs.map(limitHigh => {
                if (limitHigh.paramIndex === event.target.id) {
                    return limitHigh.paramInitValue === event.target.value ? {
                        ...limitHigh,
                        paramValue: event.target.value,
                        paramHasChanged: false
                    } : {...limitHigh, paramValue: event.target.value, paramHasChanged: true}
                }
                return limitHigh;
            }
        ));
    };

    const handleAlarmActionsChange = event => {
        setAlarmActions(alarmActions.map(alarmAction => {
                let newValue = (alarmAction.paramValue === "0" ? "3" : "0");
                if (alarmAction.paramIndex === event.target.id) {
                    if (alarmAction.paramInitValue === newValue) {
                        return {...alarmAction, paramValue: alarmAction.paramInitValue, paramHasChanged: false}
                    } else {
                        return {...alarmAction, paramValue: newValue, paramHasChanged: true}
                    }
                }
                return alarmAction;
            }
        ));
    };

    const handleAlarmNamesChange = event => {
        setAlarmNames(alarmNames.map(alarmName => {
                if (alarmName.paramIndex === event.target.id) {
                    return alarmName.paramInitValue === event.target.value ? {
                        ...alarmName,
                        paramValue: event.target.value,
                        paramHasChanged: false
                    } : {...alarmName, paramValue: event.target.value, paramHasChanged: true}
                }
                return alarmName;
            }
        ));
    };

    const handleAlarmOKStrsChange = event => {
        setAlarmOKStrs(alarmOKStrs.map(alarmOkStr => {
                if (alarmOkStr.paramIndex === event.target.id) {
                    return alarmOkStr.paramInitValue === event.target.value ? {
                        ...alarmOkStr,
                        paramValue: event.target.value,
                        paramHasChanged: false
                    } : {...alarmOkStr, paramValue: event.target.value, paramHasChanged: true}
                }
                return alarmOkStr;
            }
        ));
    };

    const handleAlarmWarnStrsChange = event => {
        setAlarmWarnStrs(alarmWarnStrs.map(alarmWarnStr => {
                if (alarmWarnStr.paramIndex === event.target.id) {
                    return alarmWarnStr.paramInitValue === event.target.value ? {
                        ...alarmWarnStr,
                        paramValue: event.target.value,
                        paramHasChanged: false
                    } : {...alarmWarnStr, paramValue: event.target.value, paramHasChanged: true}
                }
                return alarmWarnStr;
            }
        ));
    };

    liveData.forEach((data, index) => {

        //const deviceNameData = config.VNAM.split("/");

        VeriboxDataListItems.push(
            <ListItem>
                <Paper className={classes.listPaper} variant={"outlined"}>
                    <Typography className={classes.listSubheader} variant={"h6"}>{data.id}</Typography>
                    <LinearLayout orientation={"horizontal"} itemSpacing={3} sm={12}>
                        <LayoutItem md={4}>
                            <Tooltip title={"VANM" + inputNames[index].paramIndex}>
                                <TextField id={inputNames[index].paramIndex} fullWidth disabled={true}
                                           value={inputNames[index].paramValue}
                                           variant={"outlined"} label={"Név"} onChange={handleInputNamesChange}/>
                            </Tooltip>
                        </LayoutItem>
                        <LayoutItem md={4}>
                            <Tooltip title={"VADM" + dimensions[index].paramIndex}>
                                <TextField id={dimensions[index].paramIndex} fullWidth disabled={false}
                                           value={dimensions[index].paramValue}
                                           variant={"outlined"} label={"Mértékegység"}
                                           onChange={handleDimensionsChange}/>
                            </Tooltip>
                        </LayoutItem>
                        <LayoutItem md={4}>
                            <Tooltip title={"VAIN" + physicalInputs[index].paramIndex}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel>Fizikai bemenet</InputLabel>
                                    <Select
                                        disabled={false}
                                        onChange={handlePhysicalInputsChange}
                                        value={physicalInputs[index].paramValue}
                                        label={"Fizikai bemenet"}>
                                        <ListSubheader>Digitális</ListSubheader>
                                        <MenuItem value="1" id={physicalInputs[index].paramIndex}>D1</MenuItem>
                                        <MenuItem value="2" id={physicalInputs[index].paramIndex}>D2</MenuItem>
                                        <MenuItem value="3" id={physicalInputs[index].paramIndex}>D3</MenuItem>
                                        <MenuItem value="4" id={physicalInputs[index].paramIndex}>D4</MenuItem>
                                        <ListSubheader>Analóg</ListSubheader>
                                        <MenuItem value="5" id={physicalInputs[index].paramIndex}>A1</MenuItem>
                                        <MenuItem value="6" id={physicalInputs[index].paramIndex}>A2</MenuItem>
                                        <MenuItem value="7" id={physicalInputs[index].paramIndex}>A3</MenuItem>
                                        <MenuItem value="16" id={physicalInputs[index].paramIndex}>Temp</MenuItem>
                                    </Select>
                                </FormControl>
                            </Tooltip>
                        </LayoutItem>
                        <LayoutItem md={4}>
                            <Tooltip title={"WAUG" + warnLows[index].paramIndex}>
                                <TextField id={warnLows[index].paramIndex} fullWidth disabled={false}
                                           value={warnLows[index].paramValue} type={"number"}
                                           variant={"outlined"} label={"Alacsony határérték"}
                                           onChange={handleWarnLowsChange}
                                           InputProps={{
                                               endAdornment: <InputAdornment
                                                   position="end">{dimensions[index].paramValue}</InputAdornment>,
                                           }}/>
                            </Tooltip>
                        </LayoutItem>
                        <LayoutItem md={4}>
                            <Tooltip title={"WAOG" + warnHighs[index].paramIndex}>
                                <TextField id={warnHighs[index].paramIndex} fullWidth disabled={false}
                                           value={warnHighs[index].paramValue} type={"number"}
                                           variant={"outlined"} label={"Magas határérték"}
                                           onChange={handleWarnHighsChange}
                                           InputProps={{
                                               endAdornment: <InputAdornment
                                                   position="end">{dimensions[index].paramValue}</InputAdornment>,
                                           }}/>
                            </Tooltip>
                        </LayoutItem>
                        <LayoutItem md={4}>
                            <Tooltip title={"VAOR" + limitHighs[index].paramIndex}>
                                <TextField id={limitHighs[index].paramIndex} fullWidth disabled={false}
                                           value={limitHighs[index].paramValue} type={"number"}
                                           variant={"outlined"} label={"Méréstartomány felső"}
                                           onChange={handleLimitHighsChange}
                                           InputProps={{
                                               endAdornment: <InputAdornment
                                                   position="end">{dimensions[index].paramValue}</InputAdornment>,
                                           }}/>
                            </Tooltip>
                        </LayoutItem>
                        <LayoutItem md={12}>
                            <Tooltip title={"WAWD" + alarmActions[index].paramIndex}>
                                <FormControlLabel
                                    control={<Switch id={alarmActions[index].paramIndex}
                                                     checked={(alarmActions[index].paramValue >= 2)}
                                                     onChange={handleAlarmActionsChange}/>}
                                    label="Riasztás engedélyezése"
                                />
                            </Tooltip>
                        </LayoutItem>
                        {alarmActions[index].paramValue >= 2 &&
                        <LayoutItem md={4}>
                            <Tooltip title={"WANM" + alarmNames[index].paramIndex}>
                                <TextField id={alarmNames[index].paramIndex} fullWidth disabled={false}
                                           error={(alarmActions[index].paramValue >= 2) && (alarmNames[index].paramValue === "")}
                                           helperText={(alarmActions[index].paramValue >= 2) && (alarmNames[index].paramValue === "") ? "Ez a mező nem maradhat üres!" : ""}
                                           value={alarmNames[index].paramValue} variant={"outlined"}
                                           label={"Riasztás elnevezése"}
                                           onChange={handleAlarmNamesChange}/>
                            </Tooltip>
                        </LayoutItem>}
                        {alarmActions[index].paramValue >= 2 &&
                        <LayoutItem md={4}>
                            <Tooltip title={"WAOK" + alarmOKStrs[index].paramIndex}>
                                <TextField id={alarmOKStrs[index].paramIndex} fullWidth disabled={false}
                                           error={(alarmActions[index].paramValue >= 2) && (alarmOKStrs[index].paramValue === "")}
                                           helperText={(alarmActions[index].paramValue >= 2) && (alarmOKStrs[index].paramValue === "") ? "Ez a mező nem maradhat üres!" : ""}
                                           value={alarmOKStrs[index].paramValue} variant={"outlined"}
                                           label={"Normál állapot szövege"}
                                           onChange={handleAlarmOKStrsChange}/>
                            </Tooltip>
                        </LayoutItem>}
                        {alarmActions[index].paramValue >= 2 &&
                        <LayoutItem md={4}>
                            <Tooltip title={"WAWA" + alarmWarnStrs[index].paramIndex}>
                                <TextField id={alarmWarnStrs[index].paramIndex} fullWidth disabled={false}
                                           error={(alarmActions[index].paramValue >= 2) && (alarmWarnStrs[index].paramValue === "")}
                                           helperText={(alarmActions[index].paramValue >= 2) && (alarmWarnStrs[index].paramValue === "") ? "Ez a mező nem maradhat üres!" : ""}
                                           value={alarmWarnStrs[index].paramValue} variant={"outlined"}
                                           label={"Hibás állapot szövege"}
                                           onChange={handleAlarmWarnStrsChange}/>
                            </Tooltip>
                        </LayoutItem>}
                        {/*<Fade in={(alarmActions[index].paramValue >= 2)}>
                        <LayoutItem md={4}>
                            <Tooltip title={"WANM" + alarmNames[index].paramIndex}>
                                <TextField id={alarmNames[index].paramIndex} fullWidth disabled={false}
                                           value={alarmNames[index].paramValue} variant={"outlined"}
                                           label={"Riasztás elnevezése"}
                                           onChange={handleAlarmNamesChange}/>
                            </Tooltip>
                        </LayoutItem>
                    </Fade>
                    <Fade in={(alarmActions[index].paramValue >= 2)}>
                        <LayoutItem md={4}>
                            <Tooltip title={"WAOK" + alarmOKStrs[index].paramIndex}>
                                <TextField id={alarmOKStrs[index].paramIndex} fullWidth disabled={false}
                                           value={alarmOKStrs[index].paramValue} variant={"outlined"}
                                           label={"Normál állapot szövege"}
                                           onChange={handleAlarmOKStrsChange}/>
                            </Tooltip>
                        </LayoutItem>
                    </Fade>
                    <Fade in={(alarmActions[index].paramValue >= 2)}>
                        <LayoutItem md={4}>
                            <Tooltip title={"WAWA" + alarmWarnStrs[index].paramIndex}>
                                <TextField id={alarmWarnStrs[index].paramIndex} fullWidth disabled={false}
                                           value={alarmWarnStrs[index].paramValue} variant={"outlined"}
                                           label={"Hibás állapot szövege"}
                                           onChange={handleAlarmWarnStrsChange}/>
                            </Tooltip>
                        </LayoutItem>
                    </Fade>
                    <Divider color={"inherit"} variant={"fullWidth"}/>*/}
                    </LinearLayout>
                </Paper>
            </ListItem>
        );

    });

    return (
        <div>
            <List>
                {VeriboxDataListItems}
            </List>
            <OnSaveBackdrop message={"Változtatások mentése folyamatban..."} status={backDropStatus}/>
            <Fab variant="extended" color="primary" className={classes.saveFab}
                 onClick={handleSave}>
                <SaveIcon className={classes.extendedIcon}/>
                Mentés
            </Fab>
        </div>
    );
}

Settings.propTypes = {
    config: PropTypes.object,
    liveData: PropTypes.array,
    connectionStatus: PropTypes.string,
    commandBufferStatus: PropTypes.string,
};

const mapStateToProps = state => ({
        config: state.deviceConfiguration,
        liveData: state.liveData,
        connectionStatus: state.connection.connectionStatus.status,
        commandBufferStatus: state.commandBuffer.commandBufferStatus
    }
);

const SettingsContainer = connect(mapStateToProps, null)(Settings);
export default SettingsContainer;
