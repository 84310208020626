import {makeStyles} from "@material-ui/core/styles";
import theme from "../../../../styles/theme";

/** Styles for Logbook component */

export default makeStyles({
    table: {
        minWidth: 650,
    },
    root: {
        flexGrow: 1,
        paddingLeft: 16
    },
    description: {
        minWidth: "150px"
    },
    deleteDialogTitle: {
        color: theme.palette.error.main
    },
    sendDialogTitle: {
        color: theme.palette.primary.main
    }
});
