import {CREATE_LOG, DELETE_LOG, SEND_LOG} from "../actions";

const logList = (state = [], action) => {

    let logList = JSON.parse(localStorage.getItem("logList"));

    switch (action.type) {
        case CREATE_LOG:
            const id = Math.floor((Math.random() * 10000) + 1);
            const logs = [{
                id: id,
                name: action.logObject.name,
                category: action.logObject.category,
                startDate: action.logObject.startDate,
                /*endDate: action.logObject.endDate,*/
                /*title: action.logObject.title,*/
                template: action.logObject.template,
                desc: action.logObject.desc,
                mqttTopic: action.logObject.mqttTopic,
                isSent: false
            }, ...state];

            localStorage.setItem("logList", JSON.stringify(logs));
            return logs;

        case DELETE_LOG:
            let newList = [];

            newList = logList.filter((log) => log.id !== action.id);

            /*for (let i = 0; i < logList.length; i++) {
                if (logList[i].id === action.id) continue;
                newList.push(logList[i]);
            }*/

            localStorage.setItem("logList", JSON.stringify(newList));
            return newList;

        case SEND_LOG:
            let newState = state.map((log) => {
                if (log.id === action.logObject.id) {
                    return {...log, isSent: true};
                }
                else { return log }
            });
            /*return state.map((log) => {
                if (log.id === action.logObject.id) return {...log, isSent: true};
            });*/
            localStorage.setItem("logList", JSON.stringify(newState));
            return newState;

        default:
            let storageLogList = JSON.parse(localStorage.getItem("logList"));
            if (storageLogList !== null) {
                return storageLogList;
            }
            return state;

    }
};

export default logList
