import {makeStyles} from "@material-ui/core/styles";

/** Styles for DiagChart component */

export default makeStyles(theme => ({
    trendingIcon: {
        scale: 1.2,
        marginTop: 3
    },
    title: {flexGrow: 1}
}));
