import {makeStyles} from "@material-ui/core/styles";

/** Styles for SecondsData component */

export default makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper
    },
    addNewLog: {
        position: "absolute",
        bottom: theme.spacing(4),
        right: theme.spacing(3.5),
    }
}));
