import {SET_LIVE_DATA} from "../actions"

const liveData = (state = [], action) => {
    if (action.type === SET_LIVE_DATA) {
        return action.liveData;
    } else {
        /*default*/
        return state;
    }
};

export default liveData