import React from "react";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import {connect} from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";
import {store} from "../../index";
import {setNotification} from "../../redux/actions";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Notification({status, severity, message}) {

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') return;
        store.dispatch(setNotification({status: false, severity: severity, message: message}))
    };

    return (
        <Snackbar open={status} autoHideDuration={20000} onClose={handleAlertClose}>
            <Alert onClose={handleAlertClose} severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    )
}

Notification.propTypes = {
    status: PropTypes.bool.isRequired,
    severity: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    status: state.notification.status,
    severity: state.notification.severity,
    message: state.notification.message
});

const NotificationManager = connect(mapStateToProps, null)(Notification);

export default NotificationManager
