import {connect} from "react-redux";
import React from "react";
import {Route} from "react-router-dom";
import PropTypes from "prop-types";
import SecondsData from "./pages/secondsdata/SecondsData";
import Settings from "./pages/settings/Settings";
import {ConnectionStatus} from "../redux/actions";
import {useGlobalStyles} from "./App";
import DiagListContainer from "./pages/diagnostic/DiagList/DiagList";
import AppFrameContainer from "./templates/AppFrame";
import LogBookContainer from "./pages/logbook/LogBook";
import LiveData from "./pages/livedata/LiveData";
import DiagnosticContainer from "./pages/diagnostic/Diagnostic";
import HistoricalData from "./pages/historicaldata/HistoricalData";

function PageSelector(props) {

    const parentPath = props.match.path;

    return (
            <AppFrameContainer>
                <Route exact path={parentPath + "/diagnosztika"} component={
                    (props.connectionStatus === ConnectionStatus.CONNECTED || props.connectionStatus === ConnectionStatus.PAUSED) ? DiagListContainer : DiagnosticContainer
                }/>
                <Route exact path={parentPath + "/elo_adatok"} component={LiveData}/>
                <Route exact path={parentPath + "/uzemnaplo"} component={LogBookContainer}/>
                <Route exact path={parentPath + "/havaria_adatok"} component={SecondsData}/>
                <Route exact path={parentPath + "/hisztorikus_adatok"} component={HistoricalData}/>
                <Route exact path={parentPath + "/beallitasok"} component={Settings}/>
            </AppFrameContainer>
        )
}

PageSelector.propTypes = {
    connectionStatus: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    connectionStatus: state.connection.connectionStatus.status
});

const PageSelectorContainer = connect(mapStateToProps, null)(PageSelector);
export default PageSelectorContainer;
