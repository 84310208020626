import {makeStyles} from "@material-ui/core/styles";

/** Styles for LogDialog component */

export default makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    container: {
        marginTop: theme.spacing(3)
    },
    nameTextField: {
        width: 200
    },
    categorySelector: {
        width: 250
    },
    templateSelector: {
        width: 250
    },
    timePicker: {
        width: 250
    },
    description: {}
}));
