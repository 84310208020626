import {ExportToCsv} from "export-to-csv";

/** Utils for LiveDataTable component */

export function exportCSV(currentLiveData) {

    let fullVAVLObjectArray = [];

    for (let i = 0; i < currentLiveData.metrics.length; i++) {
        fullVAVLObjectArray.push({
            időpont: currentLiveData.metrics[i].date + " " + currentLiveData.metrics[i].time,
            érték: currentLiveData.metrics[i].value + " " + currentLiveData.dim
        });
    }

    const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        filename: currentLiveData.name + '_elo_adatok',
        showLabels: true,
        showTitle: true,
        title: currentLiveData.name + ' Élő Adatok',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };

    new ExportToCsv(options).generateCsv(fullVAVLObjectArray);

}
