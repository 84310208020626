import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Typography from "@material-ui/core/Typography";
import {
    CommandBufferStatus,
    ConnectionMessage,
    ConnectionMessageSeverity,
    ConnectionStatus,
    setCommandBuffer,
    setConnectionStatus
} from "../../redux/actions";
import TurnOffIcon from "@material-ui/icons/PowerSettingsNew";
import Drawer from "@material-ui/core/Drawer";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import Navigation from "../navigation/Navigation";
import {useGlobalStyles} from "../App";
import PropTypes from "prop-types";
import PageFrame from "./PageFrame";
import {connect} from "react-redux";
import {store} from "../../index";
import {Link} from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import NotificationManager from "./Notification";

function createOnBrowserCloseDialog(state, handleClose) {
    return (
        <div>
            <Dialog
                open={state}>
                <DialogTitle id="alert-dialog-title">{"Az MQTT kommunikáció letiltva maradt!"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Amennyiben kikapcsolva marad, a Veribox nem fog semmilyen adatot továbbítani a központnak.
                        Biztosan szeretné a felküldést letiltani?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} id={"onBrowserCloseDialogDisagree"} color="secondary">
                        Igen, maradhat letiltva!
                    </Button>
                    <Button onClick={handleClose} id={"onBrowserCloseDialogAgree"} color="primary" autoFocus>
                        Mégsem
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

function AppFrame({children, connectionStatus, currentPage, config, commandBufferStatus, disconnect}) {

    const classes = useGlobalStyles();

    const [open, setOpen] = React.useState(false);
    const [onBrowserCloseDialogStatus, setOnBrowserCloseDialogStatus] = React.useState(false);
    let isAlarmSendDisabled = (config.GTFL2 === "0");
    let toolbarItemsInvisibility = commandBufferStatus === CommandBufferStatus.COMMAND_REQUESTED;

    const handleAlarmSendStatusChange = event => {
        store.dispatch(setConnectionStatus({
            status: ConnectionStatus.PAUSED,
            message: ConnectionMessage.PAUSED,
            ConnectionMessageSeverity: ConnectionMessageSeverity.LOW
        }));
        store.dispatch(setCommandBuffer({
            commandBuffer: [{
                paramName: "alarmSend",
                paramIndex: "2",
                paramValue: (isAlarmSendDisabled ? "65" : "0")
            }], commandBufferStatus: CommandBufferStatus.COMMAND_REQUESTED
        }));
    };

    const handleBrowserCloseDialog = event => {
        if (event.currentTarget.id === "onBrowserCloseDialogAgree") {
            // handleAlarmSendStatusChange();
        } else {
            store.dispatch(setConnectionStatus(
                {
                    status: ConnectionStatus.DISCONNECT_REQUESTED,
                    message: ConnectionMessage.DISCONNECTING,
                    messageSeverity: ConnectionMessageSeverity.LOW
                }))
        }
        setOnBrowserCloseDialogStatus(false)
    };

    const handleDisconnect = event => {

        if (isAlarmSendDisabled && (connectionStatus === ConnectionStatus.CONNECTED))
            setOnBrowserCloseDialogStatus(true);
        else
            store.dispatch(setConnectionStatus(
                {
                    status: ConnectionStatus.DISCONNECT_REQUESTED,
                    message: ConnectionMessage.DISCONNECTING,
                    messageSeverity: ConnectionMessageSeverity.LOW
                }))
    };

    const OnBrowserCloseDialog = createOnBrowserCloseDialog(onBrowserCloseDialogStatus, handleBrowserCloseDialog);

    /*let beforeunloadListener = (e) => {
        if (isAlarmSendDisabled && connectionStatus === ConnectionStatus.CONNECTED) {
            setOnBrowserCloseDialogStatus(true);
            e.preventDefault();
            e.returnValue = "";
        }
    };
    if (isAlarmSendDisabled && connectionStatus === ConnectionStatus.CONNECTED) {
        if (!isEventRegistered) {
            window.addEventListener("beforeunload", beforeunloadListener);
            setIsEventRegistered(true)
        }
    } else if (!isAlarmSendDisabled || connectionStatus === ConnectionStatus.DISCONNECTED) {
        window.removeEventListener("beforeunload", beforeunloadListener)
    }*/

    /*window.addEventListener("beforeunload", function (e) {
        let alaramSend = (store.getState().config.GTFL2 === "0");
        let isConnected = (store.getState().connectionStatus === ConnectionStatus.CONNECTED);

        if (alaramSend && isConnected) {
            setOnBrowserCloseDialogStatus(true);
            e.preventDefault();
            e.returnValue = ""
        }
    });*/

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                        component={props => <Link to={"/bejelentkezes"} {...props} />}
                    >
                        <ExitToAppIcon/>
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        {currentPage}
                    </Typography>
                    {(connectionStatus === ConnectionStatus.CONNECTED || connectionStatus === ConnectionStatus.PAUSED) &&
                    <FormControlLabel
                        label={(isAlarmSendDisabled ? "MQTT kommunikáció letiltva" : "MQTT kommunikáció engedélyezve")}
                        labelPlacement={"start"}
                        control={<Switch checked={isAlarmSendDisabled} onChange={handleAlarmSendStatusChange}
                                         name="AlertStatus" disabled={toolbarItemsInvisibility}/>}
                    />}
                    {(connectionStatus === ConnectionStatus.CONNECTED || connectionStatus === ConnectionStatus.PAUSED) &&
                    <Divider className={classes.toolbarDivider} orientation="vertical" flexItem color={"inherit"}/>}
                    {(connectionStatus === ConnectionStatus.CONNECTED || connectionStatus === ConnectionStatus.PAUSED) &&
                    <IconButton color="inherit" onClick={handleDisconnect}
                                disabled={toolbarItemsInvisibility}><TurnOffIcon/></IconButton>}
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent"
                    classes={{paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),}} open={open}>
                <div className={classes.toolbarIcon}>
                    <IconButton>
                        <ChevronLeftIcon/>
                    </IconButton>
                </div>
                <Divider/>
                <List>
                    <Navigation/>
                </List>
            </Drawer>
            <CssBaseline/>
            <PageFrame>
                {children}
            </PageFrame>
            {OnBrowserCloseDialog}
            <NotificationManager/>
        </div>
    )
}

AppFrame.propTypes = {
    children: PropTypes.node,
    connectionStatus: PropTypes.string.isRequired,
    disconnect: PropTypes.func.isRequired,
    currentPage: PropTypes.string.isRequired,
    config: PropTypes.object.isRequired,
    commandBufferStatus: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    connectionStatus: state.connection.connectionStatus.status,
    currentPage: state.pages,
    config: state.deviceConfiguration,
    commandBufferStatus: state.commandBuffer.commandBufferStatus
});

const AppFrameContainer = connect(mapStateToProps, null)(AppFrame);

export default AppFrameContainer
