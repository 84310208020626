
/** Utils for SecondsData component */

export function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export function convertCSVIntoJSON(csv) {

    if (!csv) return null;

    let rows = csv.split("\n");
    let header = rows[0].split(";");

    let havariaJSON = [
        {
            name: header[3],
            metrics: []
        },
        {
            name: header[5],
            metrics: []
        }
    ];

    for (let i = 0; i < rows.length; i++) {
        let currentRow = rows[i];
        let currentCells = currentRow.split(";");

        havariaJSON[0].metrics.push({
            date: currentCells[1].split(".").reverse().join(".") + ".",
            time: currentCells[2],
            value: currentCells[4]
        });
        havariaJSON[1].metrics.push({
            date: currentCells[1].split(".").reverse().join(".") + ".",
            time: currentCells[2],
            value: currentCells[6]
        });
    }

    return havariaJSON;

}
