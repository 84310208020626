import React from "react";
import PropTypes from "prop-types"
import Grid from "@material-ui/core/Grid";

export default function LinearLayout(props) {

    let orientation = "row";
    let itemSpacing = props.itemSpacing;
    let isItem = false;
    let itemsPositionHorizontal; //Alapesetben ez az justify
    let itemsPositionVertical; //Alapesetben ez az align-items
    let lg = false;
    let md = false;
    let sm = false;

    if (props.orientation === "horizontal") {
        itemsPositionHorizontal = "flex-start";
        itemsPositionVertical = "stretch";
    } else {
        itemsPositionHorizontal = "stretch";
        itemsPositionVertical = "flex-start";
    }

    if (props.orientation === "vertical") {
        orientation = "column";
    }

    if (props.item != null ) {
        isItem = props.item;
    }

    if (props.lg != null) {
        lg = props.lg;
    }

    if (props.md != null) {
        md = props.md;
    }

    if (props.sm != null) {
        sm = props.sm;
    }

    if (props.itemsPositionHorizontal != null) {
        itemsPositionHorizontal = props.itemsPositionHorizontal;
    }

    if (props.itemsPositionVertical != null) {
        itemsPositionVertical = props.itemsPositionVertical;
    }

    return (
        <Grid container item={ isItem } direction={ orientation }
              spacing={ itemSpacing }
              className={props.className}
              alignItems={ props.orientation === "horizontal" ? itemsPositionVertical : itemsPositionHorizontal } //cross axis
              justify={ props.orientation === "horizontal" ? itemsPositionHorizontal : itemsPositionVertical} //main Axis
              lg={ lg } md={ md } sm={ sm }
              >
            { props.children }
        </Grid>
    )

}

LinearLayout.propTypes = {
    orientation: PropTypes.string.isRequired,
    itemSpacing: PropTypes.number.isRequired,
    item: PropTypes.bool,
    className: PropTypes.object,
    itemsPositionHorizontal: PropTypes.string,
    itemsPositionVertical: PropTypes.string,
    lg: PropTypes.number,
    md: PropTypes.number,
    sm: PropTypes.number,
};