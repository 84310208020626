// General
import React from "react";
import PropTypes from "prop-types";
// Other
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
// Styles
import {useGlobalStyles} from "../../../App";
import {useTheme} from "@material-ui/core/styles";
import clsx from "clsx";

export default function SecondsDataChart({chartData}) {
    // Styles
    const classes = useGlobalStyles();
    const theme = useTheme();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    // Calculate y axis maximum value
    let yMaxVal = (1.2 * Math.max.apply(Math, chartData.metrics.map(metric => metric.value))).toFixed(1);
    yMaxVal = chartData.metrics[9].value === 0 ? 0 : parseInt(yMaxVal);

    return (
        <div className={fixedHeightPaper}>
            <ResponsiveContainer>
                <AreaChart data={chartData.metrics} margin={{top: 16, right: 10, bottom: 0, left: 10,}}>
                    <defs>
                        <linearGradient id="colorIndex" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={theme.palette.primary.main} stopOpacity={0.8}/>
                            <stop offset="95%" stopColor={theme.palette.primary.main} stopOpacity={0.05}/>
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="time" hide={true}/>
                    <YAxis domain={[0, yMaxVal]}/>
                    <CartesianGrid strokeDasharray="7"/>
                    {/*<ReferenceLine y={liveInput.limit} stroke="red"/>*/}
                    <Tooltip
                        wrapperStyle={{backgroundColor: "white"}}
                        labelStyle={{color: theme.palette.primary.main}}
                        itemStyle={{color: theme.palette.primary.main}}
                        formatter={function (value, name) {
                            return `${value}`; //${chartData.dim};
                        }}
                        labelFormatter={function (value) {
                            return `időpont: ${value}`;
                        }}/>
                    <Area type="monotone" dataKey="value"
                          stroke={theme.palette.primary.main}
                          fillOpacity={1} fill="url(#colorIndex)"
                          dot={true}/>
                </AreaChart>
            </ResponsiveContainer>
        </div>
    )
}

// Property definitions
SecondsDataChart.propTypes = {
    chartData: PropTypes.object.isRequired
};
