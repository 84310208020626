import {makeStyles} from "@material-ui/core/styles";

/** Styles for Navigation component */

export default makeStyles(theme => ({
    icon: {
        color: theme.palette.secondary.main,
        marginLeft: 8
    },
    iconActive: {
        color: theme.palette.primary.main
    },
    listItem: {
        marginTop: 15
    }
}));
