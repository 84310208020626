// General
import React from "react";
// Material-UI
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// Own Component
import TabPanel from "../../templates/TabPanel";
import SecondsDataTable from "./SecondsDataTable/SecondsDataTable";
import SecondsDataChart from "./SecondsaDataChart/SecondsDataChart";
// Styles
import {makeStyles, useTheme} from '@material-ui/core/styles';
import useHavariaDataStyles from "./SecondsData.styles";
import clsx from "clsx";
// Utils
import {a11yProps, convertCSVIntoJSON} from "./SecondsData.utils";


function createTabList() {

    let FinalTabList = [];

    for (let i = 0; i < 2; i++) {
        let havariaData = convertCSVIntoJSON(sessionStorage.getItem("HAVARIA_BUFFER"));
        if (havariaData !== null) {
            havariaData = havariaData[i];
            FinalTabList.push(<Tab label={havariaData.name} {...a11yProps(i)} disabled={false}/>)
        } else {
            FinalTabList.push(<Tab label={"VAVL" + (i + 1)} {...a11yProps(i)} disabled={true}/>)
        }
    }

    return FinalTabList;
}

function createTabPanelList(value, theme, fixedHeightPaper) {

    let FinalTabPanelList = [];

    for (let i = 0; i < 2; i++) {
        let havariaData = convertCSVIntoJSON(sessionStorage.getItem("HAVARIA_BUFFER"));

        if (havariaData !== null) {
            havariaData = havariaData[i];
            FinalTabPanelList.push(
                <TabPanel value={value} index={i} dir={theme.direction}>
                    <Paper className={fixedHeightPaper}>
                        <SecondsDataChart chartData={havariaData}/>
                    </Paper>
                    <div style={{height: "30px"}}/>
                    <SecondsDataTable havariaData={havariaData}/>
                </TabPanel>
            )

        } else FinalTabPanelList.push(
            <TabPanel value={value} index={i} dir={theme.direction}>
                <Typography component="h2" variant="h5" color="textSecondary" gutterBottom align={"center"}>
                    Csatlakozzon egy Veribox eszközhöz az adatok rögzítéséhez!
                </Typography>
            </TabPanel>)
    }

    return FinalTabPanelList;
}

export default function SecondsData() {
    // Styles
    const classes = useHavariaDataStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const theme = useTheme();

    // Selected Tab value
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => setValue(newValue);
    const handleChangeIndex = index => setValue(index);

    // Create Tabs
    let TabList = createTabList();
    let TabPanelList = createTabPanelList(value, theme, fixedHeightPaper);

    return (
        <div style={{flexGrow: 1, width: '100%'}}>
            <Box display={"flex"} p={1} flexDirection="column">
                <Box p={1}>
                    <AppBar position="static" color="default">
                        <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary"
                              variant="fullWidth" scrollButtons="auto" centered={true}>
                            {TabList}
                        </Tabs>
                    </AppBar>
                </Box>
                <Box p={1}>
                    <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value} onChangeIndex={handleChangeIndex}>
                        {TabPanelList}
                    </SwipeableViews>
                </Box>
            </Box>
        </div>
    )
}
