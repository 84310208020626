import {SET_ALARM_SEND_STATUS} from "../actions";

const alarmSendStatus = (state = false, action) => {
    if (action.type === SET_ALARM_SEND_STATUS) {
        return action.isAlarmSendEnabled
    } else {
        /*default*/
        return state;
    }
};

export default alarmSendStatus