import {makeStyles} from "@material-ui/core/styles";

/** Styles for LiveDataTable component */

export default makeStyles(theme => ({
    table: {
        minWidth: 650,
    },
    exportIcon: {
        marginRight: "24px",
    },
    toolbar: {
        flex: 1,
        paddingTop: theme.spacing(2),
        paddingRight: "20px"
    }
}));
