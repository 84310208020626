let LATEST_METRIC;

/**
 * Returns the value between two numbers at a specified, decimal midpoint.
 * */
function linIntPol(firstVal, secondVal, midPoint) {
    return(firstVal * (1 - midPoint) + secondVal * midPoint);
}

function getDateString(date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    const hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    const minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    const seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

    // Set the newly calculated timestamp to the created row
    return (`${year}.${month}.${day} ${hours}:${minutes}:${seconds}`);
}

function fillTimeGaps(data) {
    const dataWithoutTimeGaps = [];

    if (LATEST_METRIC && data.length > 0) {
        // Get current row's timestamp
        const firstRowTime = new Date(data[0]["Dátum"]);

        // Get upcoming row's timestamp
        const latestRowTime = new Date(LATEST_METRIC["Dátum"]);

        // Calculate the time gap in minutes
        let timeGap = Math.floor((firstRowTime.getTime() - latestRowTime.getTime()) / (60 * 1000));

        for (let i = 1; i <= timeGap; i++) {

            // Calculate the date
            const date = new Date(latestRowTime.getTime() + i * (60 * 1000));

            // Calculate values with linear interpolation
            let pBe = linIntPol(LATEST_METRIC["Pbe (mbar)"], data[0]["Pbe (mbar)"], (1/timeGap) * i);
            let pKi = linIntPol(LATEST_METRIC["Pki (mbar)"], data[0]["Pki (mbar)"], (1/timeGap) * i);

            // Round values to 2 decimals
            pBe = Math.round((pBe + Number.EPSILON) * 100) / 100
            pKi = Math.round((pKi + Number.EPSILON) * 100) / 100

            // Push the formatted new row
            dataWithoutTimeGaps.push({
                ...LATEST_METRIC,
                "Dátum": getDateString(date),
                "Pbe (mbar)": pBe,
                "Pki (mbar)": pKi,
            });
        }
    }

    data.forEach((currentRowObj, index) => {

        // Push row into the new array
        dataWithoutTimeGaps.push(currentRowObj);

        // Check if there is an upcoming item
        if (data[index + 1]) {
            // Get current row's timestamp
            const currentRowTime = new Date(currentRowObj["Dátum"]);

            // Get upcoming row's timestamp
            const upcomingRowTime = new Date(data[index + 1]["Dátum"]);

            // Calculate the time gap in minutes
            let timeGap = Math.floor((upcomingRowTime.getTime() - currentRowTime.getTime()) / (60 * 1000));
            //if (currentRowTime.getSeconds() === upcomingRowTime.getSeconds()) timeGap--;

            for (let i = 1; i <= timeGap; i++) {

                // Calculate the date
                const date = new Date(currentRowTime.getTime() + i * (60 * 1000));

                // Calculate values with linear interpolation
                let pBe = linIntPol(currentRowObj["Pbe (mbar)"], data[index+1]["Pbe (mbar)"], (1/timeGap) * i);
                let pKi = linIntPol(currentRowObj["Pki (mbar)"], data[index+1]["Pki (mbar)"], (1/timeGap) * i);

                // Round values to 2 decimals
                pBe = Math.round((pBe + Number.EPSILON) * 100) / 100
                pKi = Math.round((pKi + Number.EPSILON) * 100) / 100

                // Push the formatted new row
                dataWithoutTimeGaps.push({
                    ...currentRowObj,
                    "Dátum": getDateString(date),
                    "Pbe (mbar)": pBe,
                    "Pki (mbar)": pKi
                });
            }
        }
    })

    // Return filled data
    return (dataWithoutTimeGaps);
}

function getTimeGapInSeconds(m1, m2) {
    return ((new Date(m1["Dátum"]).getTime() - new Date(m2["Dátum"]).getTime()) / 1000);
}

/**
 * Filters the metrics so that there is no time difference of more than 1 minute between two metrics.
 * */
function filterOnlyMinutesData(metrics) {

    let filteredData = [];

    let jump;
    for (let i = 0; i < metrics.length; i = i + jump) {
        jump = 1;

        let shouldBeSaved = true;
        let currentMetric = metrics[i];

        if (LATEST_METRIC && getTimeGapInSeconds(currentMetric, LATEST_METRIC) < 60) {
            shouldBeSaved = false;
            currentMetric = LATEST_METRIC;
        }

        // Reset
        //LATEST_METRIC = null;

        let index = i + 1;
        while (metrics[index] !== undefined && getTimeGapInSeconds(metrics[index], currentMetric) < 60) {
            index++;
            jump++;
        }

        if (shouldBeSaved) {
            filteredData.push(currentMetric);
        }
    }

    return (filteredData);
}

export default (metrics) => {

    /* PHASE 1: Filtering */

    const filteredData = filterOnlyMinutesData(metrics);

    /* PHASE 2: Filling the time gaps */

    const filledData = fillTimeGaps(filteredData);

    /* PHASE 3: Save the latest metric */

    if (filteredData.length > 0) {
        LATEST_METRIC = filledData[filledData.length - 1];
    }

    return(filledData);
}