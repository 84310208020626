// Redux
import {store} from "../../../../index";
import {
    CommandBufferStatus,
    ConnectionMessage,
    ConnectionMessageSeverity,
    ConnectionStatus,
    sendLog, setCommandBuffer,
    setConnectionStatus,
    setNotification
} from "../../../../redux/actions";
// MQTT
import Paho from 'paho-mqtt'
// Utils
import {LOGBOOK_SEND_DIRECT, LOGBOOK_SEND_VIA_VERIBOX} from "../LogBook.utils";

function composeMessageString(msgObj) {

    let eventTime = Math.round(new Date(msgObj.startDate).getTime() / 1000);

    msgObj.category = msgObj.category.normalize("NFD").replace(/[\u0300-\u036f]|;|,|\//g, "");
    msgObj.desc = msgObj.desc.normalize("NFD").replace(/[\u0300-\u036f]|;|,|\//g, "");
    msgObj.name = msgObj.name.normalize("NFD").replace(/[\u0300-\u036f]|;|,|\//g, "");

    return [eventTime, msgObj.category, msgObj.desc, msgObj.name].join(";");

}

function publishDirect(msgObj) {

    let messageString;
    let mqttClient;

    const MQTT_BROKER = localStorage.getItem('mqttBroker');
    const MQTT_PORT = localStorage.getItem('mqttPort');
    const MQTT_PATH = localStorage.getItem('mqttPath');
    const MQTT_USER = localStorage.getItem('mqttUser');
    const MQTT_PASSWORD = localStorage.getItem('mqttPassword');

    messageString = composeMessageString(msgObj);

    // Create client instance
    let clientID = msgObj.mqttTopic.slice(msgObj.mqttTopic.lastIndexOf('/')+1);
    mqttClient = new Paho.Client(MQTT_BROKER, Number(MQTT_PORT), MQTT_PATH, clientID);

    // Set callback handlers
    mqttClient.onConnectionLost = onConnectionLost;
    mqttClient.onMessageArrived = onMessageArrived;
    mqttClient.onMessageDelivered = onMessageDelivered;
    mqttClient.onFailure = onFailure;

    // Connect to the broker
    mqttClient.connect({
        onSuccess: onConnect,
        userName: MQTT_USER,
        password: MQTT_PASSWORD
    } );

    function onConnectionLost(responseObject) {

        if (responseObject.errorCode !== 0) {

            console.error('LogbookMQTT: Connection lost');

            store.dispatch(
                setNotification({
                    status: true,
                    severity: 'error', message: 'Kapcsolat megszakadt, próbálja újra'}
                ));

        }
    }

    function onConnect() {

        let mqttMessage;

        console.log('LogbookMQTT: Connected');
        mqttMessage = new Paho.Message(messageString);
        mqttMessage.destinationName = msgObj.mqttTopic + '/Log';
        mqttMessage.retained = true;
        mqttClient.publish(mqttMessage);
        mqttClient.disconnect();

    }

    function onMessageDelivered(message) {

        store.dispatch(sendLog(msgObj));

        store.dispatch(
            setNotification({
                status: true,
                severity: 'success', message: 'Sikeres felküldés'}
            ));

    }

    function onFailure() {

        console.error('LogbookMQTT: Connection error');

        store.dispatch(
            setNotification({
                status: true,
                severity: 'error', message: 'A szerverhez kapcsolódás sikertelen. Ellenőrizze a beállításokat.'}
            ));

    }

    function onMessageArrived(message) {
        console.log('LogbookMQTT:'+ message.payloadString);
    }
}

function forwardToVeribox(msgObj) {

    let commandBuffer;
    let messageString;

    messageString = composeMessageString(msgObj);

    commandBuffer = [
        { paramName: 'logbook', paramIndex: '', paramValue: messageString },    // save logbook to veribox
        { paramName: 'gsmONOFF', paramIndex: '', paramValue: 1 },               // force immediate GSM connection 1=ON
    ];

    store.dispatch(setConnectionStatus({
        status: ConnectionStatus.PAUSED,
        message: ConnectionMessage.PAUSED,
        ConnectionMessageSeverity: ConnectionMessageSeverity.LOW
    }));

    store.dispatch(setCommandBuffer({
        commandBuffer,
        commandBufferStatus: CommandBufferStatus.COMMAND_REQUESTED
    }));

}

function mqttPublishLogbook( sendMode, msgObj) {

    if ( sendMode === LOGBOOK_SEND_DIRECT )
        publishDirect(msgObj);
    else if ( sendMode === LOGBOOK_SEND_VIA_VERIBOX )
        forwardToVeribox(msgObj);

}

export default mqttPublishLogbook;




