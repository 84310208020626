import React from 'react';
import ReactDOM from 'react-dom';
import {ThemeProvider} from '@material-ui/core/styles';
import theme from './styles/theme';
import registerCommWorker from "./wokerHandler";
import {createStore} from "redux";
import stateManager from "./redux/reducers";
import {devToolsEnhancer} from "redux-devtools-extension";
import {Provider} from "react-redux";
import App from "./components/App";
import * as serviceWorker from './serviceWorker';
import {publishChunk} from "./redux/utils/tester";

export const store = createStore(stateManager, devToolsEnhancer());
export const appVersion = "v2.11";
export const DEBUG_MODE = process.env.NODE_ENV !== 'production';

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <App/>
        </ThemeProvider>
    </Provider>
    ,
    document.querySelector('#root'),
);

serviceWorker.register();
registerCommWorker();
initLocalStorage();
//setTimeout(publishChunk, 1000);

function initLocalStorage() {

    localStorage.getItem('mqttBroker') ||
    localStorage.setItem('mqttBroker', 'broker.hivemq.com');

    localStorage.getItem('mqttPort') ||
    localStorage.setItem('mqttPort', '8000');

    localStorage.getItem('mqttPath') ||
    localStorage.setItem('mqttPath', '/mqtt');

    localStorage.getItem('mqttUser') ||
    localStorage.setItem('mqttUser', '');

    localStorage.getItem('mqttPassword') ||
    localStorage.setItem('mqttPassword', '');

    localStorage.getItem('gsmTimeoutMillisec') ||
    localStorage.setItem('gsmTimeoutMillisec', '40000');

}



