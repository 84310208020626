import {combineReducers} from "redux";
import pages from "./pages";
import connection from "./connection";
import deviceConfiguration from "./deviceConfiguration";
import liveData from "./liveData";
import logList from "./logList";
import commandBuffer from "./commadBuffer";
import alarmSendStatus from "./alarmSendStatus";
import notification from "./notification";
import historicalData from "./historicalData";

const stateManager = combineReducers(
    {
        pages,
        connection,
        deviceConfiguration,
        liveData,
        logList,
        commandBuffer,
        notification,
        alarmSendStatus,
        historicalData
    }
);

export default stateManager
