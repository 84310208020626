// General
import React from 'react';
import PropTypes from "prop-types";
// Own Component
import Title from '../../../templates/Title';
// Material-UI
import Typography from '@material-ui/core/Typography';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";


export default function DiagCard({liveInput}) {

    // Create List of the last five live input values
    const lastFiveValuesList = liveInput.metrics.slice(-6).reverse().map(metric => (
            <ListItem>
                <Typography component="p" variant="h6">
                    {metric.time} -> {metric.value} {liveInput.dim}
                </Typography>
            </ListItem>
        )
    );

    return (
        <React.Fragment>
            <Title>{liveInput.name}</Title>
            <List>
                {lastFiveValuesList}
            </List>
        </React.Fragment>
    );
}

// Property definitions
DiagCard.protoTypes = {
    liveInput: PropTypes.object.isRequired
};
