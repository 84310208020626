export default () => {

    var websocket;              // Websocket to device
    var wsUri;                  // Websocket uri
    var intervalTimer;          // General interval timer used for periodic events
    var timeoutTimer;           // General timeout timer, used to measure wait time

    var wakeUpResponse;         // Buffer where wake up response will be collected
    var commandModeResponse;    // Buffer where command mode response will be collected
    var dumpResponse;           // Buffer where dump response will be collected
    var havariaResponse;        // Buffer where havaria response will be stored
    var liveDataResponse;       // Buffer where live data response will be collected
    var commandResponse;        // Buffer where command response will be collected
    var deviceConfiguration;    // JSON Object containing data from device dump

    var ConnectionMessage;
    var ConnectionStatus;
    var commandBuffer;
    var currentCmd;
    var isCommandFailed;
    var CommandBufferStatus;

    var liveQueryArray;         // Helper array where live query parameters are stored
    var liveQueryIndex;         // Holds the actual liveQuery index being queried
    var liveDataArray;          // Array containing live parameters
    const LIVE_DECIMALS = 3;    // Decimals the live data round to

    const timeout = {
        CONNECT: 2200,
        WAKE_UP: 20000,
        COMMAND_MODE: 1000,
        DUMP: 10000,
        QUERY: 300,
        HAVARIA: 12000,
        GUARD_DUMP: 700,
        GUARD_QUERY: 350
    };

    const cycletime = {
        WAKE_UP: 100,
        LIVE_QUERY: 5000
    };


    // Sleeps the process for a given time interval then execute the next step
    function nextStep(sleepTime, nextFunc, funcParam) {

        // Guard internal for dump request
        postMessage({
            debug: `serialWorker: Sleep ${sleepTime} msecs then proceed to ${nextFunc.name}`
        });

        // Select function to execute after the sleep time
        setTimeout(nextFunc, sleepTime, funcParam);

    }

    /* INIT WORKER */

    /* eslint-disable-next-line no-restricted-globals */
    self.addEventListener('message', function (e) {

        let msgFromMainThread;

        // eslint-disable-line no-restricted-globals
        if (!e) return;

        msgFromMainThread = e.data;

        // Connect command from main thread
        if (msgFromMainThread.action === 'connect') {

            ConnectionMessage = msgFromMainThread.params.ConnectionMessage;
            ConnectionStatus = msgFromMainThread.params.ConnectionStatus;
            CommandBufferStatus = msgFromMainThread.params.CommandBufferStatus;

            wsUri = msgFromMainThread.params.uri;
            postMessage({debug: 'serialWorker: WORKER STARTED'});
            postMessage(ConnectionMessage.CONNECTING_TO_WS);
            connectSocket(wsUri);
        }

        // Disconnect command from main thread
        if (msgFromMainThread.action === 'disconnect') {
            cleanUp();
        }

        // Save command from main thread
        if (msgFromMainThread.action === 'command') {
            commandBuffer = msgFromMainThread.params;
            postMessage({error: commandBuffer.length + ' command(s) received '});
        }

    }, false);


    /* WAKE UP DEVICE */

    // Start sending '!' characters periodically to wake up the device
    function wakeUp() { // websocket.onopen callback

        var wakeUpCounter = 0;

        // Stop websocket's connection timeout timer
        clearTimeout(timeoutTimer);

        // Redirect websocket.onmessage function to collect wake up response
        websocket.onmessage = (evt) => collectWakeUpResponse(evt);

        // Send two EOF chars before sending '!'s
        websocket.send('\x04\x04');
        // Start sending '!' characters periodically to wake up the device
        intervalTimer = setInterval(() => {

            wakeUpCounter++;

            if (websocket && websocket.readyState === websocket.OPEN) {
                websocket.send('!');
                postMessage({debug: 'serialWorker: "!" sent'});

                postMessage({
                    status: ConnectionStatus.WAKING_UP,
                    message: ConnectionMessage.WAKING_UP + " (" + wakeUpCounter + ")"
                });
            }
        }, cycletime.WAKE_UP);

        // Communicate status change
        postMessage({
            status: ConnectionStatus.WAKING_UP,
            message: ConnectionMessage.WAKING_UP
        });

        postMessage({debug: 'serialWorker: Wake up start'});

        // Init wake up timeout timer
        timeoutTimer = setTimeout(() => {
            // Communicate status change
            postMessage({
                status: ConnectionStatus.WAKING_UP_FAILED,
                message: ConnectionMessage.WAKING_UP_FAILED
            });
            postMessage({error: 'serialWorker: Wake up timed out'});
            cleanUp();
        }, timeout.WAKE_UP);

    }

    function collectWakeUpResponse(evt) {   // websocket.onmessage callback
        var b = evt.data;
        var reader = new FileReader();

        reader.addEventListener("loadend", function () {

            var array = new Uint8Array(reader.result);
            var s = String.fromCharCode.apply(null, array);
            wakeUpResponse += s;

            // Check if response complete (contains at least 8 dots)
            if (/\.{8,}/.test(wakeUpResponse)) {

                /* Response complete: Device successfully woke up */

                // Stop wake up timeout timer
                clearTimeout(timeoutTimer);

                // Stop interval timer for wakeup (stop sending '!'s)
                clearInterval(intervalTimer);

                // Set onmessage callback to purge all chars received
                websocket.onmessage = (evt) => purgeReceivedChars(evt);

                // Communicate status change
                postMessage({debug: 'serialWorker: Device woke up'});

                // Enter into next state: Request for command mode
                nextStep(timeout.GUARD_DUMP, requestCommandMode);
            } else {
                postMessage({debug: 'serialWorker: char ' + s + ' received'});
            }

        });

        reader.readAsArrayBuffer(b);

    }


    /* REQUEST COMMAND MODE */

    function requestCommandMode(tryOnceWithoutWakeUp) {

        // Stop websocket's connection timeout timer
        tryOnceWithoutWakeUp && clearTimeout(timeoutTimer);

        // Redirect websocket.onmessage function to collect command mode response
        websocket.onmessage = (evt) => collectCommandModeResponse(evt);

        // Send a request for command mode
        websocket.send('\rCOMMAND\r');

        // Communicate status change
        postMessage({debug: 'serialWorker: COMMAND request sent'});

        // Init request command mode timeout timer
        timeoutTimer = setTimeout(() => {

            if (tryOnceWithoutWakeUp) {
                postMessage({debug: 'serialWorker: Not responded to COMMAND MODE, wake up the device'});
                wakeUp();
            } else {
                // Communicate status change
                postMessage({error: 'serialWorker: COMMAND request timed out'});
                cleanUp();
            }

        }, timeout.COMMAND_MODE);

    }

    function collectCommandModeResponse(evt) {
        var b = evt.data;
        var reader = new FileReader();

        reader.addEventListener("loadend", function () {

            var array = new Uint8Array(reader.result);
            var s = String.fromCharCode.apply(null, array);
            commandModeResponse += s;

            // Check if the response contains COMMAND OK pattern
            if (/.*\r\nCOMMAND\r\nOK\r\n/.test(commandModeResponse)) {

                /* Response complete: Command mode successfully set */

                // Stop command mode timeout timer
                clearTimeout(timeoutTimer);

                // Set onmessage callback to purge all chars received
                websocket.onmessage = (evt) => purgeReceivedChars(evt);

                // communicate status change
                postMessage({debug: 'serialWorker: COMMAND OK received'});

                // Request havaria data
                nextStep(timeout.GUARD_QUERY, requestHavaria);
                //nextStep(timeout.GUARD_QUERY, requestDump );
            }

        });

        reader.readAsArrayBuffer(b);

    }


    /* REQUEST HAVARIA */

    function requestHavaria() {

        // Redirect websocket.onmessage function to collect havaria response
        havariaResponse = "";
        websocket.onmessage = (evt) => collectHavariaResponse(evt);

        // Send a request for dump data
        websocket.send('DUMP:8\r');

        // Communicate status change
        postMessage({
            status: ConnectionStatus.GETTING_HAVARIA_BUFFER,
            message: ConnectionMessage.GETTING_HAVARIA_BUFFER
        });

        postMessage({debug: 'serialWorker: Havaria request sent'});

        // Init request timeout timer
        timeoutTimer = setTimeout(() => {

            // Communicate status change
            postMessage({error: 'serialWorker: Havaria request timed out'});
            postMessage({
                status: ConnectionStatus.GETTING_HAVARIA_BUFFER_FAILED,
                message: ConnectionMessage.GETTING_HAVARIA_BUFFER_FAILED
            });
            cleanUp();
        }, timeout.HAVARIA);

    }

    function collectHavariaResponse(evt) {
        var b = evt.data;
        var reader = new FileReader();

        reader.addEventListener("loadend", function () {

            var array = new Uint8Array(reader.result);
            var s = String.fromCharCode.apply(null, array);
            havariaResponse += s;

            // Check if the response contains DUMP end pattern
            if (/.*DUMP=8\r\nOK\r\n$/.test(havariaResponse)) {

                /* Response complete: Havaria data received */

                // Remove unnecessary parts from csv
                havariaResponse = havariaResponse.split("\n").slice(1, 181).join("\n");

                // Stop dump timeout timer
                clearTimeout(timeoutTimer);

                // Set onmessage callback to purge all chars received
                websocket.onmessage = (evt) => purgeReceivedChars(evt);

                // Dispatch havaria data
                postMessage({
                    dispatch: 'havariaData',
                    data: havariaResponse
                });

                // Request device Configuration
                nextStep(timeout.GUARD_DUMP, requestDeviceConfig);

            }

        });

        reader.readAsArrayBuffer(b);

    }


    /* REQUEST DEVICE CONFIG */

    function requestDeviceConfig() {

        // Clear response buffer
        dumpResponse = '';

        // Redirect websocket.onmessage function to collect dump response
        websocket.onmessage = (evt) => collectDeviceConfigResponse(evt);

        // Send a request for dump data
        websocket.send('DUMP:1\r');

        // Communicate status change
        postMessage({
            status: ConnectionStatus.GETTING_DEVICE_CONFIG,
            message: ConnectionMessage.GETTING_DEVICE_CONFIG
        });
        postMessage({debug: 'serialWorker: Device config request sent'});

        // Init request command mode timeout timer
        timeoutTimer = setTimeout(() => {
            // Communicate status change
            postMessage({error: 'serialWorker: DUMP request timed out'});
            postMessage({
                status: ConnectionStatus.GETTING_DEVICE_CONFIG_FAILED,
                message: ConnectionMessage.GETTING_DEVICE_CONFIG_FAILED
            });
            cleanUp();
        }, timeout.DUMP);

    }

    function collectDeviceConfigResponse(evt) {
        var b = evt.data;
        var reader = new FileReader();

        reader.addEventListener("loadend", function () {

            var array = new Uint8Array(reader.result);
            var s = String.fromCharCode.apply(null, array);
            dumpResponse += s;

            // Check if the response contains DUMP end pattern
            if (/.*DUMP=1\r\nOK\r\n$/.test(dumpResponse)) {

                /* Response complete: Dump data received */

                // Stop dump timeout timer
                clearTimeout(timeoutTimer);

                // Set onmessage callback to purge all chars received
                websocket.onmessage = (evt) => purgeReceivedChars(evt);

                // communicate status change
                postMessage({debug: 'serialWorker: Dump received'});

                // Extract device configuration from dump to JSON object
                if (parseDumpToJSON()) {

                    // Init live query and data arrays
                    initLiveQuery(deviceConfiguration);

                } else {
                    // TODO: Handle JSON Parse error
                    postMessage({error: 'serialWorker: DUMPtoJSON parse error'});
                }

            }

        });

        reader.readAsArrayBuffer(b);

    }

    function parseDumpToJSON() {

        // remove unusual chars from the beginning
        dumpResponse = dumpResponse.replace(/.*VPRV/, 'VPRV');

        // remove dump end pattern from the end
        dumpResponse = dumpResponse.slice(0, -12);

        // Convert 'xxx:yyy\r\n' pattern to '"xxx": "yyy",\r\n'
        dumpResponse = dumpResponse.replace(
            /(.{1,6}):(.*)\r\n/gm,
            '"$1": "$2",\r\n');

        // Remove the last unnecessary comma
        dumpResponse = dumpResponse.slice(0, -5);

        // Parse string to JSON object
        try {
            deviceConfiguration = JSON.parse('{' + dumpResponse + '}');
        } catch (error) {
            // TODO: Handle JSON error
            postMessage({error: 'serialWorker: DUMPtoJSON parse error'});
            return false;
        }

        return true;
    }


    /* LIVE DATA */

    function initLiveQuery(devConfig) {

        var key;

        liveQueryArray = [];
        liveDataArray = [];

        for (let i = 1; i <= 16; i++) {

            key = 'VAVL' + i;

            if (key in devConfig) {

                let qs = 'VAVL' + i + '?\r';
                let regx = new RegExp('\r\nVAVL' + i + '=.*\r\nOK\r\n');
                let metrics = new Array(10);
                metrics.fill({time: '--:--', value: 0});

                liveQueryArray.push({
                    queryString: qs,
                    queryRespRegex: regx
                });


                liveDataArray.push({
                    id: 'VAVL' + i,
                    name: (devConfig['VANM' + i] === '' ? 'VAVL' + i : devConfig['VANM' + i]),
                    metrics: metrics,
                    dim: devConfig['VADM' + i],
                    type: devConfig['VAIN' + i],
                    warn_low: devConfig['WAUG' + i],
                    warn_high: devConfig['WAOG' + i],
                    physical_input: devConfig['VAIN' + i],
                    limit_high: devConfig['VAOR' + i],
                    alarm_action: (i > 12 ? '' : devConfig[`WAWD` + i]),
                    alarm_name: (i > 12 ? '' : devConfig[`WANM` + i]),
                    alarm_ok_str: (i > 12 ? '' : devConfig[`WAOK` + i]),
                    alarm_warn_str: (i > 12 ? '' : devConfig[`WAWA` + i]),
                });
            }
        }

        // Dispatch device configuration data to the store
        postMessage({
            dispatch: 'configurationData',
            data: devConfig
        });

        // Dispatch initial live data to the store
        postMessage({
            dispatch: 'liveData',
            data: liveDataArray
        });

        // Start cyclic query with array index 0
        liveQueryIndex = 0;
        nextStep(timeout.GUARD_QUERY, queryLiveData, [liveQueryIndex]);

        postMessage({
            status: ConnectionStatus.CONNECTED,
            message: ConnectionMessage.CONNECTED
        });
    }

    function queryLiveData() {

        let element = liveQueryArray[liveQueryIndex];

        // Init liveDataResponse
        liveDataResponse = '';

        // Redirect websocket.onmessage function to collect keep alive response
        websocket.onmessage = (evt) => collectLiveDataResponse(evt);

        // Send a request for live data
        websocket.send(element.queryString);

        postMessage({debug: `serialWorker: "${element.queryString}" request sent `});

        // Init live query timeout timer
        timeoutTimer = setTimeout(() => {
            // Communicate status change
            postMessage({warning: 'serialWorker: VAVL request timed out'});

            // Process commands if the command buffer not empty
            if (Array.isArray(commandBuffer) && commandBuffer.length !== 0)
                prepareCommandsToSend();
            else
                queryLiveData(liveQueryIndex);

        }, timeout.QUERY);

    }

    function collectLiveDataResponse(evt) {
        var b = evt.data;
        var reader = new FileReader();
        var responseRegex = liveQueryArray[liveQueryIndex].queryRespRegex;

        reader.addEventListener("loadend", function () {

            var array = new Uint8Array(reader.result);
            var s = String.fromCharCode.apply(null, array);
            liveDataResponse += s;

            // Check if the response contains response pattern
            if (responseRegex.test(liveDataResponse)) {

                // Extract live float value from response string
                let currentValue = parseFloat(liveDataResponse.match(/[0-9|\.]+\r/)[0]).toFixed(LIVE_DECIMALS);
                //currentValue = Math.round(Math.random());
                //currentValue = 1;

                liveDataArray[liveQueryIndex].metrics.shift();
                liveDataArray[liveQueryIndex].metrics.push(addTimeStampToValue(currentValue));

                dispatchSaveLiveData(liveQueryIndex, currentValue);

                // Stop keep alive timeout timer
                clearTimeout(timeoutTimer);

                // Set onmessage callback to purge all chars received
                websocket.onmessage = (evt) => purgeReceivedChars(evt);

                postMessage({debug: 'serialWorker: VAVL data received\n'});

                // Check if live Query Array were looped over
                if (liveQueryIndex === liveQueryArray.length - 1) {

                    // Dispatch live data to the store
                    postMessage({
                        dispatch: 'liveData',
                        data: liveDataArray
                    });

                    // Process commands if the command buffer not empty otherwise run further
                    if (Array.isArray(commandBuffer) && commandBuffer.length !== 0)
                        prepareCommandsToSend();
                    else {
                        // Start a new query cycle
                        liveQueryIndex = 0;
                        timeoutTimer = setTimeout(() => (queryLiveData(0)), cycletime.LIVE_QUERY);
                    }

                } else {
                    // Go on with the query of the next VAVL element
                    liveQueryIndex++;
                    nextStep(timeout.GUARD_QUERY, queryLiveData, [liveQueryIndex])
                }

            }

        });

        reader.readAsArrayBuffer(b);

    }

    function addTimeStampToValue(value) {
        let time;

        time = (new Date()).toISOString().split('T')[1].slice(0, 8);
        return {time, value};
    }

    function dispatchSaveLiveData(liveQueryIndex, currentValue) {

        let timeStamp = new Date();

        let currentLiveData = {
            id: liveDataArray[liveQueryIndex].id,
            name: liveDataArray[liveQueryIndex].name,
            metrics: {time: timeStamp, value: currentValue},
            dim: liveDataArray[liveQueryIndex].dim,
            type: liveDataArray[liveQueryIndex].type
        };

        postMessage({
            dispatch: 'saveLiveData',
            data: currentLiveData
        });

    }


    /* COMMAND PROCESSING */

    function prepareCommandsToSend() {

        postMessage({debug: 'Enter to prepareCommandsToSend'});
        // Prevent pending timeout to be called
        clearTimeout(timeoutTimer);

        // Insert password authentication to the first position
        commandBuffer.unshift({commandType: 'SET_ONLY', action: 'PWDN3', value: '00000'});

        // Send out the first command (password)
        currentCmd = commandBuffer.shift();
        isCommandFailed = false;
        sendCommand(currentCmd);

    }

    function sendCommand(cmd) {

        // Get command timeout (default is 10000)
        const cmdTimeout = cmd?.timeout ?? 10000;

        // Redirect websocket.onmessage to the appropriate function based on the command type
        websocket.onmessage = (evt) => {
            if (cmd?.commandType === 'SET_ONLY') processSetOnlyCommand(evt);
            if (cmd?.commandType === 'QUERY_FULL') processQueryFullCommand(evt);
            if (cmd?.commandType === 'QUERY_LINE_BY_LINE') processQueryLineByLineCommand(evt);
        };

        // Send out command
        websocket.send(cmd.action + ':' + cmd.value + '\r');
        commandResponse = '';

        postMessage({debug: 'Command sent: ' + cmd.action + ':' + cmd.value});

        // Init request timeout timer
        timeoutTimer = setTimeout(() => {
            // Communicate status change
            postMessage({error: 'serialWorker: Command timed out'});
            isCommandFailed = true;
            executeNextCommand();
        }, cmdTimeout);

    }

    function processSetOnlyCommand(evt) {
        const b = evt.data;
        const reader = new FileReader();

        reader.addEventListener("loadend", function () {

            const array = new Uint8Array(reader.result);
            const s = String.fromCharCode.apply(null, array);
            let responseOKRegex;
            let responseErrorRegex;
            commandResponse += s;

            // Check if the response contains the command OK pattern
            responseOKRegex = new RegExp(".*" + currentCmd.action + "=" + currentCmd.value + "\r\nOK\r\n$");
            if (responseOKRegex.test(commandResponse)) {

                /* Response complete: OK response received */

                // Stop command timeout timer
                clearTimeout(timeoutTimer);

                // Debug
                postMessage({debug: 'Command response complete. '});

                // Set onmessage callback to purge all chars received
                websocket.onmessage = (evt) => purgeReceivedChars(evt);

                // Update the effected value in device config
                deviceConfiguration[currentCmd.action] = currentCmd.value;

                executeNextCommand(commandBuffer);

            }

            // Check if command failed
            responseErrorRegex = new RegExp(".*" + currentCmd.action + "=" + currentCmd.value + "\r\nERR.*\r\n$");
            if (responseErrorRegex.test(commandResponse)) {

                isCommandFailed = true;
                executeNextCommand(commandBuffer);
            }

        });

        reader.readAsArrayBuffer(b);

    }

    function processQueryFullCommand(evt) {
        const b = evt.data;
        const reader = new FileReader();

        reader.addEventListener("loadend", function () {

            const array = new Uint8Array(reader.result);
            const s = String.fromCharCode.apply(null, array);
            let responseOKRegex;
            let responseErrorRegex;
            commandResponse += s;

            // Check if the response contains the command OK pattern
            responseOKRegex = new RegExp(".*" + currentCmd.action + "=" + currentCmd.value + "\r\nOK\r\n$");
            if (responseOKRegex.test(commandResponse)) {

                /* Response complete: OK response received */

                // Stop command timeout timer
                clearTimeout(timeoutTimer);

                // Debug
                postMessage({debug: 'Command response complete. '});

                // Set onmessage callback to purge all chars received
                websocket.onmessage = (evt) => purgeReceivedChars(evt);

                // Remove the first line from command response
                commandResponse = commandResponse.split("\n").slice(1).join("\n");
                postMessage({
                    dispatch: currentCmd?.dispatch ?? 'defaultQueryDispatch',
                    data: commandResponse
                });

                executeNextCommand(commandBuffer);

            }

            // Check if command failed
            responseErrorRegex = new RegExp(".*" + currentCmd.action + "=" + currentCmd.value + "\r\nERR.*\r\n$");
            if (responseErrorRegex.test(commandResponse)) {

                // Set error flag to true
                isCommandFailed = true;
                executeNextCommand(commandBuffer);
            }

        });

        reader.readAsArrayBuffer(b);

    }

    function processQueryLineByLineCommand(evt) {
        const b = evt.data;
        const reader = new FileReader();

        reader.addEventListener("loadend", function () {

            const array = new Uint8Array(reader.result);
            const s = String.fromCharCode.apply(null, array);
            let responseOKRegex;
            let responseNewLineRegex;
            let responseErrorRegex;
            commandResponse += s;

            // Check if the response contains the command OK pattern
            responseOKRegex = new RegExp(".*" + currentCmd.action + "=" + currentCmd.value + "\r\nOK\r\n$");
            if (responseOKRegex.test(commandResponse)) {

                /* Response complete: OK response received */

                // Stop command timeout timer
                clearTimeout(timeoutTimer);

                // Debug
                postMessage({debug: 'Command response complete. '});

                // Set onmessage callback to purge all chars received
                websocket.onmessage = (evt) => purgeReceivedChars(evt);

                postMessage({
                    dispatch: currentCmd?.dispatch ?? 'defaultQueryDispatch',
                    data: commandResponse.split("\n")
                });

                executeNextCommand(commandBuffer);
                return;

            }

            // Check if command failed
            responseErrorRegex = new RegExp(".*" + currentCmd.action + "=" + currentCmd.value + "ERR.*\r\n$");
            if (responseErrorRegex.test(commandResponse)) {

                // Set error flag to true
                isCommandFailed = true;
                executeNextCommand(commandBuffer);
            }

            // Get the dispatch frequency to know how many lines the data should contain (default is 1)
            const dispatchFreq = currentCmd?.dispatchFreq ?? 1;
            // Determine weather the dispatch frequency met?
            const isDispatchFreqFulfilled = commandResponse.split("\n").length >= dispatchFreq;

            // Enter the dispatch process, if the data contains an appropriate number of lines
            if (isDispatchFreqFulfilled) {
                const lines = [];

                // Collect every full line data from commandResponse, and leave the rest in it
                let indexOfNewLineChar = commandResponse.indexOf("\n");
                while (indexOfNewLineChar !== -1) {
                    lines.push(commandResponse.slice(0, indexOfNewLineChar));
                    commandResponse = commandResponse.slice(indexOfNewLineChar + 1);
                    indexOfNewLineChar = commandResponse.indexOf("\n");
                }

                // Dispatch the found lines
                postMessage({
                    dispatch: currentCmd?.dispatch ?? 'defaultQueryDispatch',
                    data: lines
                });
            }

        });

        reader.readAsArrayBuffer(b);

    }

    function executeNextCommand() {

        // Check if all commands has been sent out from the buffer
        if (Array.isArray(commandBuffer) && commandBuffer.length !== 0) {

            // Command buffer not empty, execute next command from the buffer
            currentCmd = commandBuffer.shift();
            nextStep(timeout.GUARD_QUERY, sendCommand, currentCmd);

        } else {

            // No more commands left in the buffer, resume live query

            let commandResult;

            // Evaluate command execution result
            if (isCommandFailed)
                commandResult = CommandBufferStatus.COMMAND_ERROR;
            else
                commandResult = CommandBufferStatus.COMMAND_OK;

            postMessage({
                dispatch: 'commandResult',
                data: commandResult
            });

            // Restart live query
            initLiveQuery(deviceConfiguration);

        }
    }


    /* WEBSOCKET COMMUNICATION */

    function connectSocket(uri) {

        cleanUp();

        // Init websocket
        websocket = new WebSocket(uri, "raw-serial");
        websocket.onopen = function () {
            requestCommandMode(true)
        };
        websocket.onclose = function (evt) {
            onSocketClose(evt)
        };
        websocket.onmessage = function (evt) {
            purgeReceivedChars(evt)
        };
        websocket.onerror = function (evt) {
            onSocketError(evt)
        };

        // Communicate status change
        postMessage({
            status: ConnectionStatus.INITIALISING,
            message: ConnectionMessage.CONNECTING_TO_WS
        });

        postMessage({debug: `serialWorker: Connecting to websocket: ${wsUri}`});

        // Init connection timeout timer
        timeoutTimer = setTimeout(() => {
            // Communicate status change
            postMessage({
                status: ConnectionStatus.INITIALISING_FAILED,
                message: ConnectionMessage.CONNECTING_TO_WS_FAILED
            });
            postMessage({error: 'serialWorker: Websocket timed out'});
            cleanUp();
        }, timeout.CONNECT);
    }

    function onSocketError() {
        postMessage({
            status: ConnectionStatus.DISCONNECTED,
            message: ConnectionMessage.CONNECTION_BROKEN
        });
        postMessage({error: `serialWorker: Socket ERROR: ${wsUri}`});
        cleanUp();
    }

    function onSocketClose() {
        postMessage({
            status: ConnectionStatus.DISCONNECTED,
            message: ConnectionMessage.DEFAULT
        });
        postMessage({debug: 'serialWorker: Socket Close'});
        cleanUp();
    }


    /* UTILS */

    function purgeReceivedChars(evt) {
        var b = evt.data;
        var reader = new FileReader();

        reader.addEventListener("loadend", function () {

            var array = new Uint8Array(reader.result);
            var s = String.fromCharCode.apply(null, array);

            postMessage({debug: `serialWorker: Purge char(s) ${s}`});

        });

        reader.readAsArrayBuffer(b);

    }

    function cleanUp() {

        postMessage({debug: 'serialWorker: cleanUp'});

        if (websocket && websocket.readyState === websocket.OPEN) {
            websocket.send('\x04');
            websocket.close();
        }

        if (intervalTimer)
            clearInterval(intervalTimer);

        if (timeoutTimer)
            clearTimeout(timeoutTimer);

        websocket = null;
        intervalTimer = null;
        timeoutTimer = null;
        wakeUpResponse = "";
        commandModeResponse = "";
        dumpResponse = "";
        deviceConfiguration = {};
    }

    /*function hexdump(buffer, blockSize) {
        blockSize = blockSize || 16;
        var lines = [];
        var hex = "0123456789ABCDEF";
        for (var b = 0; b < buffer.length; b += blockSize) {
            var block = buffer.slice(b, Math.min(b + blockSize, buffer.length));
            var addr = ("0000" + b.toString(16)).slice(-4);
            var codes = block.split('').map(function (ch) {
                var code = ch.charCodeAt(0);
                return " " + hex[(0xF0 & code) >> 4] + hex[0x0F & code];
            }).join("");
            codes += "   ".repeat(blockSize - block.length);
            var chars = block.replace(/[\x00-\x1F\x20]/g, '.');
            chars +=  " ".repeat(blockSize - block.length);
            lines.push(addr + " " + codes + "  " + chars);
        }
        return lines.join("\n");
    }*/
}
