
/** Utils for Logbook component */

export const LOGBOOK_SEND_DIRECT = 'LOGBOOK_SEND_DIRECT';
export const LOGBOOK_SEND_VIA_VERIBOX = 'LOGBOOK_SEND_VIA_VERIBOX';
export const LOGBOOK_SEND_DIALOG_CANCEL = 'LOGBOOK_SEND_DIALOG_CANCEL';
export const LOG_EDIT_STATES = {
    NEW: 0,
    EDIT: 1,
    CLOSED: 2
};

export function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
