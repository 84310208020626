// General
import React from "react";
import PropTypes from "prop-types";
// Material-UI
import List from "@material-ui/core/List";
// Own Components
import DiagListItem from "./DiagListItem";
import OnSaveBackdrop from "../../../templates/OnSaveBackdrop";
// Redux
import {connect} from "react-redux";
import {CommandBufferStatus} from "../../../../redux/actions";


function DiagList({liveInputsArray, commandBufferStatus}) {
    return (
        <div>
            <OnSaveBackdrop message={"Változtatások mentése folyamatban..."} status={commandBufferStatus === CommandBufferStatus.COMMAND_REQUESTED}/>
            <List>
                {liveInputsArray.map(liveInput => (<DiagListItem liveInput={liveInput}/>))}
            </List>
        </div>
    )
}

// Property definitions
DiagList.propTypes = {
    liveInputsArray: PropTypes.array.isRequired,
    commandBufferStatus: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    liveInputsArray: state.liveData,
    commandBufferStatus: state.commandBuffer.commandBufferStatus
});

const DiagListContainer = connect(mapStateToProps, null)(DiagList);
export default DiagListContainer;
