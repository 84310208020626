import {Pages, SET_PAGE} from "../actions"

const pages = (state = Pages.DIAG, action) => {
    if (action.type === SET_PAGE) {
        return action.page;
        /*switch (action.page) {
            case Pages.DIAG:
                return Pages.DIAG;

            case Pages.LIVE_DATA:
                return Pages.LIVE_DATA;

            case Pages.LOGBOOK:
                return Pages.LOGBOOK;

            case Pages.HAVARIA_DATA:
                return Pages.HAVARIA_DATA;

            case Pages.SETTINGS:
                return Pages.SETTINGS;

            default:
                return state;
        }*/
    } else {
        /*default*/
        return state;
    }
};

export default pages
