import {
    RESET_HISTORICAL_DATA,
    SET_HISTORICAL_DATA,
    SET_HISTORICAL_DATA_STATUS,
    SET_HISTORICAL_DATA_TYPE
} from "../actions";
import processSecondsData, {resetSecondsDataVars} from "../utils/processSecondsData";
import processMinutesData from "../utils/processMinutesData";
import handleSumData from "../utils/labelingSumData";
import _ from "lodash";
import createSecondsData from "../utils/createSecondsData";

const REDUCER_DEFAULT_STATE = { headers: [], metrics: [], status: false, type: "sum" }

export let CURRENT_STATE = REDUCER_DEFAULT_STATE;

/** Filters out the non-value data rows */
function sanitizeData(data) {

    // Remove first row (EEPROM-DUMP)
    //data.splice(0, 1);

    data = data.filter(rowStr => rowStr.split(";")[3] === "0" || rowStr.split(";")[3] === "1");

    // Remove non-value related rows using the first row's length as the base
    // const firstRowLen = data[0].split(";").length;
    // data = data.filter(rowStr => rowStr.split(";").length === firstRowLen);

    // Format rows
    const formattedData = data.map(rowStr => {
        // Split row string into array
        const row = rowStr.split(";");

        // Remove [00b674:16:20]: VeriboxSerial from start of each row
        row.splice(0, 1)

        // Remove |00 from the end of each row
        row.splice(row.length - 1, 1);

        // Format date
        const date = row[0].split(".");
        row[0] = `${date[2]}.${date[1]}.${date[0]}.`;

        // Merge date with the timestamp
        row[0] = `${row[0]} ${row[1]}`;
        row.splice(1, 1);

        return(row.join(";"));
    })

    // Remove duplicates and return (Set type not allows duplicates)
    return(Array.from(new Set(formattedData)));
}

function convertStringDataIntoJson(data) {
    // Init historical data object
    const historicalData = {headers: ["Dátum"], metrics: []};

    // Create header
    const shiftAmount = 2;
    const headerIndexes = [0];
    let firstRow = data[0].split(";");

    firstRow = firstRow.splice(shiftAmount, firstRow.length - 1);
    firstRow.forEach((column, i) => {
        if (i % 4 === 0) {
            historicalData.headers.push(`${column}${firstRow[i + 2] ? ` (${firstRow[i + 2]})` : ""}`);
            headerIndexes.push(shiftAmount + i + 1);
        }
    });

    data.forEach(rowStr => {
        // Split row string into array
        const row = rowStr.split(";");

        const obj = {};
        headerIndexes.forEach((headerIndex, i) => {
            obj[historicalData.headers[i]] = row[headerIndex];
        })

        // Push created object into metrics
        historicalData.metrics.push(obj);
    })

    // Return historical data object
    return(historicalData);
}

const historicalData = (state = REDUCER_DEFAULT_STATE, action) => {

    if (action.type === RESET_HISTORICAL_DATA) {
        // Reset all variables
        CURRENT_STATE = REDUCER_DEFAULT_STATE;

        resetSecondsDataVars();

        return(REDUCER_DEFAULT_STATE);
    }

    if (action.type === SET_HISTORICAL_DATA_STATUS) {
        if (action.status === false && state.type === "sum") {
            const sumMetrics = createSecondsData(state.metrics);
            return({...state, status: action.status, metrics: sumMetrics})
        }
        return({...state, status: action.status});
    }

    if (action.type === SET_HISTORICAL_DATA_TYPE) {
        return {...state, type: action.historicalDataType}
    }

    if (action.type === SET_HISTORICAL_DATA) {

        const rawData = action.historicalData;
        const sanitizedData = sanitizeData(rawData);

        if (sanitizedData.length === 0) {
            return(state);
        }

        const newState = convertStringDataIntoJson(sanitizedData);

        switch (state.type) {
            case "min":
                const minutesData = processMinutesData(newState.metrics);
                newState.metrics = [...state.metrics, ...minutesData];
                CURRENT_STATE = {...newState, status: state.status, type: state.type};
                break;
            case "raw":
                newState.metrics = [...state.metrics, ...newState.metrics];
                CURRENT_STATE = {...newState, status: state.status, type: state.type};
                break;
            case "sum":

                // Run the data filling process
                const sumData = handleSumData(newState.metrics)//processSecondsData(newState.metrics);

                if (sumData !== null) {
                    let finalMetrics = [...state.metrics, ...sumData]//_.sortBy([...state.metrics, ...sumData], ["Dátum"]);
                    finalMetrics = _.uniqBy(finalMetrics, "Dátum");
                    CURRENT_STATE = {...newState, metrics: finalMetrics, status: state.status, type: state.type};
                } else {
                    // The process is not finished yet
                    CURRENT_STATE = {...state};
                }

                break;
            default:
                return(state);

        }

        return(CURRENT_STATE);

    } else {
        /*default*/
        return state;
    }
};

export default historicalData
