import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import {useGlobalStyles} from "../App";
import {appVersion} from "../../index";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {appVersion}
            {' Copyright © '}
            <Link color="inherit" href="https://senso-net.com/">
                Senso-Net Kommunikációs kft.
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function PageFrame(props) {

    const classes = useGlobalStyles();

    return (
        <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                {props.children}
                <Box pt={4}>
                    <Copyright />
                </Box>
            </Container>
        </main>
    );
}

PageFrame.propTypes = {
    children: PropTypes.node,
};
