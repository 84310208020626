import {SET_DEVICE_CONFIGURATION} from "../actions"

const deviceConfiguration = (state = {}, action) => {
    if (action.type === SET_DEVICE_CONFIGURATION) {
        return action.configurationData
    } else {
        /*default*/
        return state;
    }
};

export default deviceConfiguration