// General
import React from 'react';
import PropTypes from 'prop-types';
// Material-UI
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import SwipeableViews from "react-swipeable-views";
import Fab from "@material-ui/core/Fab";
import AddIcon from '@material-ui/icons/Add';
// Redux
import {connect} from "react-redux";
import {CommandBufferStatus, ConnectionStatus, LogbookCategories, LogbookTemplates} from "../../../redux/actions";
// Own Components
import LogDialog from "./LogDialog/LogDialog";
import LogBookList from "./LogBookList/LogBookList";
import TabPanel from "../../templates/TabPanel";
// Styles
import {useTheme} from '@material-ui/core/styles';
import useLogbookStyles from "./LogBook.styles";
// Utils
import {a11yProps, LOG_EDIT_STATES} from "./LogBook.utils";
import OnSaveBackdrop from "../../templates/OnSaveBackdrop";


function Logbook({logList, connectionStatus, commandBufferStatus}) {

    // Styles
    const classes = useLogbookStyles();
    const theme = useTheme();

    // Get username from localstorage
    const username = localStorage.getItem("username");

    // Handle tab changes
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => setValue(newValue);
    const handleChangeIndex = index => setValue(index);

    // Initial log category lists
    const [log, setLog] = React.useState({});
    const [szerelesLista, setSzerelesLista] = React.useState([]);
    const [meresLista, setMeresLista] = React.useState([]);
    const [ellenorzesLista, setEllenorzesLista] = React.useState([]);
    const [megfigyelesLista, setMegfigyelesLista] = React.useState([]);
    const [karbantartasLista, setKarbantartasLista] = React.useState([]);

    // Filter logs by categories
    React.useEffect(() => {

        setSzerelesLista(logList.filter(item => item.category === LogbookCategories[0].displayName));

        setMeresLista(logList.filter(item => item.category === LogbookCategories[1].displayName));

        setEllenorzesLista(logList.filter(item => item.category === LogbookCategories[2].displayName));

        setMegfigyelesLista(logList.filter(item => item.category === LogbookCategories[3].displayName));

        setKarbantartasLista(logList.filter(item => item.category === LogbookCategories[4].displayName));

    }, [logList]);

    // Handle log dialog state
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogState, setDialogState] = React.useState(false);
    const handleLogDialogOpen = (state, log) => {
        setLog(log);
        setDialogState(state);
        setDialogOpen(!dialogOpen);
    };
    const handleLogDialogClose = () => {
        setDialogOpen(!dialogOpen);
    };

    return (
        <div style={{flexGrow: 1, width: '100%'}}>
            <Box display={"flex"} p={1} flexDirection="column">
                <Box p={1}>
                    <AppBar position="static" color="default">
                        <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary"
                              variant="scrollable" scrollButtons="auto" centered={true}>
                            <Tab label="Mind" {...a11yProps(0)} />
                            <Tab label={LogbookCategories[0].displayName} {...a11yProps(1)} />
                            <Tab label={LogbookCategories[1].displayName} {...a11yProps(2)} />
                            <Tab label={LogbookCategories[2].displayName} {...a11yProps(3)} />
                            <Tab label={LogbookCategories[3].displayName} {...a11yProps(4)} />
                            <Tab label={LogbookCategories[4].displayName} {...a11yProps(5)} />
                        </Tabs>
                    </AppBar>
                </Box>
                <Box p={1}>
                    <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
                        <TabPanel value={value} index={0} dir={theme.direction}>
                            <LogBookList logList={logList} openDialog={handleLogDialogOpen}
                                         connectionStatus={connectionStatus} commandBufferResult={commandBufferStatus}/>
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                            <LogBookList logList={szerelesLista} openDialog={handleLogDialogOpen}
                                         connectionStatus={connectionStatus} commandBufferResult={commandBufferStatus}/>
                        </TabPanel>
                        <TabPanel value={value} index={2} dir={theme.direction}>
                            <LogBookList logList={meresLista} openDialog={handleLogDialogOpen}
                                         connectionStatus={connectionStatus} commandBufferResult={commandBufferStatus}/>
                        </TabPanel>
                        <TabPanel value={value} index={3} dir={theme.direction}>
                            <LogBookList logList={ellenorzesLista} openDialog={handleLogDialogOpen}
                                         connectionStatus={connectionStatus} commandBufferResult={commandBufferStatus}/>
                        </TabPanel>
                        <TabPanel value={value} index={4} dir={theme.direction}>
                            <LogBookList logList={megfigyelesLista} openDialog={handleLogDialogOpen}
                                         connectionStatus={connectionStatus} commandBufferResult={commandBufferStatus}/>
                        </TabPanel>
                        <TabPanel value={value} index={5} dir={theme.direction}>
                            <LogBookList logList={karbantartasLista} openDialog={handleLogDialogOpen}
                                         connectionStatus={connectionStatus} commandBufferResult={commandBufferStatus}/>
                        </TabPanel>
                    </SwipeableViews>
                </Box>
            </Box>
            <div className={classes.fabGroup}>
                <Fab variant="extended" color="primary" className={classes.newLogFab}
                     disabled={connectionStatus !== ConnectionStatus.CONNECTED}
                     onClick={() => handleLogDialogOpen(LOG_EDIT_STATES.NEW, {
                         name: username,
                         category: LogbookCategories[0].displayName,
                         startDate: "",
                         endDate: "",
                         title: "",
                         template: {
                             tempName: LogbookTemplates[LogbookCategories[0].codeName][0].tempName,
                             text: LogbookTemplates[LogbookCategories[0].codeName][0].text,
                             number: 0
                         },
                         desc: ""
                     })}>
                    <AddIcon className={classes.extendedIcon}/>
                    Új Napló
                </Fab>
                {/*<Fab variant="extended" color="primary" onClick={() => publishLogbook( 'keult', 'veriboxtest', 'szuper')}>
                    <CloudUploadIcon className={classes.extendedIcon}/>
                    MQTT Felküldés
                </Fab>*/}
            </div>
            {dialogOpen && <LogDialog open={dialogOpen} state={dialogState} close={handleLogDialogClose} log={log}/>}
            <OnSaveBackdrop message={"Változtatások mentése folyamatban..."} status={commandBufferStatus === CommandBufferStatus.COMMAND_REQUESTED}/>
        </div>
    );
}

// Property definitions
Logbook.propTypes = {
    logList: PropTypes.array.isRequired,
    connectionStatus: PropTypes.string,
    commandBufferStatus: PropTypes.string
};

const mapStateToProps = state => {
    return ({
        logList: state.logList,
        connectionStatus: state.connection.connectionStatus.status,
        commandBufferStatus: state.commandBuffer.commandBufferStatus
    })
};

const LogBookContainer = connect(mapStateToProps, null)(Logbook);
export default LogBookContainer;
