import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer - 1,
        color: '#fff',
    },
    progressbar: {
        marginTop: theme.spacing(2)
    }
}));

export default function OnSaveBackdrop({status, message}) {

    const classes = useStyles();

    return (
        <Backdrop className={classes.backdrop} open={status}>
            <Grid container
                  direction="column"
                  justify="center"
                  alignItems="center">
                <Typography color="inherit" variant={"h5"}>{ message }</Typography>
                <CircularProgress color="inherit" className={classes.progressbar}/>
            </Grid>
        </Backdrop>
    )
}

OnSaveBackdrop.propTypes = {
    status: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired
};