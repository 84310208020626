import {
    ConnectionMessage,
    ConnectionStatus,
    SET_CONNECTION_STATUS,
    SET_CONNECTION_PARAMS,
    DefaultConnectionParams, ConnectionMessageSeverity
} from "../actions"

const connection = (state = {
    connectionStatus: {
        status: ConnectionStatus.DISCONNECTED,
        message: ConnectionMessage.DEFAULT,
        messageSeverity: ConnectionMessageSeverity.LOW
    },
    connectionParams: DefaultConnectionParams
}, action) => {

    switch (action.type) {
        case SET_CONNECTION_STATUS:
            return {
                connectionParams: state.connectionParams,
                connectionStatus: {
                    status: action.status,
                    message: action.message,
                    messageSeverity: ConnectionMessageSeverity.LOW/*action.messageSeverity*/
                }
            };

        case SET_CONNECTION_PARAMS:
            return {
                connectionStatus: state.connectionStatus,
                connectionParams: action.paramsObject
            };

        default:
            return state
    }
};

export default connection