import React from "react";
import PropTypes from "prop-types"
import Grid from "@material-ui/core/Grid";

export default function LayoutItem(props) {

    let alignment = "center";
    let container = false;
    let lg = false;
    let md = false;
    let sm = false;

    if (props.alignment != null) {
        alignment = props.alignment;
    }

    if (props.container != null) {
        container = props.container;
    }

    if (props.lg != null) {
        lg = props.lg;
    }

    if (props.md != null) {
        md = props.md;
    }

    if (props.sm != null) {
        sm = props.sm;
    }

    return (
        <Grid item container={ container } className={props.className} align-self={ alignment } lg={ lg } md={ md } sm={ sm }>
            { props.children }
        </Grid>
    )

}

LayoutItem.propTypes = {
    className: PropTypes.object,
    alignment: PropTypes.string,
    container: PropTypes.bool,
    lg: PropTypes.number,
    md: PropTypes.number,
    sm: PropTypes.number,
};