import React from "react";
import Slide from "@material-ui/core/Slide";
import {LogbookCategories} from "../../../../redux/actions";

/** Utils for LogDialog component */

export const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function refactorDateFormat(date, format) {

    let year;
    let month;
    let day;
    let hours;
    let minutes;

    switch (format) {
        case "saveFormat":
            date = new Date(date);
            year = date.getFullYear();
            month = date.getMonth() + 1;
            day = date.getDate();
            hours = date.getHours();
            minutes = date.getMinutes();

            if (month < 10) month = "0" + month;
            if (day < 10) day = "0" + day;
            if (hours < 10) hours = "0" + hours;
            if (minutes < 10) minutes = "0" + minutes;

            return year + '.' + month + '.' + day + ". " + hours + ":" + minutes;

        case "datePickerFormat":
            year = date.substring(0, 4);
            month = date.substring(5, 7);
            day = date.substring(8, 10);
            hours = date.substring(12, 14);
            minutes = date.substring(15, 17);

            return year + '-' + month + '-' + day + "T" + hours + ":" + minutes;

        case "convertToPickerFormat":
            year = date.getFullYear();
            month = date.getMonth() + 1;
            day = date.getDate();
            hours = date.getHours();
            minutes = date.getMinutes();

            if (month < 10) month = "0" + month;
            if (day < 10) day = "0" + day;
            if (hours < 10) hours = "0" + hours;
            if (minutes < 10) minutes = "0" + minutes;

            return year + '-' + month + '-' + day + "T" + hours + ":" + minutes;
        default:
            return '';
    }
}

export const getCategoryCodeName = (category) => {
    switch (category) {
        case LogbookCategories[0].displayName:
            return LogbookCategories[0].codeName;

        case LogbookCategories[1].displayName:
            return LogbookCategories[1].codeName;

        case LogbookCategories[2].displayName:
            return LogbookCategories[2].codeName;

        case LogbookCategories[3].displayName:
            return LogbookCategories[3].codeName;

        case LogbookCategories[4].displayName:
            return LogbookCategories[4].codeName;
        default:
            return '';
    }
};
