import _ from "lodash";
import {CURRENT_STATE} from "../reducers/historicalData";

const TARGET_SECONDS_DATA_COUNT = 240; // 4 minutes
const TIME_JUMP_BACK = 180; // 3 minutes

let UNPROCESSED_METRICS_BUFFER = [];
let PROCESSED_METRICS_BUFFER = [];
let SECONDS_METRICS_BUFFER = [];

let CURRENT_GYZ_VALUES = {}
let HAVARIA_METRIC = undefined;
let POSSIBLE_HAVARIA_METRIC = undefined;

let IS_BUFFERING_SECONDS_DATA = false;

/**
 * Searches for a bigger date in the metrics, which indicates the end of seconds data.
 * It returns either the index of the first metric that does not satisfy the criteria for a seconds data, or -1 meaning no end was found.
 *
 * @param {{"Dátum": string, "Pbe (mbar)": number, "Pki (mbar)": number, "Gyz1": string, "Gyz2": string}[]} metrics
 * @return {-1 | number}
 * */
export function detectEndOfSecondsData(metrics) {
    const havariaMetricDate = new Date(HAVARIA_METRIC["Dátum"]);
    const maxEndTime = havariaMetricDate.getTime() - (TIME_JUMP_BACK * 1000) + (TARGET_SECONDS_DATA_COUNT * 1000);

    for (let i = 0; i < metrics.length; i++) {
        const currentMetric = metrics[i];
        const currentMetricTime = new Date(currentMetric["Dátum"]).getTime();
        const gyz1Change = currentMetric["Gyz1"] !== CURRENT_GYZ_VALUES["Gyz1"];
        const gyz2Change = currentMetric["Gyz2"] !== CURRENT_GYZ_VALUES["Gyz2"];

        if (currentMetricTime < havariaMetricDate.getTime()) {
            continue;
        }

        if (currentMetricTime > maxEndTime || gyz1Change || gyz2Change) {
            return(i)
        }
    }

    return(-1);
}

/**
 * This function tries to detect a havaria event.
 * This could happen in two cases:
 *    1. If a metric timestamp is lower than the previous.
 *    2. If there is no jump back in time, then checks if the digital values (Gyz values) has changed.
 *
 * It has 5 possible return cases:  <br/>
 *   1: No change detection needed as the process is currently in buffering.  <br/>
 *   2: A jump back in time has happened. It indicates the start of havaria data. <br/>
 *   3: No jump back, but digital value change has happened. It indicates that there will be no havaria data afterwards.  <br/>
 *   4: A digital value change has happened at the end of the series.
 *      Further iteration with more data is needed to determine if it is a case 2 or 3. <br/>
 *   5: Nor time jump back nor digital value change has been detected.
 *
 * @param {{"Dátum": string, "Pbe (mbar)": number, "Pki (mbar)": number, "Gyz1": string, "Gyz2": string}[]} metrics
 * @return {{case: 1 | 2 | 3 | 4 | 5, index: number | -1}}
 * */
export function detectHavariaEvent(metrics) {
    // Case 1: Buffering is on
    if (IS_BUFFERING_SECONDS_DATA) return ({case: 1, index: -1});

    let digitalValueChangedIndex = -1;
    let isTimeJumpBack = false;
    for (let i = 0; i < metrics.length; i++) {
        const currentMetric = metrics[i];
        const nextMetric = metrics[i + 1];

        // Case 3: Check if the current metric's digital values are different from the previous one
        if (digitalValueChangedIndex === -1) {
            const gyz1Change = currentMetric["Gyz1"] !== CURRENT_GYZ_VALUES["Gyz1"];
            const gyz2Change = currentMetric["Gyz2"] !== CURRENT_GYZ_VALUES["Gyz2"];
            if (gyz1Change || gyz2Change) {
                // Save the current index
                digitalValueChangedIndex = i;
                console.info(`Current Gyz1: ${CURRENT_GYZ_VALUES["Gyz1"]}, Current Gyz2: ${CURRENT_GYZ_VALUES["Gyz2"]}`)
                console.info(`Metric Gyz1: ${currentMetric["Gyz1"]}, Metric Gyz2: ${currentMetric["Gyz2"]}`)
                console.info("Digital value change: ", currentMetric);
            }
        }

        // Return Case 4 or break the loop
        if (nextMetric === undefined) {
            if (digitalValueChangedIndex !== -1) {
                return({case: 4, index: digitalValueChangedIndex});
            } else {
                break;
            }
        }

        // Case 2: Check if the next metric's timestamp jumps back in time
        if (new Date(nextMetric["Dátum"]).getTime() < new Date(currentMetric["Dátum"]).getTime()) {
            // Set digital values
            CURRENT_GYZ_VALUES["Gyz1"] = currentMetric["Gyz1"];
            CURRENT_GYZ_VALUES["Gyz2"] = currentMetric["Gyz2"];
            isTimeJumpBack = true;
        }

        // Return Case 2 and 3
        if (isTimeJumpBack) {
            return({case: 2, index: i})
        }
        else if (digitalValueChangedIndex !== -1) {
            // Set digital values
            CURRENT_GYZ_VALUES["Gyz1"] = currentMetric["Gyz1"];
            CURRENT_GYZ_VALUES["Gyz2"] = currentMetric["Gyz2"];
            return({case: 3, index: digitalValueChangedIndex})
        }
    }

    // Case 5: Nor change nor time jump back has been detected
    return ({case: 5, index: -1});
}

export default (metricsChunk) => {

    console.info("New chunk arrived: ", metricsChunk);

    /* Phase 1: Data preparation */

    // Filter out the duplicates
    metricsChunk = _.uniqBy(metricsChunk, 'Dátum');

    // Map all analog values into floats and all digital values into integers
    // Sometimes the values are in string format with "," as the decimal separator character
    // This could lead to a lack of consistency in data presentation
    metricsChunk = metricsChunk.map((metric) => {
        if (typeof metric["Pbe (mbar)"] !== "string") return metric;

        const tmpMetric = {...metric};

        tmpMetric["Pbe (mbar)"] = tmpMetric["Pbe (mbar)"].replace(",", ".");
        tmpMetric["Pki (mbar)"] = tmpMetric["Pki (mbar)"].replace(",", ".");

        tmpMetric["Pbe (mbar)"] = parseFloat(tmpMetric["Pbe (mbar)"]);
        tmpMetric["Pki (mbar)"] = parseFloat(tmpMetric["Pki (mbar)"]);

        tmpMetric["Gyz1"] = parseInt(tmpMetric["Gyz1"]);
        tmpMetric["Gyz2"] = parseInt(tmpMetric["Gyz2"]);

        return tmpMetric;
    })

    // Initialize the Gyz values if needed
    if (CURRENT_STATE.metrics.length === 0 && metricsChunk.length > 0) {
        CURRENT_GYZ_VALUES["Gyz1"] = metricsChunk[0]["Gyz1"];
        CURRENT_GYZ_VALUES["Gyz2"]= metricsChunk[0]["Gyz2"];
    }

    // Map all metrics as minutes data
    metricsChunk = metricsChunk.map(m => ({...m, type: "min"}));

    console.info("New chunk prepared: ", metricsChunk);

    /* PHASE 2: Data extension with the possibly havaria metric */

    let metrics = [...UNPROCESSED_METRICS_BUFFER, ...metricsChunk];

    if (POSSIBLE_HAVARIA_METRIC) {
        metrics = [POSSIBLE_HAVARIA_METRIC, ...metrics];
    }

    POSSIBLE_HAVARIA_METRIC = undefined;
    UNPROCESSED_METRICS_BUFFER = [];

    console.info("Extended metrics: ", metrics);

    /* PHASE 3: Havaria detection */

    const havariaDetectionResult = detectHavariaEvent(metrics);

    // Init variables
    let possibleSecondsData = [];

    // Check if there was no havaria event
    if (havariaDetectionResult.case === 5) {
        console.info("Case 5: No havaria event...")
        // Return all metrics, because there is no need to continue the process
        return metrics;
    }
    // Check if further iteration is needed
    else if (havariaDetectionResult.case === 4) {
        console.info("Case 4: Further iteration is needed...")
        // Save the index of the metric that was detected as changed
        // It is possible that the upcoming metrics will be havaria metrics
        POSSIBLE_HAVARIA_METRIC = metrics.splice(havariaDetectionResult.index)?.[0];
        // Map all metrics as minutes data
        metrics = metrics.map(m => ({...m, type: "min"}));
        // The havaria metric was deleted from metrics array with splice
        // Now these metrics can be returned to save as they are now
        return metrics;
    }
    // Check if either digital value change or time jump back happened
    else if (havariaDetectionResult.case === 3 || havariaDetectionResult.case === 2) {
        console.info(`Case ${havariaDetectionResult.case}: Either digital value change or time jump back happened...`)
        // Separate the metrics before the change/havaria, and save them for later
        // They are not needed for the interpolation, so they will be given back as they are now
        PROCESSED_METRICS_BUFFER = metrics.slice(0, havariaDetectionResult.index);

        // Mark the items in PROCESSED_METRICS_BUFFER as minutes data
        PROCESSED_METRICS_BUFFER = PROCESSED_METRICS_BUFFER.map(m => ({...m, type: "min"}));

        // Save the metrics after the change/havaria as unprocessed, because they won't contain seconds data
        possibleSecondsData = metrics.slice(havariaDetectionResult.index);

        // Save the changed metric for later and remove it from the array
        // Why shift method?
        // The changed metric object will be the first element in the UNPROCESSED_METRICS_BUFFER array,
        // because the metrics array was sliced with its index.
        // It means that it will be the first item, because slice method includes the start index.
        HAVARIA_METRIC = possibleSecondsData.shift();
        // Mark this item as havaria with a flag
        HAVARIA_METRIC = {...HAVARIA_METRIC, type: "havaria"};
        console.info("New havaria metric: ", HAVARIA_METRIC);
    }
    // Check if seconds data buffering is currently on
    else if (havariaDetectionResult.case === 1) {
        console.info(`Case 1: Seconds data buffering is currently on...`)
        // Save the whole metrics array, because they will contain seconds data
        possibleSecondsData = [...metrics];
    }
    else {
        return null;
    }

    /* PHASE 2: End of buffering detection */

    console.info("Possible seconds data: ", possibleSecondsData);

    // Detect end of seconds data
    const secondsDataEndIndex = detectEndOfSecondsData(possibleSecondsData);

    console.info("End detection result: ", possibleSecondsData[secondsDataEndIndex]);

    // All metrics are seconds data, so the buffering should continue/start
    if (secondsDataEndIndex === -1) {
        console.info("No end index found, buffering is on...")

        // Set buffer flag
        IS_BUFFERING_SECONDS_DATA = true;

        // Save all metrics in the buffer
        SECONDS_METRICS_BUFFER = [...SECONDS_METRICS_BUFFER, ...possibleSecondsData];

        // Return null to indicate continue of the process
        return null;
    }

    /* PHASE 3: End of buffering was detected */

    // Cut possibleSecondsData where the last seconds data was detected
    const extractedSecondsData = possibleSecondsData.slice(0, secondsDataEndIndex);

    // Extend the buffer with the latest seconds data
    SECONDS_METRICS_BUFFER = [...SECONDS_METRICS_BUFFER, ...extractedSecondsData];

    // Save all those metrics in UNPROCESSED_METRICS_BUFFER, which does not count as seconds data
    UNPROCESSED_METRICS_BUFFER = possibleSecondsData.slice(secondsDataEndIndex);

    // Mark all items of UNPROCESSED_METRICS_BUFFER as min data
    UNPROCESSED_METRICS_BUFFER = UNPROCESSED_METRICS_BUFFER.map(m => ({...m, type: "min"}));

    /* PHASE 4: Fill the missing records in the seconds data with generated metrics */

    console.info("START OF DATA CREATION WITH SECONDS_METRICS_BUFFER: ", SECONDS_METRICS_BUFFER);

    // Mark the items in SECONDS_METRICS_BUFFER as seconds data
    SECONDS_METRICS_BUFFER = SECONDS_METRICS_BUFFER.map(m => ({...m, type: "sec"}));

    const createdSecondsData = [HAVARIA_METRIC, ...SECONDS_METRICS_BUFFER];

    console.info("END OF DATA CREATION: ", createdSecondsData);

    /* PHASE 5: Finalizing */

    // Reset all global variables
    HAVARIA_METRIC = undefined;
    POSSIBLE_HAVARIA_METRIC = undefined;
    IS_BUFFERING_SECONDS_DATA = false;
    SECONDS_METRICS_BUFFER = []

    // Merge the created metrics with the metrics before the change
    let finalData = [...PROCESSED_METRICS_BUFFER, ...createdSecondsData];

    // Remove digital values from all items except the havaria
    finalData = finalData.map((metric) => {
        if (metric.type === "min" || metric.type === "havaria") return(metric);
        else return({...metric, "Gyz1": "", "Gyz2": ""});
    });

    console.info("Ready data is returned");
    console.info("---------------------------\n\n");
    return (finalData);

}