// General
import React from "react";
import PropTypes from "prop-types";
// Material-UI
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import {
    Checkbox,
    Container, FormControl,
    IconButton, InputLabel,
    LinearProgress,
    MenuItem,
    Paper,
    Select,
    Tooltip,
    Typography
} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import TableContainer from "@material-ui/core/TableContainer";
import Button from "@material-ui/core/Button";
import ExportIcon from '@material-ui/icons/GetAppRounded';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
// Utils
import {exportToCSV} from "./HistoricalData.utils";
// Redux
import {connect} from "react-redux";
import {
    CommandBufferStatus,
    ConnectionMessage,
    ConnectionMessageSeverity,
    ConnectionStatus, resetHistoricalData, setCommandBuffer,
    setConnectionStatus, setHistoricalData, setHistoricalDataStatus, setHistoricalDataType
} from "../../../redux/actions";
import {store} from "../../../index";
// Assets
import historical_data from "../../../assets/historicalData.png";
import theme from "../../../styles/theme";


function HistoricalData({historicalData, commandBufferStatus, connectionStatus}) {

    // Setup pagination
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(60);
    const rows = historicalData?.metrics;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows?.length - page * rowsPerPage);

    // Handle pagination
    const handleChangePage = (event, newPage) => setPage(newPage);
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Handle type change
    const handleTypeChange = (event) => {
        store.dispatch(setHistoricalDataType(event.target.value));
    }

    // Handle query
    const handleQuery = () => {
        //store.dispatch(setHistoricalDataType(onlySecondsData ? "sec" : "min"));

        store.dispatch(setHistoricalDataStatus(true));

        store.dispatch(setConnectionStatus({
            status: ConnectionStatus.PAUSED,
            message: ConnectionMessage.PAUSED,
            ConnectionMessageSeverity: ConnectionMessageSeverity.LOW
        }));

        store.dispatch(setCommandBuffer({
            commandBuffer: [{paramName: "historicalData", paramIndex: 0, paramValue: 2}],
            commandBufferStatus: CommandBufferStatus.COMMAND_REQUESTED
        }));
    }

    // Handle stop
    const handleStop = () => {
        store.dispatch(setHistoricalDataStatus(false));

        store.dispatch(setConnectionStatus({
            status: ConnectionStatus.DISCONNECT_REQUESTED,
            message: ConnectionMessage.DISCONNECTING,
            ConnectionMessageSeverity: ConnectionMessageSeverity.LOW
        }));
    }

    // Handle reset data
    const handleReset = () => {
        store.dispatch(resetHistoricalData());
    }

    // Handle export
    const handleExport = () => {
        if (historicalData.type === "sum") {
            exportToCSV(historicalData, ["type"])
        } else {
            exportToCSV(historicalData)
        }
    }

    if ((connectionStatus === ConnectionStatus.DISCONNECTED || !historicalData.status) && rows?.length > 0) {
        return (
            <Paper>
                <TableContainer component={Paper}>
                    <div style={{marginLeft: -8, paddingTop: 8, paddingBottom: 8, display: "flex"}}>
                        <TablePagination
                            rowsPerPageOptions={[
                                {label: '60', value: 60},
                                {label: '120', value: 120},
                                {label: '180', value: 180}
                            ]}
                            colSpan={12}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            style={{borderBottom: "none", padding: 0}}
                            labelRowsPerPage={"Sorok oldalanként:"}
                        />
                        <div style={{flexGrow: 1}}/>
                        <Tooltip title={"CSV exportálás"} placement={"top"}>
                            <IconButton style={{marginRight: 8}} onClick={handleExport}>
                                <ExportIcon color={"primary"}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"Adatok visszaallítása"} placement={"top"}>
                            <IconButton style={{marginRight: 8}} onClick={() => handleReset()}>
                                <DeleteIcon style={{color: theme.palette.error.main}}/>
                            </IconButton>
                        </Tooltip>
                    </div>
                    <Table style={{minWidth: 650}} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {[...historicalData?.headers].map(header => {
                                    return(<TableCell>{header}</TableCell>)
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : rows
                            ).map((row) => (
                                <TableRow hover>
                                    {[...historicalData?.headers]?.map(header => {
                                        return(<TableCell>{row[header]}</TableCell>)
                                    })}
                                </TableRow>
                            ))}

                            {emptyRows > 0 && (
                                <TableRow style={{height: 53 * emptyRows}}>
                                    <TableCell colSpan={6}/>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        )
    } else {
        return (
            historicalData.status ?
                <div style={{
                    marginTop: 32,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <img alt={"historicalData"} src={historical_data} width={600}/>
                    <div style={{height: 32}}/>
                    <Typography variant={"h5"}>
                        Hisztorikus adatok lekérdezése folyamatban...
                    </Typography>
                    <div style={{height: 16}}/>
                    <Typography variant={"h5"}>
                        {`Beolvasott sorok száma: ${rows?.length}`}
                    </Typography>
                    <div style={{height: 32}}/>
                    <LinearProgress color={"primary"} style={{width: 450, borderRadius: 50}}/>
                    <div style={{height: 32}}/>
                    <Button style={{color: "white", backgroundColor: theme.palette.error.main}} variant="contained"
                            size="large" onClick={handleStop}>
                        Lekérdezés leállítása
                    </Button>
                </div>
                :
                <div style={{
                    marginTop: 32,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <img alt={"historicalData"} src={historical_data} width={600}/>
                    <div style={{height: 32}}/>
                    <FormControl variant={"outlined"} style={{width: 350, margin: 16}}>
                        <InputLabel>Lekérdezési típus</InputLabel>
                        <Select
                            value={historicalData.type}
                            onChange={handleTypeChange}
                            label="Lekérdezési típus"
                        >
                            <MenuItem value={"min"}>Perces adatok</MenuItem>
                            <MenuItem value={"raw"}>Nyers adatok</MenuItem>
                            <MenuItem value={"sum"}>Összesített havária és nyers adatok</MenuItem>
                        </Select>
                    </FormControl>
                    <div style={{height: 8}}/>
                    <Button color="primary" variant="contained" size="large"
                            onClick={commandBufferStatus === CommandBufferStatus.COMMAND_REQUESTED ? (e) => e.preventDefault : handleQuery}
                            disabled={commandBufferStatus === CommandBufferStatus.COMMAND_REQUESTED || connectionStatus !== ConnectionStatus.CONNECTED}>
                        Hisztorikus adatok lekérdezése
                    </Button>
                </div>
        )
    }
}

// Property definitions
HistoricalData.propTypes = {
    historicalData: PropTypes.array,
    commandBufferStatus: PropTypes.string,
    connectionStatus: PropTypes.string
};

const mapStateToProps = state => ({
    historicalData: state.historicalData,
    commandBufferStatus: state.commandBuffer.commandBufferStatus,
    connectionStatus: state.connection.connectionStatus.status
});

const HistoricalDataContainer = connect(mapStateToProps, null)(HistoricalData);

export default HistoricalDataContainer
