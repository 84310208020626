import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {makeStyles} from "@material-ui/core/styles";
import PageSelector from "./PageSelector";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import Login from "./pages/login/Login";
import Signup from "./pages/login/Signup";
import theme from "../styles/theme";

const drawerWidth = 240;

export const useGlobalStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 400,
    },
    pageContainerDiv: {
        display: "flex",
        marginRight: 0,
        paddingRight: 0
    },
    toolbarDivider: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    backdrop: {
        zIndex: theme.zIndex.drawer - 1,
        color: '#fff',
    }
}));

export default function App() {

    const isLoggedIn = localStorage.getItem("isLoggedIn");

    return (
        <BrowserRouter>
            <CssBaseline/>
            {isLoggedIn === "true" ? <Redirect to="/app/diagnosztika"/> : <Redirect to="/bejelentkezes"/>}
            <Switch>
                <Route path={"/bejelentkezes"} component={Login}/>
                <Route path={"/regisztracio"} component={Signup}/>
                <Route path={"/app"} component={PageSelector}/>
            </Switch>

        </BrowserRouter>
    );
}
