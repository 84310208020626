// General
import React from "react";
import PropTypes from "prop-types";
// Material-UI
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
// Own Component
import DiagChart from "../DiagChart/DiagChart";
import DiagCard from "../DiagCard/DiagCard";
// Styles
import clsx from "clsx";
import {useGlobalStyles} from "../../../App";


export default function DiagListItem({liveInput}) {

    // Styles
    const classes = useGlobalStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    // Map through metrics and parse all value to integer
    liveInput.metrics = liveInput.metrics.map(metric => {
       let newValue = parseInt(metric.value).toFixed(0);
       return {...metric, value: newValue}
    });

    return(
        <Grid container spacing={3}>
            {/* DiagChart */}
            <Grid item xs={12} md={8} lg={9}>
                <Paper className={fixedHeightPaper} elevation={2}>
                    <DiagChart liveInput={liveInput}/>
                </Paper>
            </Grid>
            {/* DiagCard */}
            <Grid item xs={12} md={4} lg={3}>
                <Paper className={fixedHeightPaper} elevation={2}>
                    <DiagCard liveInput={liveInput}/>
                </Paper>
            </Grid>
        </Grid>
    )
}

// Property definitions
DiagListItem.propTypes = {
    liveInput: PropTypes.object.isRequired
};
