
/** Utils for DiagChart component */

export function calculateTrending(liveInput) {
    let onePercent = liveInput.metrics[8].value / 100;
    let difference = liveInput.metrics[9].value - liveInput.metrics[8].value;

    let trendingPercent = (difference / onePercent).toFixed(1);
    return(isFinite(parseFloat(trendingPercent)) ? trendingPercent : 0.0);
}
