import {ExportToCsv} from "export-to-csv";

export function exportToCSV(historicalData, exclude = []) {

    const metricsToExport = historicalData.metrics.map((metric) => {
        const modifiedMetric = {...metric};

        exclude.forEach(key => {
            delete modifiedMetric[key]
        });

        // Filter out keys that should be excluded
        /*Object.keys(metric).forEach((key) => {
            if (!exclude.includes(key)) {
                modifiedMetric[key] = metric[key];
            }
        })*/

        return modifiedMetric;
    })

    console.log(metricsToExport);

    // Setup csv options
    const options = {
        filename: "veribox-hisztorikus-adatok",
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalSeparator: ',',
        showLabels: true,
        showTitle: false,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true
    };

    // Create exporter object
    const csvExporter = new ExportToCsv(options);

    // Generate csv
    csvExporter.generateCsv(metricsToExport);
}
