// General
import React from "react";
import PropTypes from "prop-types";
// Other
import {ExportToCsv} from 'export-to-csv';
// Material-UI
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import {Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
// Styles
import useLiveDataTableStyles from "./LiveDataTable.styles";
import {exportCSV} from "./LiveData.utils";


export default function LiveDataTable({liveData}) {
    // Styles
    const classes = useLiveDataTableStyles();

    // Setup pagination
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(60);
    const rows = liveData.metrics;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    // Handle pagination
    const handleChangePage = (event, newPage) => setPage(newPage);
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <TableContainer component={Paper}>
            <Grid container className={classes.toolbar} xs={12} direction="row" justify="space-between" alignItems="center">
                <TablePagination
                    rowsPerPageOptions={[{label: '1 perc', value: 60}, {label: '2 perc', value: 120}, {
                        label: '3 perc',
                        value: 180
                    }]}
                    colSpan={12}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    component={"div"}
                    style={{borderBottom: "none", padding: 0, marginLeft: 0}}
                    labelRowsPerPage={"Intervallum:"}
                />
                <Tooltip title={"Mentés"} placement={"bottom"}>
                    <IconButton className={classes.extendedIcon}>
                        <SaveAltIcon onClick={() => exportCSV(liveData)}/>
                    </IconButton>
                </Tooltip>
            </Grid>
            <Table className={classes.table} aria-label="caption table">
                <TableHead>
                    <TableRow>
                        <TableCell>Időpont</TableCell>
                        <TableCell>Érték</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : rows
                    ).map((row) => (
                        <TableRow key={row.time} hover>
                            <TableCell>{row.date + " " + row.time}</TableCell>
                            <TableCell>{row.value + " " + liveData.dim}</TableCell>
                        </TableRow>
                    ))}

                    {emptyRows > 0 && (
                        <TableRow style={{height: 53 * emptyRows}}>
                            <TableCell colSpan={6}/>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

// Property definitions
LiveDataTable.propTypes = {
    liveData: PropTypes.object.isRequired
};
