import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useHistory} from "react-router-dom";
import { Link as RouterLink } from 'react-router-dom';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://senso-net.com/">
                Senso-Net Kommunikációs kft.
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Signup() {
    const classes = useStyles();
    const history = useHistory();

    const [values, setValues] = React.useState({
        username: '',
        lastName: '',
        firstName: '',
        password: ''
    });
    const [showPassword, setShowPassword] = React.useState(false);
    const [passwordFieldError, setPasswordFieldError] = React.useState(false);
    const [usernameFieldError, setUsernameFieldError] = React.useState(false);
    const [lastNameFieldError, setLastNameFieldError] = React.useState(false);
    const [firstNameFieldError, setFirstNameFieldError] = React.useState(false);

    const checkTextFields = event => {

        let hasLastNameError = false;
        let hasFirstNameError = false;
        let hasUsernameError = false;
        let hasPasswordError = false;

        if (values.lastName.length === 0) {
            hasLastNameError = true;
        }

        if (values.firstName.length === 0) {
            hasFirstNameError = true;
        }

        if (values.username.length === 0) {
            hasUsernameError = true;
        }
        if (values.password.length === 0) {
            hasPasswordError = true;
        }

        setLastNameFieldError(hasLastNameError);
        setFirstNameFieldError(hasFirstNameError);
        setUsernameFieldError(hasUsernameError);
        setPasswordFieldError(hasPasswordError);

        if (!hasLastNameError && !hasFirstNameError && !hasUsernameError && !hasPasswordError) {
            history.push("/");
        } else {
            event.preventDefault();
        }
    };

    const handleChange = prop => event => {
        setValues({...values, [prop]: event.target.value});
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Regisztráció
                </Typography>
                <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoFocus
                                variant="outlined"
                                required={true}
                                fullWidth
                                id="lastName"
                                label="Vezetéknév"
                                name="lastName"
                                autoComplete="lname"
                                onChange={handleChange("lastName")}
                                error={lastNameFieldError}
                                helperText={ lastNameFieldError ? "Kötelező mező!" : "" }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required={true}
                                fullWidth
                                id="firstName"
                                label="Keresztnév"
                                name="firstName"
                                autoComplete="fname"
                                onChange={handleChange("firstName")}
                                error={firstNameFieldError}
                                helperText={ firstNameFieldError ? "Kötelező mező!" : "" }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required={true}
                                fullWidth
                                id="username"
                                label="Felhasználónév"
                                name="username"
                                autoComplete="email"
                                onChange={handleChange("username")}
                                error={usernameFieldError}
                                helperText={ usernameFieldError ? "Kötelező mező!" : "" }
                            />
                        </Grid>
                        {/*<Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required={true}
                                fullWidth
                                name="password"
                                label="Jelszó"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                        </Grid>*/}
                        <Grid item xs={12}>
                            {/*<FormControlLabel
                                control={<Checkbox value="allowLoginAfterSignup" color="primary"/>}
                                label="Bejelentkezés a a regisztráció után"
                            />*/}
                        </Grid>
                    </Grid>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={checkTextFields}
                    >
                        Regisztráció
                    </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link href="#" variant="body2" component={props => <RouterLink to={"/bejelentkezes"} {...props} />}>
                                Már van felhasználója? Bejelentkezés
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={5}>
                <Copyright/>
            </Box>
        </Container>
    );
}