// General
import React from "react";
import PropTypes from 'prop-types';
// Material-UI
import {lighten, Tooltip} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import SendIcon from "@material-ui/icons/Send";
import TableRow from "@material-ui/core/TableRow";
// Styles
import {useTheme} from "@material-ui/core/styles";
import useLogBookListStyles from "../LogBookList.styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {ConnectionStatus} from "../../../../../redux/actions";


// Create DeleteAlertDialog
const DeleteAlertDialog = ({open, handleDelete, handleCancel}) => {
    const classes = useLogBookListStyles();
    return (
        <Dialog open={open}>
            <DialogTitle className={classes.deleteDialogTitle}>
                {"Biztosan törölni szeretné az üzemnapló bejegyzést?"}
            </DialogTitle>
            <DialogActions>
                <Button onClick={handleCancel}>
                    Mégsem
                </Button>
                <Button onClick={handleDelete}>
                    Igen
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default function LogBookListItem ({log, detailsLog, deleteLog, sendLog, connectionStatus}) {
    // Styles
    const theme = useTheme();

    // Handle delete alert dialog
    const [deleteAlertStatus, setDeleteAlertStatus] = React.useState(false);
    const handleDeleteAlert = () => setDeleteAlertStatus(!deleteAlertStatus);

    // Details
    const handleDetails = () => detailsLog(log);

    // Delete
    const handleDelete = () => {
        deleteLog(log);
        handleDeleteAlert();
    }

    // Send
    const handleSend = () => sendLog(log);

    return(
        <>
            <TableRow hover={true} style={{backgroundColor: (!log.isSent && lighten(theme.palette.primary.main, 0.85))}}>
                <TableCell width={"25%"}>{log.name}</TableCell>
                <TableCell width={"20%"}>{log.category}</TableCell>
                <TableCell width={"20%"}>{log.startDate}</TableCell>
                {/*<TableCell className={classes.description}>
                                {row.desc.substring(0, 30) + (row.desc.length > 30 ? "..." : "")}
                            </TableCell>*/}
                <TableCell width={"20%"}>{log.mqttTopic}</TableCell>
                <TableCell padding={"checkbox"} width={"5%"}>
                    <Tooltip title={"Megtekeintés"} placement={"top"}>
                        <IconButton onClick={handleDetails}>
                            <VisibilityIcon color={"secondary"}/>
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell padding={"checkbox"} width={"5%"}>
                    <Tooltip title={"Törlés"} placement={"top"}>
                        <IconButton onClick={handleDeleteAlert}>
                            <DeleteIcon color={"error"}/>
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell padding={"checkbox"} width={"5%"}>
                    <Tooltip title={"Felküldés"} placement={"top"}>
                        <IconButton disabled={connectionStatus !== ConnectionStatus.CONNECTED} onClick={handleSend}>
                            <SendIcon color={((connectionStatus !== ConnectionStatus.CONNECTED) ? "disabled" : "primary")}/>
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
            <DeleteAlertDialog open={deleteAlertStatus} handleCancel={handleDeleteAlert} handleDelete={handleDelete}/>
        </>
    )
}

// Property definitions
LogBookListItem.propTypes = {
    log: PropTypes.object.isRequired,
    detailsLog: PropTypes.func.isRequired,
    deleteLog: PropTypes.func.isRequired,
    sendLog: PropTypes.func.isRequired
}
