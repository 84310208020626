// General
import React from 'react';
import PropTypes from "prop-types";
// Material-UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {CssBaseline, TextField} from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDateTimePicker} from '@material-ui/pickers';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import huLocale from "date-fns/locale/hu";
// Utils
import {LOG_EDIT_STATES} from "../LogBook.utils";
// Redux
import {createLog, LogbookCategories, LogbookTemplates} from "../../../../redux/actions";
import {store} from "../../../../index";
// Own Component
import LayoutItem from "../../../templates/LayoutItem";
import LinearLayout from "../../../templates/LinearLayout";
// Styles
import useLogDialogStyles from "./LogDialog.styles";
// Utils
import {getCategoryCodeName, refactorDateFormat, Transition} from "./LogDialog.utils";


export default function LogDialog({open, state, close, log}) {
    // Styles
    const classes = useLogDialogStyles();

    // Convert Date
    const currentDate = refactorDateFormat(new Date(), "convertToPickerFormat");

    // Determine log states
    let isNew = state === LOG_EDIT_STATES.NEW;
    let isClosed = state === LOG_EDIT_STATES.CLOSED;

    // Log properties
    const [name, setName] = React.useState(log.name);
    const [nameError, setNameError] = React.useState(false);
    const [category, setCategory] = React.useState(log.category);
    const [templateDictNum, setTemplateDictNum] = React.useState(log.template.number);
    const [templateDict, setTemplateDict] = React.useState(LogbookTemplates[getCategoryCodeName(log.category)]);
    const [selectedTemplate, setSelectedTemplate] = React.useState(log.template);
    const [startDate, setStartDate] = React.useState(log.startDate || currentDate);
    const [description, setDescription] = React.useState(log.desc);
    /*const [endDate, setEndDate] = React.useState("");*/
    /*const [endDateError, setEndDateError] = React.useState(false);*/
    /*const [title, setTitle] = React.useState("");*/
    /*const [titleError, setTitleError] = React.useState(false);*/

    React.useEffect(() => {
        isNew && setDescription(templateDict[0].text)
    }, [templateDict, isNew]);

    // Handle log properties
    const handleNameChange = event => {
        setName(event.target.value);
    };
    const handleCategoryChange = event => {
        setCategory(event.target.value);
        setTemplateDict(LogbookTemplates[getCategoryCodeName(event.target.value)]);
        setSelectedTemplate(LogbookTemplates[getCategoryCodeName(event.target.value)][0]);
    };
    const handleTemplateChange = event => {
        setTemplateDictNum(event.currentTarget.id);
        setSelectedTemplate(templateDict[event.currentTarget.id]);
        setDescription(event.target.value);
    };
    const handleStartDateChange = date => {
        setStartDate(date);
    };
    const handleDescriptionChange = event => {
        setDescription(event.target.value);
    };

    /*const handleEndDateChange = date => {
        setEndDate(date);
        if (new Date(startDate) > date) { setEndDateError(true) }
        else { setEndDateError(false) }
    };*/

    /*const handleTitleChange = event => {
        setTitle(event.target.value);
        if (event.target.value.length > 30) {
            setTitleError(true);
        } else {
            setTitleError(false);
        }
    };*/

    // Handle log save
    const handleSave = () => {

        let errorName = false;
        /*let errorTitle = false;*/

        if (name === "") {
            setNameError(true);
            errorName = true;
        } else {
            setNameError(false);
        }

        if (!errorName /*&& !errorTitle*/) {

            let config = store.getState().deviceConfiguration;

            let mqttTopic = config.VNAM; //.slice(config.VNAM.lastIndexOf('/')+1);

            store.dispatch(createLog({
                name: name,
                category: category,
                startDate: refactorDateFormat(startDate, "saveFormat"),
                /*endDate: refactorDateFormat(endDate, "saveFormat"),*/
                /*title: title,*/
                template: {tempName: selectedTemplate.tempName, text: selectedTemplate.text, number: templateDictNum},
                desc: description,
                mqttTopic: mqttTopic
            }));

            /*setName(initName);
            setCategory(initCategory);
            setStartDate(initStartDate);
            /!*setEndDate(initEndDate);*!/
            /!*setTitle(initTitle);*!/
            setDescription(initDescription);*/

            close();
        }
    };

    return (
        <Dialog fullScreen open={open} TransitionComponent={Transition}>
            <CssBaseline/>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Új napló készítése{/*{title}*/}
                    </Typography>
                    {
                        !isClosed &&
                        <Button autoFocus color="inherit" onClick={handleSave}>
                            Mentés
                        </Button>
                    }
                </Toolbar>
            </AppBar>
            <CssBaseline/>
            <LinearLayout className={classes.container} orientation={"vertical"} sm={12} itemSpacing={5}>
                <LayoutItem>
                    <LinearLayout orientation={"horizontal"} itemsPositionHorizontal={"center"} sm={12} itemSpacing={0}>
                        <LayoutItem sm={6} alignment={"center"}>
                            <TextField required fullWidth id="name" label="Név"
                                       error={nameError}
                                       disabled={!isNew}
                                       defaultValue={name}
                                       helperText={nameError && "Kötelező mező!"}
                                       variant={"outlined"}
                                       size={"medium"}
                                       value={name}
                                       onChange={handleNameChange}/>
                        </LayoutItem>
                    </LinearLayout>
                </LayoutItem>
                <LayoutItem>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={huLocale}>
                        <LinearLayout orientation={"horizontal"} itemsPositionHorizontal={"center"} sm={12}
                                      itemSpacing={0}>
                            <LayoutItem sm={3}>
                                <KeyboardDateTimePicker
                                    variant={"dialog"}
                                    inputVariant="outlined"
                                    ampm={false}
                                    label="Kezdete"
                                    value={startDate}
                                    onChange={handleStartDateChange}
                                    format="yyyy.MM.dd. HH:mm"
                                    cancelLabel={"Mégse"}
                                    showTodayButton
                                    todayLabel={"Mai nap"}
                                    invalidDateMessage={"Hibás dátum formátum!"}
                                    disabled={isClosed}
                                    classname={classes.timePicker}
                                />
                            </LayoutItem>
                            {/*<LayoutItem sm={3}>
                                <KeyboardDateTimePicker
                                    variant={"dialog"}
                                    inputVariant="outlined"
                                    ampm={false}
                                    label="Vége"
                                    value={endDate}
                                    onChange={handleEndDateChange}
                                    format="yyyy.MM.dd. HH:mm"
                                    cancelLabel={"Mégse"}
                                    showTodayButton
                                    todayLabel={"Mai nap"}
                                    minDate={startDate}
                                    minDateMessage={"A kezdet dátum nem lehet nagyobb mint a vég dátum!"}
                                    invalidDateMessage={"Hibás dátum formátum!"}
                                    disabled={isClosed}
                                    error={endDateError}
                                    helperText={endDateError && "A kezdet dátum nem lehet nagyobb mint a vég dátum!"}
                                />
                            </LayoutItem>*/}
                        </LinearLayout>
                    </MuiPickersUtilsProvider>
                </LayoutItem>
                <LayoutItem>
                    <LinearLayout orientation={"horizontal"} itemSpacing={0} sm={12} itemsPositionHorizontal={"center"}>
                        <LayoutItem sm={3} alignment={"center"}>
                            <FormControl variant="outlined">
                                <InputLabel>Kategória</InputLabel>
                                <Select
                                    labelId="log-category"
                                    disabled={!isNew}
                                    id="log-category"
                                    value={category}
                                    onChange={handleCategoryChange}
                                    label="Kategória"
                                    className={classes.categorySelector}
                                >
                                    {LogbookCategories.map((item) => <MenuItem value={item.displayName}>{item.displayName}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </LayoutItem>
                        <LayoutItem sm={3} alignment={"center"}>
                            <FormControl variant="outlined">
                                <InputLabel>Szövegsablon</InputLabel>
                                <Select
                                    labelId="log-template"
                                    disabled={!isNew}
                                    id="log-template"
                                    value={selectedTemplate.text}
                                    onChange={handleTemplateChange}
                                    label="Szövegsablon"
                                    className={classes.templateSelector}
                                >
                                    {templateDict.map((item, index) => <MenuItem id={index} value={item.text}>{item.tempName}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </LayoutItem>
                    </LinearLayout>
                </LayoutItem>
                <LayoutItem>
                    <LinearLayout orientation={"horizontal"} itemSpacing={0} itemsPositionHorizontal={"center"}>
                        <LayoutItem sm={6}>
                            <TextField fullWidth multiline label="Leírás"
                                       disabled={!isNew}
                                       defaultValue={description}
                                       variant={"outlined"}
                                       rows={20}
                                       size={"medium"}
                                       value={description}
                                       onChange={handleDescriptionChange}/>
                        </LayoutItem>
                    </LinearLayout>
                </LayoutItem>
                {/*<LayoutItem>
                    <LinearLayout orientation={"horizontal"} itemSpacing={0} sm={12} itemsPositionHorizontal={"center"}>
                        <LayoutItem sm={7}>
                            <TextField fullWidth required id="title" label="Cím"
                                       error={titleError}
                                       disabled={!isNew}
                                       defaultValue={title}
                                       helperText={titleError && "A cím kötelező és maximum 30 karakter hosszú lehet!"}
                                       variant={"outlined"}
                                       size={"medium"}
                                       value={title}
                                       onChange={handleTitleChange}
                            />
                        </LayoutItem>
                    </LinearLayout>
                </LayoutItem>*/}
            </LinearLayout>
        </Dialog>
    );
}

// Property definitions
LogDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    state: PropTypes.number.isRequired,
    close: PropTypes.func.isRequired,
    log: PropTypes.object.isRequired
};


