import {SET_NOTIFICATION} from "../actions"

const notification = (state = {status: false, severity: "info", message: "Init"}, action) => {
    if (action.type === SET_NOTIFICATION) {
        return action.notification;
    } else {
        /*default*/
        return state;
    }
};

export default notification