
/** Utils for LiveData component */

export function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export function metricFormatting(metrics) {

    let formattedMetrics = [];

    metrics.map((metric) => {
        let date = new Date(metric.time);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();

        if (month < 10) month = "0" + month;
        if (day < 10) day = "0" + day;
        if (hours < 10) hours = "0" + hours;
        if (minutes < 10) minutes = "0" + minutes;
        if (seconds < 10) seconds = "0" + seconds;

        formattedMetrics.push({
            time: hours + ":" + minutes + ":" + seconds,
            date: year + '.' + month + '.' + day + ".",
            value: metric.value
        })

        return ''; // map should return a value
    });

    return formattedMetrics;
}
