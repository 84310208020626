// General
import React from 'react';
import {Link} from "react-router-dom";
// Material-UI
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LogBookIcon from '@material-ui/icons/MenuBook';
import SecondsIcon from '@material-ui/icons/RepeatOne';
import HistoryIcon from '@material-ui/icons/History';
import InfoIcon from '@material-ui/icons/Settings';
import SyncIcon from '@material-ui/icons/Sync';
import Tooltip from "@material-ui/core/Tooltip";
// Styles
import clsx from "clsx";
import useNavigationStyles from "./Navigation.styles";
// Redux
import {Pages, setPage} from "../../redux/actions";
import {store} from "../../index";
import PropTypes from "prop-types";
import {connect} from "react-redux";


function Navigation({page}) {
    // Styles
    const classes = useNavigationStyles();

    // Handle page changes
    const handlePageChange = (event) => store.dispatch(setPage(event.currentTarget.id));

    return (<div>
        <ListItem id={Pages.DIAG} button onClick={handlePageChange} className={classes.listItem} component={props => <Link to={"/app/diagnosztika"} {...props} />}>
            <Tooltip title="Diagnosztika" placement="right">
                <ListItemIcon>
                    <DashboardIcon className={clsx(classes.icon, page === Pages.DIAG && classes.iconActive)}/>
                </ListItemIcon>
            </Tooltip>
        </ListItem>
        <ListItem id={Pages.LIVE_DATA} button onClick={handlePageChange} className={classes.listItem} component={props => <Link to={"/app/elo_adatok"} {...props} />}>
            <Tooltip title="Élő adatok" placement="right">
                <ListItemIcon>
                    <SyncIcon className={clsx(classes.icon, page === Pages.LIVE_DATA && classes.iconActive)}/>
                </ListItemIcon>
            </Tooltip>
        </ListItem>
        <ListItem id={Pages.LOGBOOK} button onClick={handlePageChange} className={classes.listItem} component={props => <Link to={"/app/uzemnaplo"} {...props} />}>
            <Tooltip title="Üzemnapló" placement="right">
                <ListItemIcon>
                    <LogBookIcon className={clsx(classes.icon, page === Pages.LOGBOOK && classes.iconActive)}/>
                </ListItemIcon>
            </Tooltip>
        </ListItem>
        <ListItem id={Pages.SECONDS_DATA} button onClick={handlePageChange} className={classes.listItem} component={props => <Link to={"/app/havaria_adatok"} {...props} />}>
            <Tooltip title="Friss másodperces adatok" placement="right">
                <ListItemIcon>
                    <SecondsIcon className={clsx(classes.icon, page === Pages.SECONDS_DATA && classes.iconActive)}/>
                </ListItemIcon>
            </Tooltip>
        </ListItem>
        <ListItem id={Pages.HISTORICAL_DATA} button onClick={handlePageChange} className={classes.listItem} component={props => <Link to={"/app/hisztorikus_adatok"} {...props} />}>
            <Tooltip title="Hisztorikus adatok" placement="right">
                <ListItemIcon>
                    <HistoryIcon className={clsx(classes.icon, page === Pages.HISTORICAL_DATA && classes.iconActive)}/>
                </ListItemIcon>
            </Tooltip>
        </ListItem>
        <ListItem id={Pages.SETTINGS} button onClick={handlePageChange} className={classes.listItem} component={props => <Link to={"/app/beallitasok"} {...props} />}>
            <Tooltip title="Beállítások" placement="right">
                <ListItemIcon>
                    <InfoIcon className={clsx(classes.icon, page === Pages.SETTINGS && classes.iconActive)}/>
                </ListItemIcon>
            </Tooltip>
        </ListItem>
    </div>)
}

// Property definitions
Navigation.propTypes = {
    page: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    page: state.pages
});

const NavigationContainer = connect(mapStateToProps, null)(Navigation);

export default NavigationContainer
