import {store} from "../../index";
import {setHistoricalData, setHistoricalDataStatus} from "../actions";

let dumpData = "null\n" +
    "EEPROM-DUMP\n" +
    ",[0049f0:16:20]: 27187;06.07.2023;12:30:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[004a06:16:20]: 27187;06.07.2023;12:31:42;0;Pbe;18;mbar;0;Pki;18.33;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[004a1c:16:20]: 27187;06.07.2023;12:32:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[004a32:16:20]: 27187;06.07.2023;12:33:00;0;Pbe;58;mbar;0;Pki;57.39;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[004a48:16:20]: 27187;06.07.2023;12:34:00;0;Pbe;75;mbar;0;Pki;75.14;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[004a5e:16:20]: 27187;06.07.2023;12:31:35;0;Pbe;18;mbar;0;Pki;18.33;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004a74:16:20]: 27187;06.07.2023;12:31:44;0;Pbe;21;mbar;0;Pki;21.08;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004a8a:16:20]: 27187;06.07.2023;12:31:46;0;Pbe;28;mbar;0;Pki;28.64;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    "[004aa0:16:20]: 27187;06.07.2023;12:31:48;0;Pbe;32;mbar;0;Pki;32.07;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004ab6:16:20]: 27187;06.07.2023;12:31:50;0;Pbe;36;mbar;0;Pki;35.59;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004acc:16:20]: 27187;06.07.2023;12:31:52;0;Pbe;39;mbar;0;Pki;39.10;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004ae2:16:20]: 27187;06.07.2023;12:31:54;0;Pbe;41;mbar;0;Pki;41.08;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004af8:16:20]: 27187;06.07.2023;12:31:56;0;Pbe;44;mbar;0;Pki;44.22;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004b0e:16:20]: 27187;06.07.2023;12:31:58;0;Pbe;47;mbar;0;Pki;46.96;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004b24:16:20]: 27187;06.07.2023;12:32:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004b3a:16:20]: 27187;06.07.2023;12:32:02;0;Pbe;54;mbar;0;Pki;54.07;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004b50:16:20]: 27187;06.07.2023;12:32:04;0;Pbe;58;mbar;0;Pki;58.19;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    "[004b66:16:20]: 27187;06.07.2023;12:32:06;0;Pbe;62;mbar;0;Pki;62.01;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004b7c:16:20]: 27187;06.07.2023;12:32:08;0;Pbe;64;mbar;0;Pki;64.07;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004b92:16:20]: 27187;06.07.2023;12:32:10;0;Pbe;68;mbar;0;Pki;68.04;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004ba8:16:20]: 27187;06.07.2023;12:32:12;0;Pbe;73;mbar;0;Pki;73.23;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004bbe:16:20]: 27187;06.07.2023;12:32:14;0;Pbe;79;mbar;0;Pki;79.34;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004bd4:16:20]: 27187;06.07.2023;12:32:22;0;Pbe;81;mbar;0;Pki;80.72;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004bea:16:20]: 27187;06.07.2023;12:32:24;0;Pbe;83;mbar;0;Pki;83.31;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004c00:16:20]: 27187;06.07.2023;12:32:26;0;Pbe;85;mbar;0;Pki;84.54;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004c16:16:20]: 27187;06.07.2023;12:32:28;0;Pbe;79;mbar;0;Pki;79.04;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    "[004c2c:16:20]: 27187;06.07.2023;12:32:30;0;Pbe;73;mbar;0;Pki;73.16;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004c42:16:20]: 27187;06.07.2023;12:32:32;0;Pbe;68;mbar;0;Pki;68.42;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004c58:16:20]: 27187;06.07.2023;12:32:56;0;Pbe;65;mbar;0;Pki;65.22;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004c6e:16:20]: 27187;06.07.2023;12:32:58;0;Pbe;61;mbar;0;Pki;60.56;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004c84:16:20]: 27187;06.07.2023;12:33:00;0;Pbe;58;mbar;0;Pki;57.35;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004c9a:16:20]: 27187;06.07.2023;12:33:02;0;Pbe;52;mbar;0;Pki;52.16;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004cb0:16:20]: 27187;06.07.2023;12:33:04;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004cc6:16:20]: 27187;06.07.2023;12:33:06;0;Pbe;49;mbar;0;Pki;48.95;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004cdc:16:20]: 27187;06.07.2023;12:33:08;0;Pbe;40;mbar;0;Pki;39.48;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    "[004cf2:16:20]: 27187;06.07.2023;12:33:10;0;Pbe;34;mbar;0;Pki;34.36;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004d08:16:20]: 27187;06.07.2023;12:33:12;0;Pbe;37;mbar;0;Pki;37.42;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004d1e:16:20]: 27187;06.07.2023;12:33:14;0;Pbe;46;mbar;0;Pki;46.12;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004d34:16:20]: 27187;06.07.2023;12:33:16;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004d4a:16:20]: 27187;06.07.2023;12:33:56;0;Pbe;57;mbar;0;Pki;56.97;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004d60:16:20]: 27187;06.07.2023;12:33:58;0;Pbe;69;mbar;0;Pki;68.80;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004d76:16:20]: 27187;06.07.2023;12:34:00;0;Pbe;75;mbar;0;Pki;75.14;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004d8c:16:20]: 27187;06.07.2023;12:34:02;0;Pbe;82;mbar;0;Pki;81.48;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004da2:16:20]: 27187;06.07.2023;12:34:04;0;Pbe;69;mbar;0;Pki;67.74;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    "[004db8:16:20]: 27187;06.07.2023;12:34:06;0;Pbe;56;mbar;0;Pki;55.75;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004dce:16:20]: 27187;06.07.2023;12:34:10;0;Pbe;50;mbar;0;Pki;49.71;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004de4:16:20]: 27187;06.07.2023;12:34:12;0;Pbe;45;mbar;0;Pki;45.44;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004dfa:16:20]: 27187;06.07.2023;12:34:34;0;Pbe;45;mbar;0;Pki;45.44;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[004e10:16:20]: 27187;06.07.2023;12:38:07;0;Pbe;49;mbar;0;Pki;49.37;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[004e26:16:20]: 27187;06.07.2023;12:38:27;0;Pbe;49;mbar;0;Pki;49.33;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[004e3c:16:20]: 27187;06.07.2023;12:43:10;0;Pbe;49;mbar;0;Pki;49.33;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[004e52:16:20]: 27187;06.07.2023;12:53:17;0;Pbe;49;mbar;0;Pki;49.33;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[004e68:16:20]: 27187;06.07.2023;13:00:00;0;Pbe;49;mbar;0;Pki;49.41;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    "[004e7e:16:20]: 27187;06.07.2023;13:30:00;0;Pbe;49;mbar;0;Pki;49.41;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[004e94:16:20]: 27187;06.07.2023;14:00:00;0;Pbe;49;mbar;0;Pki;49.41;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[004eaa:16:20]: 27187;06.07.2023;14:30:00;0;Pbe;49;mbar;0;Pki;49.37;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[004ec0:16:20]: 27187;06.07.2023;15:00:00;0;Pbe;49;mbar;0;Pki;49.37;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[004ed6:16:20]: 27187;06.07.2023;15:30:00;0;Pbe;49;mbar;0;Pki;49.41;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[004eec:16:20]: 27187;06.07.2023;16:00:00;0;Pbe;49;mbar;0;Pki;49.37;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[004f02:16:20]: 27187;06.07.2023;16:30:00;0;Pbe;49;mbar;0;Pki;49.33;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[004f18:16:20]: 27187;06.07.2023;17:00:00;0;Pbe;49;mbar;0;Pki;49.37;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[004f2e:16:20]: 27187;06.07.2023;17:30:00;0;Pbe;49;mbar;0;Pki;49.41;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    "[004f44:16:20]: 27187;06.07.2023;18:00:00;0;Pbe;49;mbar;0;Pki;49.37;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[004f5a:16:20]: 27187;06.07.2023;18:30:00;0;Pbe;49;mbar;0;Pki;49.37;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[004f70:16:20]: 27187;06.07.2023;19:00:00;0;Pbe;49;mbar;0;Pki;49.37;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[004f86:16:20]: 27187;06.07.2023;19:30:00;0;Pbe;49;mbar;0;Pki;49.37;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[004f9c:16:20]: 27187;06.07.2023;20:00:00;0;Pbe;49;mbar;0;Pki;49.37;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[004fb2:16:20]: 27187;06.07.2023;20:30:00;0;Pbe;49;mbar;0;Pki;49.37;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[004fc8:16:20]: 27187;06.07.2023;21:00:00;0;Pbe;49;mbar;0;Pki;49.37;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[004fde:16:20]: 27187;06.07.2023;21:30:00;0;Pbe;49;mbar;0;Pki;49.37;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[004ff4:16:20]: 27187;06.07.2023;22:00:00;0;Pbe;49;mbar;0;Pki;49.41;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    "[00500a:16:20]: 27187;06.07.2023;22:30:00;0;Pbe;49;mbar;0;Pki;49.37;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[005020:16:20]: 27187;06.07.2023;23:00:00;0;Pbe;49;mbar;0;Pki;49.41;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[005036:16:20]: 27187;06.07.2023;23:30:00;0;Pbe;49;mbar;0;Pki;49.37;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[00504c:16:20]: 27187;07.07.2023;00:00:00;0;Pbe;49;mbar;0;Pki;49.37;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[005062:16:20]: 27187;07.07.2023;00:30:00;0;Pbe;49;mbar;0;Pki;49.37;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[005078:16:20]: 27187;07.07.2023;01:00:00;0;Pbe;49;mbar;0;Pki;49.37;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[00508e:16:20]: 27187;07.07.2023;01:30:00;0;Pbe;49;mbar;0;Pki;49.37;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[0050a4:16:20]: 27187;07.07.2023;02:00:00;0;Pbe;49;mbar;0;Pki;49.33;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[0050ba:16:20]: 27187;07.07.2023;02:30:00;0;Pbe;49;mbar;0;Pki;49.41;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    "[0050d0:16:20]: 27187;07.07.2023;03:00:00;0;Pbe;49;mbar;0;Pki;49.37;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[0050e6:16:20]: 27187;07.07.2023;03:30:00;0;Pbe;49;mbar;0;Pki;49.37;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[0050fc:16:20]: 27187;07.07.2023;04:00:00;0;Pbe;49;mbar;0;Pki;49.37;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[005112:16:20]: 27187;07.07.2023;04:30:00;0;Pbe;49;mbar;0;Pki;49.37;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[005128:16:20]: 27187;07.07.2023;05:00:00;0;Pbe;49;mbar;0;Pki;49.37;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[00513e:16:20]: 27187;07.07.2023;05:30:00;0;Pbe;49;mbar;0;Pki;49.37;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[005154:16:20]: 27187;07.07.2023;06:00:00;0;Pbe;49;mbar;0;Pki;49.37;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[00516a:16:20]: 27187;07.07.2023;06:30:00;0;Pbe;49;mbar;0;Pki;49.37;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[005180:16:20]: 27187;07.07.2023;07:00:00;0;Pbe;49;mbar;0;Pki;49.41;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    "[005196:16:20]: 27187;07.07.2023;07:30:00;0;Pbe;49;mbar;0;Pki;49.33;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[0051ac:16:20]: 27187;07.07.2023;08:00:00;0;Pbe;49;mbar;0;Pki;49.41;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[0051c2:16:20]: 27187;07.07.2023;08:30:00;0;Pbe;49;mbar;0;Pki;49.41;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[0051d8:16:20]: 27187;07.07.2023;09:00:00;0;Pbe;49;mbar;0;Pki;49.37;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[0051ee:16:20]: 27187;07.07.2023;09:30:00;0;Pbe;49;mbar;0;Pki;49.37;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[005204:16:20]: 27187;07.07.2023;09:52:00;0;Pbe;77;mbar;0;Pki;77.09;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[00521a:16:20]: 27187;07.07.2023;09:53:00;0;Pbe;55;mbar;0;Pki;55.02;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[005230:16:20]: 27187;07.07.2023;09:50:39;0;Pbe;49;mbar;0;Pki;49.33;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[005246:16:20]: 27187;07.07.2023;09:51:50;0;Pbe;53;mbar;0;Pki;53.38;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    "[00525c:16:20]: 27187;07.07.2023;09:51:52;0;Pbe;57;mbar;0;Pki;57.43;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[005272:16:20]: 27187;07.07.2023;09:51:54;0;Pbe;61;mbar;0;Pki;61.24;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[005288:16:20]: 27187;07.07.2023;09:51:56;0;Pbe;65;mbar;0;Pki;65.22;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[00529e:16:20]: 27187;07.07.2023;09:51:58;0;Pbe;69;mbar;0;Pki;69.26;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[0052b4:16:20]: 27187;07.07.2023;09:52:00;0;Pbe;77;mbar;0;Pki;77.05;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[0052ca:16:20]: 27187;07.07.2023;09:52:08;0;Pbe;81;mbar;0;Pki;80.64;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[0052e0:16:20]: 27187;07.07.2023;09:52:10;0;Pbe;82;mbar;0;Pki;82.09;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[0052f6:16:20]: 27187;07.07.2023;09:52:12;0;Pbe;83;mbar;0;Pki;83.70;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[00530c:16:20]: 27187;07.07.2023;09:52:14;0;Pbe;85;mbar;0;Pki;85.30;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    "[005322:16:20]: 27187;07.07.2023;09:52:20;0;Pbe;87;mbar;0;Pki;86.90;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[005338:16:20]: 27187;07.07.2023;09:52:28;0;Pbe;86;mbar;0;Pki;85.76;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[00534e:16:20]: 27187;07.07.2023;09:52:30;0;Pbe;84;mbar;0;Pki;84.23;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[005364:16:20]: 27187;07.07.2023;09:52:32;0;Pbe;82;mbar;0;Pki;82.02;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[00537a:16:20]: 27187;07.07.2023;09:52:36;0;Pbe;81;mbar;0;Pki;80.64;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[005390:16:20]: 27187;07.07.2023;09:52:38;0;Pbe;79;mbar;0;Pki;79.42;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[0053a6:16:20]: 27187;07.07.2023;09:52:40;0;Pbe;77;mbar;0;Pki;77.05;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[0053bc:16:20]: 27187;07.07.2023;09:52:42;0;Pbe;76;mbar;0;Pki;75.53;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[0053d2:16:20]: 27187;07.07.2023;09:52:44;0;Pbe;75;mbar;0;Pki;74.76;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    "[0053e8:16:20]: 27187;07.07.2023;09:52:46;0;Pbe;72;mbar;0;Pki;71.55;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[0053fe:16:20]: 27187;07.07.2023;09:52:48;0;Pbe;69;mbar;0;Pki;68.73;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[005414:16:20]: 27187;07.07.2023;09:52:50;0;Pbe;68;mbar;0;Pki;67.74;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[00542a:16:20]: 27187;07.07.2023;09:52:52;0;Pbe;65;mbar;0;Pki;65.22;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[005440:16:20]: 27187;07.07.2023;09:52:54;0;Pbe;64;mbar;0;Pki;63.61;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[005456:16:20]: 27187;07.07.2023;09:52:56;0;Pbe;63;mbar;0;Pki;62.47;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[00546c:16:20]: 27187;07.07.2023;09:52:58;0;Pbe;59;mbar;0;Pki;58.57;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[005482:16:20]: 27187;07.07.2023;09:53:00;0;Pbe;55;mbar;0;Pki;54.98;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[005498:16:20]: 27187;07.07.2023;09:53:02;0;Pbe;51;mbar;0;Pki;51.32;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    "[0054ae:16:20]: 27187;07.07.2023;09:53:04;0;Pbe;49;mbar;0;Pki;48.57;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[0054c4:16:20]: 27187;07.07.2023;09:53:06;0;Pbe;47;mbar;0;Pki;47.42;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[0054da:16:20]: 27187;07.07.2023;09:53:10;0;Pbe;46;mbar;0;Pki;45.82;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[0054f0:16:20]: 27187;07.07.2023;09:53:12;0;Pbe;44;mbar;0;Pki;44.22;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[005506:16:20]: 27187;07.07.2023;09:53:14;0;Pbe;41;mbar;0;Pki;41.47;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[00551c:16:20]: 27187;07.07.2023;09:53:16;0;Pbe;40;mbar;0;Pki;39.86;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[005532:16:20]: 27187;07.07.2023;09:53:38;0;Pbe;40;mbar;0;Pki;39.86;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[005548:16:20]: 27187;07.07.2023;09:55:41;0;Pbe;71;mbar;0;Pki;71.02;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    ",[00555e:16:20]: 27187;07.07.2023;09:57:31;0;Pbe;29;mbar;0;Pki;28.98;mbar;0;Gyz1;0;;0;Gyz2;1;;0;|00\n" +
    "[005574:16:20]: 27187;07.07.2023;09:58:00;0;Pbe;23;mbar;0;Pki;23.06;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[00558a:16:20]: 27187;07.07.2023;09:57:31;0;Pbe;29;mbar;0;Pki;28.94;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[0055a0:16:20]: 27187;07.07.2023;09:57:33;0;Pbe;27;mbar;0;Pki;27.34;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[0055b6:16:20]: 27187;07.07.2023;09:57:35;0;Pbe;26;mbar;0;Pki;26.27;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[0055cc:16:20]: 27187;07.07.2023;09:57:37;0;Pbe;24;mbar;0;Pki;24.28;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[0055e2:16:20]: 27187;07.07.2023;09:57:39;0;Pbe;23;mbar;0;Pki;22.68;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[0055f8:16:20]: 27187;07.07.2023;09:57:41;0;Pbe;21;mbar;0;Pki;21.08;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[00560e:16:20]: 27187;07.07.2023;09:57:43;0;Pbe;20;mbar;0;Pki;19.85;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005624:16:20]: 27187;07.07.2023;09:57:45;0;Pbe;19;mbar;0;Pki;18.71;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    "[00563a:16:20]: 27187;07.07.2023;09:57:49;0;Pbe;17;mbar;0;Pki;16.72;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005650:16:20]: 27187;07.07.2023;09:57:53;0;Pbe;19;mbar;0;Pki;18.71;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005666:16:20]: 27187;07.07.2023;09:57:57;0;Pbe;21;mbar;0;Pki;20.62;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[00567c:16:20]: 27187;07.07.2023;09:57:59;0;Pbe;22;mbar;0;Pki;21.84;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005692:16:20]: 27187;07.07.2023;09:58:00;0;Pbe;23;mbar;0;Pki;23.06;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[0056a8:16:20]: 27187;07.07.2023;09:58:02;0;Pbe;27;mbar;0;Pki;26.58;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[0056be:16:20]: 27187;07.07.2023;09:58:04;0;Pbe;30;mbar;0;Pki;29.71;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[0056d4:16:20]: 27187;07.07.2023;09:58:06;0;Pbe;35;mbar;0;Pki;35.20;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[0056ea:16:20]: 27187;07.07.2023;09:58:08;0;Pbe;41;mbar;0;Pki;41.08;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    "[005700:16:20]: 27187;07.07.2023;09:58:08;0;Pbe;41;mbar;0;Pki;41.08;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005716:16:20]: 27187;07.07.2023;10:00:03;0;Pbe;94;mbar;0;Pki;94.39;mbar;0;Gyz1;1;;0;Gyz2;0;;0;|00\n" +
    ",[00572c:16:20]: 27187;07.07.2023;10:01:54;0;Pbe;57;mbar;0;Pki;56.97;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[005742:16:20]: 27187;07.07.2023;10:01:54;0;Pbe;57;mbar;0;Pki;56.97;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[005758:16:20]: 27187;07.07.2023;10:03:44;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[00576e:16:20]: 27187;07.07.2023;10:03:44;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;1;;0;Gyz2;1;;0;|00\n" +
    ",[005784:16:20]: 27187;07.07.2023;10:07:25;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[00579a:16:20]: 27187;07.07.2023;10:30:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[0057b0:16:20]: 27187;07.07.2023;11:00:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    "[0057c6:16:20]: 27187;07.07.2023;11:30:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[0057dc:16:20]: 27187;07.07.2023;12:00:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[0057f2:16:20]: 27187;07.07.2023;12:30:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005808:16:20]: 27187;07.07.2023;13:00:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[00581e:16:20]: 27187;07.07.2023;13:30:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005834:16:20]: 27187;07.07.2023;14:00:00;0;Pbe;50;mbar;0;Pki;50.21;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[00584a:16:20]: 27187;07.07.2023;14:30:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005860:16:20]: 27187;07.07.2023;15:00:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005876:16:20]: 27187;07.07.2023;15:30:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    "[00588c:16:20]: 27187;07.07.2023;16:00:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[0058a2:16:20]: 27187;07.07.2023;16:30:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[0058b8:16:20]: 27187;07.07.2023;17:00:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[0058ce:16:20]: 27187;07.07.2023;17:30:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[0058e4:16:20]: 27187;07.07.2023;18:00:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[0058fa:16:20]: 27187;07.07.2023;18:30:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005910:16:20]: 27187;07.07.2023;19:00:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005926:16:20]: 27187;07.07.2023;19:30:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[00593c:16:20]: 27187;07.07.2023;20:00:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    "[005952:16:20]: 27187;07.07.2023;20:30:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005968:16:20]: 27187;07.07.2023;21:00:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[00597e:16:20]: 27187;07.07.2023;21:30:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005994:16:20]: 27187;07.07.2023;22:00:00;0;Pbe;50;mbar;0;Pki;50.21;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[0059aa:16:20]: 27187;07.07.2023;22:30:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[0059c0:16:20]: 27187;07.07.2023;23:00:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[0059d6:16:20]: 27187;07.07.2023;23:30:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[0059ec:16:20]: 27187;08.07.2023;00:00:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005a02:16:20]: 27187;08.07.2023;00:30:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    "[005a18:16:20]: 27187;08.07.2023;01:00:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005a2e:16:20]: 27187;08.07.2023;01:30:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005a44:16:20]: 27187;08.07.2023;02:00:00;0;Pbe;50;mbar;0;Pki;50.21;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005a5a:16:20]: 27187;08.07.2023;02:30:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005a70:16:20]: 27187;08.07.2023;03:00:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005a86:16:20]: 27187;08.07.2023;03:30:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005a9c:16:20]: 27187;08.07.2023;04:00:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005ab2:16:20]: 27187;08.07.2023;04:30:00;0;Pbe;50;mbar;0;Pki;50.21;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005ac8:16:20]: 27187;08.07.2023;05:00:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    "[005ade:16:20]: 27187;08.07.2023;05:30:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005af4:16:20]: 27187;08.07.2023;06:00:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005b0a:16:20]: 27187;08.07.2023;06:30:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005b20:16:20]: 27187;08.07.2023;07:00:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005b36:16:20]: 27187;08.07.2023;07:30:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005b4c:16:20]: 27187;08.07.2023;08:00:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005b62:16:20]: 27187;08.07.2023;08:30:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005b78:16:20]: 27187;08.07.2023;09:00:00;0;Pbe;50;mbar;0;Pki;50.21;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005b8e:16:20]: 27187;08.07.2023;09:30:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    "[005ba4:16:20]: 27187;08.07.2023;10:00:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005bba:16:20]: 27187;08.07.2023;10:30:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005bd0:16:20]: 27187;08.07.2023;11:00:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005be6:16:20]: 27187;08.07.2023;11:30:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005bfc:16:20]: 27187;08.07.2023;12:00:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005c12:16:20]: 27187;08.07.2023;12:30:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005c28:16:20]: 27187;08.07.2023;13:00:00;0;Pbe;50;mbar;0;Pki;50.17;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",[005c3e:16:20]: 27187;08.07.2023;13:30:00;0;Pbe;50;mbar;0;Pki;50.21;mbar;0;Gyz1;0;;0;Gyz2;0;;0;|00\n" +
    ",,DUMP ENDE\n" +
    "\n" +
    ",DUMP=2\n" +
    ",OK\n" +
    ","

let dumpArray = dumpData.split("\n");

function randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export function publishChunk() {
    const random = randomIntFromInterval(8, 12);

    const chunk = dumpArray.splice(0, random);

    store.dispatch(setHistoricalData(chunk));

    if (dumpArray.length > 0) {
        setTimeout(publishChunk, 1000);
    } else {
        store.dispatch(setHistoricalDataStatus(false));
    }
}