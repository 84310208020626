import serialWorker from "./wifiWorker.js";
import {
    CommandBufferStatus,
    ConnectionMessage,
    ConnectionStatus, SET_HISTORICAL_DATA,
    setCommandBuffer,
    setConnectionStatus,
    setDeviceConfiguration, setHistoricalData, setHistoricalDataStatus,
    setLiveData, setNotification
} from './redux/actions';
import {store} from './index.js';

let serialWorkerInstance;
const DEBUG_MODE = false;

// Wrapper class that helps to work with webpack URLs
class WebWorker {

    constructor(worker) {
        const code = worker.toString();
        const blob = new Blob(["(" + code + ")()"]);
        return new Worker(URL.createObjectURL(blob));
    }
}

export default function registerCommWorker() {

    // Initialize worker instance
    serialWorkerInstance = new WebWorker(serialWorker);
    serialWorkerInstance.addEventListener("message", evt => processWorkerMessages(evt), false);
    console.log('Worker INITIALIZED');

    // Subscribe to redux store changes
    store.subscribe(() => {

        let connectionStatus = store.getState().connection.connectionStatus.status;

        if (connectionStatus === ConnectionStatus.CONNECTION_REQUESTED) {

            // User request for connect

            let connectionParams;
            if (localStorage.getItem('AirConsole'))
                connectionParams = {uri: localStorage.getItem('AirConsole')};
            else
                connectionParams = store.getState().connection.connectionParams;

            connectionParams.ConnectionStatus = ConnectionStatus;
            connectionParams.ConnectionMessage = ConnectionMessage;
            connectionParams.CommandBufferStatus = CommandBufferStatus;

            serialWorkerInstance.postMessage({action: 'connect', params: connectionParams});
        }

        if (connectionStatus === ConnectionStatus.DISCONNECT_REQUESTED) {

            // User request for disconnect
            serialWorkerInstance.postMessage({action: 'disconnect', params: null});

        }

        if (connectionStatus === ConnectionStatus.PAUSED) {

            let commandBuffer = store.getState().commandBuffer.commandBuffer;

            commandBuffer = commandBuffer.map(item => getCommandStr(item));

            if (commandBuffer.length > 0) {

                // Send command buffer to worker thread
                serialWorkerInstance.postMessage({
                    action: 'command',
                    params: commandBuffer
                });

                store.dispatch(setCommandBuffer({commandBuffer: [], connectionStatus: ""}))

            }

        }

    });

}

function processWorkerMessages(evt) {

    let messageFromWorker = evt.data;

    // Check message object conformity
    if (typeof messageFromWorker === 'object') {

        if ('debug' in messageFromWorker) {
            DEBUG_MODE && console.log(messageFromWorker.debug);
        }

        if ('warning' in messageFromWorker) {
            console.warn(messageFromWorker.warning);
        }

        if ('error' in messageFromWorker) {
            console.error(messageFromWorker.error);
        }

        // Check if connection status message arrived
        if ('status' in messageFromWorker) {

            // Dispatch status change
            store.dispatch(setConnectionStatus(messageFromWorker));

        }

        // Check if dispatch message arrived
        if ('dispatch' in messageFromWorker) {

            switch (messageFromWorker.dispatch) {

                /* Configuration update arrived */
                case 'configurationData':
                    let deviceConfig = store.getState().deviceConfiguration;
                    store.dispatch(setDeviceConfiguration({...deviceConfig, ...messageFromWorker.data}));
                    localStorage.setItem('DUMP', JSON.stringify({...deviceConfig, ...messageFromWorker.data}))
                    break;

                /* Live Data arrived */
                case 'liveData':
                    store.dispatch(setLiveData(messageFromWorker.data));
                    break;

                /* Save live data in session storage */
                case 'saveLiveData':
                    saveLiveDataInSessionStorage(messageFromWorker.data);
                    break;

                /* Havaria data arrived */
                case 'havariaData':
                    sessionStorage.setItem('HAVARIA_BUFFER', messageFromWorker.data);
                    break;

                /* Historical data arrived */
                case 'historicalData':
                    const reg = new RegExp(".*DUMP ENDE.*");
                    if (messageFromWorker.data?.find(rowStr => {
                        const result = reg.test(rowStr);
                        if (result) {
                            console.log("DUMP ENDE found: " + rowStr);
                            return true;
                        }
                    })) {
                        setTimeout(() => store.dispatch(setHistoricalDataStatus(false)), 2500);
                    }
                    setTimeout(() => store.dispatch(setHistoricalData(messageFromWorker.data)), 1000);
                    break;

                /* Command result arrived */
                case 'commandResult':
                    store.dispatch(setCommandBuffer({commandBuffer: [], commandBufferStatus: messageFromWorker.data}));

                    if (messageFromWorker.data === CommandBufferStatus.COMMAND_OK)
                        store.dispatch(setNotification(
                            {status: true, severity: 'success', message: 'Művelet sikeres'}));

                    if (messageFromWorker.data === CommandBufferStatus.COMMAND_ERROR)
                        store.dispatch(setNotification(
                            {status: true, severity: 'error', message: 'Művelet sikertelen, próbálja újra!'}));
                    break;

                /* Default query behaviour */
                default:
                    store.dispatch(setHistoricalData(messageFromWorker.data));
                    break;
            }
        }
    }
}

function saveLiveDataInSessionStorage(currentLiveData) {

    var liveStorage = sessionStorage.getItem(currentLiveData.id);
    var liveBuffer;
    const STORAGE_MAX_SIZE = 500;

    // Check if Session Storage already exists
    if (liveStorage == null) {
        // Storage not exists, init with current data
        liveBuffer = currentLiveData;
        liveBuffer.metrics = [liveBuffer.metrics];
    } else {

        // Storage exists, extend it with current data
        liveBuffer = JSON.parse(liveStorage);

        liveBuffer.metrics.push(currentLiveData.metrics);

        // Drop the oldest item if the buffer longer than STORAGE_MAX_SIZE
        liveBuffer.metrics = liveBuffer.metrics.splice(STORAGE_MAX_SIZE * -1)

    }

    // Store buffer
    sessionStorage.setItem(currentLiveData.id, JSON.stringify(liveBuffer));
}

/**
 * CommandObject = {
 *     commandType: 'SET_ONLY' || 'QUERY_FULL' || 'QUERY_LINE_BY_LINE'
 *     action: 'VANM' || 'VAIN' || 'WAUG' || 'WAOG' || 'VAOR' || 'GTFL' || 'WAWD' || 'WANM' || 'WAOK' || 'WAWA' || 'GSEV' || 'GSMQ' || 'DUMP'
 *     value: string || number
 *     timeout?: number
 *     dispatchFreq?: number (Only makes sense for a command of type 'QUERY_LINE_BY_LINE')
 *     dispatch?: string (function name)
 *     onOK?: string (function name)
 *     onError?: string (function name)
 * }
 * */
function getCommandStr(bufferItem) {

    if (typeof bufferItem !== 'object')
        return;
    else if (!('paramName' in bufferItem))
        return;
    else if (!('paramIndex' in bufferItem))
        return;
    else if (!('paramValue' in bufferItem))
        return;

    // Input name
    if (bufferItem['paramName'] === 'inputName') {
        return {
            commandType: 'SET_ONLY',
            action: 'VANM' + bufferItem['paramIndex'],
            value: bufferItem['paramValue']
        };
    }

    // Physical input channel
    if (bufferItem['paramName'] === 'physicalInput') {
        return {
            commandType: 'SET_ONLY',
            action: 'VAIN' + bufferItem['paramIndex'],
            value: bufferItem['paramValue']
        };
    }

    // Lower limit
    if (bufferItem['paramName'] === 'warnLow') {
        return {
            commandType: 'SET_ONLY',
            action: 'WAUG' + bufferItem['paramIndex'],
            value: bufferItem['paramValue']
        };
    }

    // Upper limit
    if (bufferItem['paramName'] === 'warnHigh') {
        return {
            commandType: 'SET_ONLY',
            action: 'WAOG' + bufferItem['paramIndex'],
            value: bufferItem['paramValue']
        };
    }

    // Upper limit
    if (bufferItem['paramName'] === 'limitHigh') {
        return {
            commandType: 'SET_ONLY',
            action: 'VAOR' + bufferItem['paramIndex'],
            value: bufferItem['paramValue']
        };
    }

    // AlarmSend
    if (bufferItem['paramName'] === 'alarmSend') {
        return {
            commandType: 'SET_ONLY',
            action: 'GTFL' + bufferItem['paramIndex'],
            value: bufferItem['paramValue']
        };
    }

    // AlarmAction
    if (bufferItem['paramName'] === 'alarmAction') {
        return {
            commandType: 'SET_ONLY',
            action: 'WAWD' + bufferItem['paramIndex'],
            value: bufferItem['paramValue']
        };
    }

    // AlarmName
    if (bufferItem['paramName'] === 'alarmName') {
        return {
            commandType: 'SET_ONLY',
            action: 'WANM' + bufferItem['paramIndex'],
            value: bufferItem['paramValue']
        };
    }

    // AlarmOkStr
    if (bufferItem['paramName'] === 'alarmOKStr') {
        return {
            commandType: 'SET_ONLY',
            action: 'WAOK' + bufferItem['paramIndex'],
            value: bufferItem['paramValue']
        };
    }

    // AlarmWarnStr
    if (bufferItem['paramName'] === 'alarmWarnStr') {
        return {
            commandType: 'SET_ONLY',
            action: 'WAWA' + bufferItem['paramIndex'],
            value: bufferItem['paramValue']
        };
    }

    // Logbook over GSM
    if (bufferItem['paramName'] === 'logbook') {
        return {
            commandType: 'SET_ONLY',
            action: 'GSEV' + bufferItem['paramIndex'],
            value: bufferItem['paramValue']
        };
    }

    // GSM ON/OFF - value ON=1 used to force sending logbook over MQTT
    if (bufferItem['paramName'] === 'gsmONOFF') {
        return {
            commandType: 'SET_ONLY',
            action: 'GSMQ' + bufferItem['paramIndex'],
            value: bufferItem['paramValue'],
            timeout: localStorage.getItem('gsmTimeoutMillisec')
        };
    }

    // Get historical data
    if (bufferItem['paramName'] === 'historicalData') {
        return {
            commandType: 'QUERY_LINE_BY_LINE',
            action: 'DUMP',
            value: 2,
            timeout: 60 * 60 * 1000,
            dispatchFreq: 10,
            dispatch: 'historicalData'
        };
    }

}
