import {makeStyles} from "@material-ui/core/styles";

/** Styles for Settings component */

export default makeStyles(theme => ({
    divider: {
        marginTop: 17.5,
        marginBottom: 17.5,
    },
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    listPaper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column'
    },
    listSubheader: {
        color: theme.palette.primary.main,
        paddingBottom: theme.spacing(3),
    },
    saveFab: {
        position: "absolute",
        bottom: theme.spacing(4),
        right: theme.spacing(3.5),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    formControl: {
        width: '100%',
        minWidth: 210
    },
    backdrop: {
        zIndex: theme.zIndex.drawer - 1,
        color: '#fff',
    },
    progressbar: {
        marginTop: theme.spacing(2)
    }
}));
