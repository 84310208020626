// General
import React from "react";
// Material-UI
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// Own Components
import TabPanel from "../../templates/TabPanel";
import LiveDataTable from "./LiveDataTable/LiveDataTable";
import LiveDataChart from "./LiveDataChart/LiveDataChart";
// Styles
import {useTheme} from '@material-ui/core/styles';
import useLiveDataStyles from "./LiveData.styles";
import clsx from "clsx";
// Utils
import {a11yProps} from "./LiveData.utils";
import {metricFormatting} from "./LiveData.utils";


function createTabList() {

    let FinalTabList = [];

    for (let i = 1; i < 17; i++) {
        const vavl = JSON.parse(sessionStorage.getItem("VAVL" + i));
        if (vavl !== null) {
            FinalTabList.push(<Tab label={vavl.name} {...a11yProps(i - 1)} disabled={false}/>)
        } else {
            FinalTabList.push(<Tab label={"VAVL" + i} {...a11yProps(i - 1)} disabled={true}/>)
        }
    }

    return FinalTabList;
}

function createTabPanelList(value, theme, fixedHeightPaper) {

    let FinalTabPanelList = [];

    for (let i = 1; i < 17; i++) {

        const vavlDataArray = JSON.parse(sessionStorage.getItem("VAVL" + i));

        if (vavlDataArray != null) {

            vavlDataArray.metrics = metricFormatting(vavlDataArray.metrics);

            FinalTabPanelList.push(
                <TabPanel value={value} index={i - 1} dir={theme.direction}>
                    <Paper className={fixedHeightPaper}>
                        <LiveDataChart chartData={vavlDataArray}/>
                    </Paper>
                    <div style={{height: "30px"}}/>
                    <LiveDataTable liveData={vavlDataArray}/>
                </TabPanel>
            )

        } else FinalTabPanelList.push(
            <TabPanel value={value} index={i - 1} dir={theme.direction}>
                <Typography component="h2" variant="h5" color="textSecondary" gutterBottom align={"center"}>
                    Csatlakozzon egy Veribox eszközhöz az adatok rögzítéséhez!
                </Typography>
            </TabPanel>)
    }

    return FinalTabPanelList;
}

export default function LiveData() {
    // Styles
    const classes = useLiveDataStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const theme = useTheme();

    // Selected Tab value
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => setValue(newValue);
    const handleChangeIndex = index => setValue(index);

    // Create Tabs
    let TabList = createTabList();
    let TabPanelList = createTabPanelList(value, theme, fixedHeightPaper);

    return (
        <div style={{flexGrow: 1, width: '100%'}}>
            <Box display={"flex"} p={1} flexDirection="column">
                <Box p={1}>
                    <AppBar position="static" color="default">
                        <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary"
                              variant="scrollable" scrollButtons="auto">
                            {TabList}
                        </Tabs>
                    </AppBar>
                </Box>
                <Box p={1}>
                    <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value}
                                    onChangeIndex={handleChangeIndex}>
                        {TabPanelList}
                    </SwipeableViews>
                </Box>
            </Box>
        </div>
    )
}
