export const SET_DEVICE_CONFIGURATION = "SET_DEVICE_CONFIGURATION";
export const SET_CONNECTION_STATUS = "SET_CONNECTION_STATUS";
export const SET_CONNECTION_PARAMS = "SET_CONNECTION_PARAMS";
export const SET_LIVE_DATA = "SET_LIVE_DATA";
export const RESET_HISTORICAL_DATA = "RESET_HISTORICAL_DATA";
export const SET_HISTORICAL_DATA = "SET_HISTORICAL_DATA";
export const SET_HISTORICAL_DATA_TYPE = "SET_HISTORICAL_DATA_TYPE";
export const SET_HISTORICAL_DATA_STATUS = "SET_HISTORICAL_DATA_STATUS";
export const SET_PAGE = "SET_PAGE";
export const CREATE_LOG = "CREATE_LOG";
export const DELETE_LOG = "DELETE_LOG";
export const SEND_LOG = "SEND_LOG";
export const SET_COMMAND_BUFFER = "SET_COMMAND_BUFFER";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const SET_ALARM_SEND_STATUS = "SET_ALARM_SEND_STATUS";

/* CONSTANTS */

export const ConnectionStatus = {
    DISCONNECTED: 'DISCONNECTED',                   // Default state
    CONNECTION_REQUESTED: 'CONNECTION_REQUESTED',   // User clicked the connect button
    DISCONNECT_REQUESTED: 'DISCONNECT_REQUESTED',   // User
    CONNECTION_ERROR: 'CONNECTION_ERROR',           // Worker raised a connection error
    INITIALISING: 'INITIALISING',                   // Worker initialises a physical connection
    INITIALISING_FAILED: 'INITIALISING_FAILED',     // Worker raised an error during the init.
    WAKING_UP: 'WAKING_UP',                         // Worker starts to wake up the device
    WAKING_UP_FAILED: 'WAKING_UP_FAILED',           // Worker raises an error during the wake up
    GETTING_DEVICE_CONFIG: 'GETTING_DEVICE_CONFIG', // Worker starts to query the device's dump data
    GETTING_DEVICE_CONFIG_FAILED: 'GETTING_DEVICE_CONFIG_FAILED',
    GETTING_HAVARIA_BUFFER: 'GETTING_HAVARIA_BUFFER', // Worker starts to query havaria buffer
    GETTING_HAVARIA_BUFFER_FAILED: 'GETTING_HAVARIA_BUFFER_FAILED',
    CONNECTED: 'CONNECTED',                         // Worker starts the keep alive
    PAUSED: 'PAUSED'                                // Live query paused for saving device config
};

export const ConnectionMessage = {
    DEFAULT: 'Nyomja meg a gombot a csatlakozáshoz!',
    CONNECTING: 'Csatlakozás...',
    DISCONNECTING: 'Kapcsolat bontása...',
    CONNECTING_TO_WS: 'Kapcsolódás az Airconsole-hoz WiFi-n keresztül.',
    CONNECTING_TO_WS_FAILED: 'Kapcsolódás sikertelen.\nEllenőrizze a WiFi kapcsolatot, és az Airconsole-t.',
    WAKING_UP: 'Veribox felélesztése',
    WAKING_UP_FAILED: 'Veribox felélesztése sikertelen.\nEllenőrizze, a soros és az USB kábelt.',
    GETTING_DEVICE_CONFIG: 'Eszközadatok kiolvasása...',
    GETTING_DEVICE_CONFIG_FAILED: 'Eszközadatok kiolvasása sikertelen.\nCsatlakozzon újra.',
    GETTING_HAVARIA_BUFFER: 'Havária adatok gyűjtése...',
    GETTING_HAVARIA_BUFFER_FAILED: 'Havaria adatok kiolvasása sikertelen.\nCsatlakozzon újra.',
    CONNECTED: 'Kapcsolódva.',
    CONNECTION_BROKEN: 'Kapcsolat megszakadt, kapcsolódjon újra.',
    PAUSED: 'A kapcsolat szünetel!'
};

export const ConnectionMessageSeverity = {
    LOW: 0,         // Normal message
    WARNING: 1,     // Warning message
    CRITICAL: 2     // Error message
};

export const DefaultConnectionParams = {
    uri: 'ws://192.168.10.1:8080/',
    //uri: 'ws://89.133.195.204:8080/'
};

export const Pages = {
    DIAG: 'Diagnosztika',
    LIVE_DATA: 'Élő adatok',
    LOGBOOK: 'Üzemnapló',
    SECONDS_DATA: 'Friss másodperces adatok',
    SETTINGS: 'Beállítások',
    HISTORICAL_DATA: 'Hisztorikus adatok'
};

export const CommandBufferStatus  = {
    COMMAND_REQUESTED: "COMMAND_REQUESTED",
    COMMAND_OK: "COMMAND_OK",
    COMMAND_ERROR: "COMMAND_ERROR",
};

export const LogbookCategories = [
    { displayName: "Szerelés", codeName: "szereles", code: 0},
    { displayName: "Mérés", codeName: "meres", code: 1},
    { displayName: "Ellenőrzés", codeName: "ellenorzes", code: 2},
    { displayName: "Megfigyelés", codeName: "megfigyeles", code: 3},
    { displayName: "Karbantartás", codeName: "karbantartas", code: 4},
];

export const LogbookTemplates  = {
    [LogbookCategories[0].codeName]: [{tempName: "Szerelés 1", text: "Szerelés szöveg 1"}, {tempName: "Szerelés 2", text: "Szerelés szöveg 2"}, {tempName: "Szerelés 3", text: "Szerelés szöveg 3"}],
    [LogbookCategories[1].codeName]: [{tempName: "Mérés 1", text: "Mérés szöveg 1"}, {tempName: "Mérés 2", text: "Mérés szöveg 2"}, {tempName: "Mérés 3", text: "Mérés szöveg 3"}],
    [LogbookCategories[2].codeName]: [{tempName: "Ellenőrzés 1", text: "Ellenőrzés szöveg 1"}, {tempName: "Ellenőrzés 2", text: "Ellenőrzés szöveg 2"}, {tempName: "Ellenőrzés 3", text: "Ellenőrzés szöveg 3"}],
    [LogbookCategories[3].codeName]: [{tempName: "Megfigyelés 1", text: "Megfigyelés szöveg 1"}, {tempName: "Megfigyelés 2", text: "Megfigyelés szöveg 2"}, {tempName: "Megfigyelés 3", text: "Megfigyelés szöveg 3"}],
    [LogbookCategories[4].codeName]: [{tempName: "Karbantartás 1", text: "Karbantartás szöveg 1"}, {tempName: "Karbantartás 2", text: "Karbantartás szöveg 2"}, {tempName: "Karbantartás 3", text: "Karbantartás szöveg 3"}],
};

/* ACTIONS */

export const setConnectionStatus = statusObject => ({
    type: SET_CONNECTION_STATUS,
    status: statusObject.status,
    message: statusObject.message,
    messageSeverity: statusObject.messageSeverity
});

export const setDeviceConfiguration = configurationData => ({
    type: SET_DEVICE_CONFIGURATION,
    configurationData
});

export const setLiveData = liveData => ({
    type: SET_LIVE_DATA,
    liveData
});

export const resetHistoricalData = () => ({
    type: RESET_HISTORICAL_DATA
});

export const setHistoricalData = historicalData => ({
    type: SET_HISTORICAL_DATA,
    historicalData
});

export const setHistoricalDataType = historicalDataType => ({
    type: SET_HISTORICAL_DATA_TYPE,
    historicalDataType
});

export const setHistoricalDataStatus = status => ({
    type: SET_HISTORICAL_DATA_STATUS,
    status
});

export const setConnectionParams = paramsObject => ({
    type: SET_CONNECTION_PARAMS,
    paramsObject
});

export const setPage = page => ({
    type: SET_PAGE,
    page
});

export const createLog = logObject => ({
    type: CREATE_LOG,
    logObject
});

export const deleteLog = id => ({
    type: DELETE_LOG,
    id
});

export const sendLog = logObject => ({
    type: SEND_LOG,
    logObject
});

export const setCommandBuffer = commandBufferObject => ({
    type: SET_COMMAND_BUFFER,
    commandBufferObject
});

export const setNotification = notification => ({
    type: SET_NOTIFICATION,
    notification
});

export const setAlarmSendStatus = isAlarmSendEnabled => ({
    type: SET_ALARM_SEND_STATUS,
    isAlarmSendEnabled
});
