import {red} from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import { huHU } from '@material-ui/core/locale';

// A custom theme for this app
const theme = createMuiTheme({
  typography: {
    fontFamily: ["Roboto", "sans-serif"]
  },
  palette: {
    primary: {
      main: '#1B5E20',
      mainLight: '#009342',
    },
    secondary: {
      main: '#FF5722',
    },
    error: {
      main: red.A700,
    },
    background: {
      default: '#fff',
    },
  },
}, huHU);

export default theme;
