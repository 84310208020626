// General
import React from 'react';
import {useHistory} from "react-router-dom";
// Material-UI
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
// Styles
import useLoginStyles from "./Login.styles";


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://senso-net.com/">
                Senso-Net Kommunikációs kft.
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function Login() {
    // Styles
    const classes = useLoginStyles();
    // Browser history
    const history = useHistory();

    // Login credentials
    const [values, setValues] = React.useState({
        username: '',
        //password: ''
    });
    //const [showPassword, setShowPassword] = React.useState(false);
    //const [passwordFieldError, setPasswordFieldError] = React.useState(false);

    // Login credential fields
    const [usernameFieldError, setUsernameFieldError] = React.useState(false);
    const [usernameCheckbox, setUsernameCheckbox] = React.useState(true);

    const checkTextFields = event => {

        let hasUsernameError = false;
        //let hasPasswordError = false;

        if (values.username.length === 0) {
            hasUsernameError = true;
        }
        /*if (values.password.length === 0) {
            hasPasswordError = true;
        }*/

        setUsernameFieldError(hasUsernameError);
        //setPasswordFieldError(hasPasswordError);

        if (!hasUsernameError /*&& !hasPasswordError*/) {
            history.push("/app/diagnosztika");
            localStorage.setItem("isLoggedIn", "true");
            localStorage.setItem("username", values.username)
        } else {
            event.preventDefault();
        }
    };

    // Handle credential fields
    const handleChange = prop => event => {
        setValues({...values, [prop]: event.target.value});
    };
    // Handle username save
    const handleChangeUsernameSave = () => {
        setUsernameCheckbox(!usernameCheckbox);
    };

    /*const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };*/

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Bejelentkezés
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required={true}
                        fullWidth
                        id="email"
                        label="Teljes név"
                        name="email"
                        autoFocus
                        onChange={handleChange('username')}
                        error={usernameFieldError}
                        helperText={ usernameFieldError && "Adja meg a teljes nevét!" }
                    />
                    {/*<TextField
                        variant="outlined"
                        margin="normal"
                        required={true}
                        fullWidth
                        name="password"
                        label="Jelszó"
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        autoComplete="current-password"
                        onChange={handleChange('password')}
                        error={passwordFieldError}
                        helperText={ passwordFieldError ? "Hibás jelszó!" : "" }
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                >
                                    {showPassword ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </InputAdornment>
                        }
                    />*/}
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" checked={usernameCheckbox} onChange={handleChangeUsernameSave}/>}
                        label="Név mentése"
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={checkTextFields}
                    >
                        Bejelentkezés
                    </Button>
                    {/*<Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2" component={props => <RouterLink to={"/regisztracio"} {...props} />}>
                                Elfelejtett jelszó?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2" component={props => <RouterLink to={"/regisztracio"} {...props} />}>
                                {"Még nincs felhasználója? Regisztrálás"}
                            </Link>
                        </Grid>
                    </Grid>*/}
                </form>
            </div>
            <Box mt={8}>
                <Copyright/>
            </Box>
        </Container>
    );
}
