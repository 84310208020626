// General
import React from "react";
import PropTypes from 'prop-types';
// Material-UI
import IconButton from "@material-ui/core/IconButton";
import {lighten, Tooltip} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableBody from "@material-ui/core/TableBody";
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import mqttPublishLogbook from "../LogBookMQTT/LogBookMQTT";
// Redux
import {store} from "../../../../index";
import {CommandBufferStatus, deleteLog, sendLog} from "../../../../redux/actions";
// Styles
import theme from "../../../../styles/theme";
import useLogBookListStyles from "./LogBookList.styles";
// Utils
import {LOG_EDIT_STATES} from "../LogBook.utils";
import {LOGBOOK_SEND_DIALOG_CANCEL, LOGBOOK_SEND_DIRECT, LOGBOOK_SEND_VIA_VERIBOX} from "../LogBook.utils";
import LogBookListItem from "./LogBookListItem/LogBookListItem";


export default function LogBookList({logList, openDialog, connectionStatus, commandBufferResult}) {

    // Styles
    const classes = useLogBookListStyles();

    // Log sending mode (2 possibilities: Direct or via Veribox)
    const SEND_MODE = LOGBOOK_SEND_VIA_VERIBOX;

    // Handle table pagination
    let hasRow = false;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, logList?.length - page * rowsPerPage);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Slice log list to match the selected rows per page number
    if (logList !== []) {
        //logList = logList.reverse();
        if (rowsPerPage > 0) logList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        logList.map(item => {if (item) hasRow = item;});
    }

    // Handle log delete
    const handleLogDelete = log => {
        store.dispatch(deleteLog(log.id));
    };

    // Handle log save
    /* 1. save log in selectedLog
    // 2. call mqtt
    // 3. commandBufferStatus: COMMAND_REQUESTED
    // 4. Save log in pendingLog
    // 5. commandBufferStatus: COMMAND_OK
    // 6. Dispatch sendLog to turn isSent property true
    // 7. Clear all states */
    const [selectedLog, setSelectedLog] = React.useState({});
    const [pendingLog, setPendingLog] = React.useState({});
    React.useEffect(() => {
        if (commandBufferResult === CommandBufferStatus.COMMAND_REQUESTED) {
            setPendingLog({...selectedLog});
        }
        if (commandBufferResult === CommandBufferStatus.COMMAND_OK && pendingLog?.id) {
            store.dispatch(sendLog(pendingLog));
            setPendingLog({});
            setSelectedLog({});
        }
    }, [commandBufferResult])
    const handleLogSend = log => {
        setSelectedLog(log);
        mqttPublishLogbook(SEND_MODE, log);
    };

    // Handle log details view
    const handleLogDetails = log => {
        openDialog(LOG_EDIT_STATES.CLOSED, log);
    }

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Név</TableCell>
                        <TableCell>Kategória</TableCell>
                        <TableCell>Időpont</TableCell>
                        <TableCell>Veribox</TableCell>
                        <TableCell> </TableCell>
                        <TableCell> </TableCell>
                        <TableCell> </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {logList !== [] && (rowsPerPage > 0
                            ? logList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : logList
                    ).map((row) => row && <LogBookListItem log={row} detailsLog={handleLogDetails} deleteLog={handleLogDelete} sendLog={handleLogSend} connectionStatus={connectionStatus}/>) }
                    {!hasRow && <TableRow style={{height: 53 * emptyRows}}>
                        <TableCell colSpan={6} style={{borderBottom: "none"}}>
                            <Typography component="h2" variant="h5" color="textSecondary" gutterBottom align={"center"}>
                                Ebben a kategóriában még nincs naplóbejegyzés
                            </Typography>
                        </TableCell>
                    </TableRow>}
                </TableBody>
            </Table>
            <TablePagination rowsPerPageOptions={[10, 25, {label: 'Mind', value: -1}]} style={{padding: 0, marginLeft: 0}}
                             colSpan={3} count={logList?.length} rowsPerPage={rowsPerPage} page={page}
                             onChangePage={handleChangePage} onChangeRowsPerPage={handleChangeRowsPerPage}/>

        </TableContainer>
    )
}

// Property definitions
LogBookList.propTypes = {
    logList: PropTypes.array.isRequired,
    openDialog: PropTypes.func.isRequired,
    connectionStatus: PropTypes.string.isRequired
};
