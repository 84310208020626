import {SET_COMMAND_BUFFER} from "../actions"

const commandBuffer = (state = { commandBuffer: [], commandBufferStatus: ""}, action) => {
    if (action.type === SET_COMMAND_BUFFER) {
        return action.commandBufferObject;
    } else {
        /*default*/
        return state;
    }
};

export default commandBuffer;