// General
import React from "react";
import PropTypes from "prop-types";
// Material-UI
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {Container} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
// Redux
import {
    ConnectionMessage,
    ConnectionMessageSeverity,
    ConnectionStatus,
    setConnectionStatus, setNotification
} from "../../../redux/actions";
import {connect} from "react-redux";
// Styles
import useDiagnosticStyles from "./Diagnostic.styles";


function Diagnostic({connectionStatus, connectionMessage, connectionMessageSeverity, connect}) {
    // Styles
    const classes = useDiagnosticStyles();

    let activeStep;
    let isLoading = true;
    let errorStep = -1;
    const steps = ['Kapcsolódás', 'Veribox felélesztése', 'Eszközadatok kiolvasása'];

    // Setup stepper based on the current connection state
    switch (connectionStatus) {
        case ConnectionStatus.CONNECTION_REQUESTED:
            activeStep = 0;
            if (connectionMessageSeverity !== ConnectionMessageSeverity.LOW) errorStep = activeStep;
            break;
        case ConnectionStatus.INITIALISING:
            activeStep = 0;
            if (connectionMessageSeverity !== ConnectionMessageSeverity.LOW) errorStep = activeStep;
            break;
        case ConnectionStatus.WAKING_UP:
            activeStep = 1;
            if (connectionMessageSeverity !== ConnectionMessageSeverity.LOW) errorStep = activeStep;
            break;
        case ConnectionStatus.GETTING_DEVICE_CONFIG:
        case ConnectionStatus.GETTING_HAVARIA_BUFFER:
            activeStep = 2;
            if (connectionMessageSeverity !== ConnectionMessageSeverity.LOW) errorStep = activeStep;
            break;
        default:
            activeStep = 0;
            isLoading = false;
            break;
    }

    return (
        <div>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map(label => {
                    const labelProps = {error: errorStep === activeStep};
                    return (
                        <Step key={label}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <Typography className={classes.notificationTitle} color="primary" gutterBottom align={"center"} m={30}>
                {connectionMessage}
            </Typography>
            <Container className={classes.connectButton} onClick={isLoading ? (e) => e.preventDefault : connect}>
                <Button color="primary" variant="contained" size="large" disabled={isLoading}>
                    Csatlakozás
                </Button>
                {isLoading && <CircularProgress size={24} className={classes.buttonProgress}/>}
            </Container>
        </div>
    );
}

// Property definitions
Diagnostic.propTypes = {
    connectionStatus: PropTypes.string.isRequired,
    connectionMessage: PropTypes.string.isRequired,
    connectionMessageSeverity: PropTypes.number.isRequired,
    connect: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    connectionStatus: state.connection.connectionStatus.status,
    connectionMessage: state.connection.connectionStatus.message,
    connectionMessageSeverity: state.connection.connectionStatus.messageSeverity,
});

const mapDispatchToProps = dispatch => ({
    connect: () => dispatch(setConnectionStatus(
        {
            status: ConnectionStatus.CONNECTION_REQUESTED,
            message: ConnectionMessage.CONNECTING,
            messageSeverity: ConnectionMessageSeverity.LOW
        }))
});

const DiagnosticContainer = connect(mapStateToProps, mapDispatchToProps)(Diagnostic);

export default DiagnosticContainer
